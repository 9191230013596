// @ts-nocheck
import {Column} from 'react-table'

import { Testimonial } from '../core/_models'
import { TestimonialCustomHeader } from './TestimonialCustomHeader'
import { TestimonialActionsCell } from './TestimonialActionsCell'

const TestimonialColumns: ReadonlyArray<Column<Testimonial>> = [
  {
    Header: (props) => <TestimonialCustomHeader tableProps={props} title=' Name' className='min-w-125px' />,
    accessor: 'name'
  },
  {
    Header: (props) => <TestimonialCustomHeader tableProps={props} title='Designation' className='min-w-125px' />,
    id:'designation',
    accessor: 'designation'
  },
  
  {
    Header: (props) => (
      <TestimonialCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <TestimonialActionsCell id={props.data[props.row.index].id} />,
  },
]

export {TestimonialColumns}
