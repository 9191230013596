import {FC, useState, createContext, useContext} from 'react'
import {
  ID,
  ListViewContextProps,
  initialListView,
} from '../../../../../../_metronic/helpers/crud-helper/models'
import {WithChildren} from '../../../../../../_metronic/helpers'

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  return (
    <ListViewContext.Provider
      value={{
        setItemIdForUpdate,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
