import { FC } from 'react'
import { Result } from '../../../../core/models/Result'

import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import { updatePurchaseOrderSetting, updateSalesOrderSetting } from '../../core/_requests'


import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { PurchaseOrderSetting, } from '../../core/_models'
import CustomDatePicker from '../../../../core/shared/components/CustomDatePicker'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'

type Props = {
  data: Result | PurchaseOrderSetting
}

const poSchema = Yup.object().shape({
  termsCondition: Yup.string(),
  notes: Yup.string(),

  defaultDeliveryTime: Yup.string().required('Please enter default delivery date.'),
})
const PurchaseOrderForm: FC<Props> = ({ data }) => {
  const poDetail = data as PurchaseOrderSetting
  const initialValues: PurchaseOrderSetting = {
    id: poDetail.id,
    termsCondition: poDetail.termsCondition,
    notes: poDetail.notes,
    defaultDeliveryTime: poDetail.defaultDeliveryTime

  }



  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    validationSchema: poSchema,
    onSubmit: async (formValues, { setSubmitting }) => {
      setSubmitting(true)

      let POData: PurchaseOrderSetting = {
        id: formValues.id,
        termsCondition: formValues.termsCondition,
        notes: formValues.notes,

        defaultDeliveryTime: formValues.defaultDeliveryTime
      }
      let result: Result
      result = await updatePurchaseOrderSetting(POData)
      toast.success("Purchase Order Updated Successfully.")
    },
  })



  return (
    <FormikProvider value={formik}>
      <KTCard>
        <KTCardBody>
          <form
            id='kt_modal_add_purchaseorder_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >

            <div className='mb-7 row'>
              <div className='col-6 col-md-6'><div><label className='form-label'>Delivery Time</label></div>

                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.defaultDeliveryTime && formik.errors.defaultDeliveryTime },
                    {
                      'is-valid': formik.touched.defaultDeliveryTime && !formik.errors.defaultDeliveryTime,
                    }
                  )}

                  {...formik.getFieldProps('defaultDeliveryTime')}
                  name='defaultDeliveryTime'
                />
                {formik.touched.defaultDeliveryTime && formik.errors.defaultDeliveryTime && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.defaultDeliveryTime}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-7 row '>
             
                <label className='form-label'>Terms Condition</label>
                <textarea
                  rows={5}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.termsCondition && formik.errors.termsCondition },
                    {
                      'is-valid': formik.touched.termsCondition && !formik.errors.termsCondition,
                    }
                  )}

                  {...formik.getFieldProps('termsCondition')}
                  name='termsCondition'
                />
                {formik.touched.termsCondition && formik.errors.termsCondition && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.termsCondition}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='row mb-7'>
                <label className='form-label'>Notes</label>
                <textarea
                  rows={5}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.notes && formik.errors.notes },
                    {
                      'is-valid': formik.touched.notes && !formik.errors.notes,
                    }
                  )}
                  placeholder='Customer Notes'
                  {...formik.getFieldProps('notes')}
                  name='notes'
                />
                {formik.touched.notes && formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.notes}</span>
                    </div>
                  </div>
                )}
              </div>

            

            <div className='row mb-7'>
              {/* <div className='col-6 col-md-6'>
                <label className='form-label'> Notes</label>
                <textarea
                  rows={5}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.notes && formik.errors.notes },
                    {
                      'is-valid': formik.touched.notes && !formik.errors.notes,
                    }
                  )}
                  placeholder='Customer Notes'
                  {...formik.getFieldProps('notes')}
                  name='notes'
                />
                {formik.touched.notes && formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.notes}</span>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
            {MustHavePermission(SWSection.Store, SWResource.PurchaseOrderSettings, SWAction.Update) ?
            <button type='submit' className='btn btn-primary '>
              Save
            </button>
            :null}
          </form>
        </KTCardBody>
      </KTCard>
    </FormikProvider>
  )
}

export { PurchaseOrderForm }
