import React, { useState } from "react";
import { Product } from "../product/product-list/core/_models";
import GenerateBarcode from "./Barcode";

type Props = {
    products: Product[],
    pricesep: number,
    quantity: number,


}


class PrintButton extends React.PureComponent<Props>{
    render() {

        let products = this.props.products;
        let pricesep = this.props.pricesep;
        let quantity = this.props.quantity;
        
        function printFunction(product, index) {
            let indents: React.ReactNode[] = [];
            const productQuantity = quantity[product.id as number] || 1

            for (let i = 0; i < productQuantity; i++) {

                indents.push(
                    <><div key={i} className='barcode-main__barcode-item barcode-main__barcode-style'
                        style={{ width: '38mm', height: '15mm' }}
                    >
                        <div className='fw-bolder' style={{ float: 'left', width: '50%', fontSize: '9px' }}>{product.categoryPrintName}</div>
                        <div className='text-capitalize fw-bolder' style={{ fontSize: '9px' }}>{product.designNumber}</div>
                        <GenerateBarcode value={product.barcode as string} displayValue={false} format="CODE128" width={1} height={25} textAlign={"center"} margin={0} />
                        <div style={{ float: 'left', width: '50%', fontSize: '9px' }} className="fw-bolder " >{product.colorName}</div>
                        <div style={{ float: 'left', width: '50%', fontSize: '9px' }} className="fw-bolder ">{product.spAgent}{pricesep}{product.spCustomer}</div>
                    </div></>);
            }
            return indents;
        }

        return (
            <div>
                {products && products.map((item, index) => {
                    return printFunction(item, index)
                })}
            </div>
        );

    }
}

export default PrintButton