import axios, {AxiosResponse} from 'axios'
import { Staff, StaffQueryResponse } from './_models'
import { ID, PaginationFilter} from '../../../../../../_metronic/helpers/crud-helper/models'
import { Result } from '../../../../../core/models/Result'


const API_URL = process.env.REACT_APP_STORE_API_URL
const Staff_BY_ID_URL = `${API_URL}/staff`
const Staff_LIST_URL = `${API_URL}/staff/search`

const getStaffList =async (filter: PaginationFilter): Promise<StaffQueryResponse> => {
  return await axios
  .post(`${Staff_LIST_URL}`, filter)
  .then((d: AxiosResponse<StaffQueryResponse>) => {
    return d.data
  })
  .catch((err: Result) => {
    return err
  })
}
const getStaffById =async (id: ID): Promise<Result |Staff > => {
  return await axios
    .get(`${Staff_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Staff>) => response.data)
    .then((response: Staff) => response)
    .catch((err: Result) => {
      return err
    })
}
const createStaff =async (staff: Staff): Promise<Result> => {
  return await axios
    .post(Staff_BY_ID_URL, {
      firstName:staff.firstName?.trim(),
      lastName:staff.lastName?.trim(),
      mobileNumber: staff.mobileNumber,
      addressLine1: staff.addressLine1,
      addressLine2: staff.addressLine2,
      city: staff.city,
      stateId:staff.stateId,
      zipCode: staff.zipCode,
      dob: staff.dob,
      enableLogin: staff.enableLogin,
      userName: staff.userName,
      password: staff.password,
      emailAddress: staff.emailAddress,
      panNumber: staff.panNumber,
      aadharNumber: staff.aadharNumber,
      photoUploadRequest: staff.photoUploadRequest,
      panUploadRequest:staff.panUploadRequest,
      aadharFrontUploadRequest:staff.aadharFrontUploadRequest,
      aadharBackUploadRequest:staff.aadharBackUploadRequest,
      roleId:staff.roleId
      
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateStaff = async(staff: Staff): Promise<Result> => {
  return await axios
    .put(`${Staff_BY_ID_URL}/${staff.id}`, {
      id:staff.id,
      firstName:staff.firstName?.trim(),
      lastName:staff.lastName?.trim(),
      mobileNumber: staff.mobileNumber,
      addressLine1: staff.addressLine1,
      addressLine2: staff.addressLine2,
      city: staff.city,
      stateId:staff.stateId,
      zipCode: staff.zipCode,
      dob: staff.dob,
      enableLogin: staff.enableLogin,
      userName: staff.userName,
      password: staff.password,
      emailAddress: staff.emailAddress,
      panNumber: staff.panNumber,
      aadharNumber: staff.aadharNumber,
      photoUploadRequest: staff.photoUploadRequest,
      panUploadRequest:staff.panUploadRequest,
      aadharFrontUploadRequest:staff.aadharFrontUploadRequest,
      aadharBackUploadRequest:staff.aadharBackUploadRequest,
      roleId:staff.roleId,
      userId:staff.userId,
      
     isActive:staff.isActive
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

export {
  
  getStaffList,
  getStaffById,
  createStaff,
  updateStaff,
 
}
