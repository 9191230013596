import axios, { AxiosResponse } from 'axios'
import { Product, ProductImageUploadRequest, ProductQueryResponse, updateProductPriceRequests } from './_models'

import { ID, PaginationFilter } from "../../../../../../_metronic/helpers/crud-helper/models"
import { Result } from '../../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Product_BY_ID_URL = `${API_URL}/products`
const Product_LIST_URL = `${API_URL}/products/search`
const Product_ADD_URL = `${API_URL}/products`
const Price_LIST_URL = `${API_URL}/products/updatepricelist`
const getProductList = async (filter: PaginationFilter): Promise<ProductQueryResponse> => {
  return await axios
    .post(`${Product_LIST_URL}`, filter)
    .then((d: AxiosResponse<ProductQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getProductById = async (id: ID): Promise<Result | Product> => {
  return axios
    .get(`${Product_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Product>) => response.data)
    .then((response: Product) => response)
    .catch((err: Result) => {
      return err
    })
}

const createProduct = async (product: Product): Promise<Result> => {
  return await axios
    .post(Product_ADD_URL, {
      name: product.name?.trim(),
      barcode: product.barcode?.trim(),
      description: product.description?.trim(),
      colorId: product.colorId,
      polishingTypeId: product.polishingTypeId,
      initialStockBalance: product.initialStockBalance,
      lowStockAlert: product.lowStockAlert,
      spSemiWholeSeller: product.spSemiWholeSeller,
      spRetail: product.spRetail,
      spWholeSeller: product.spWholeSeller,
      retailMoQ: product.retailMoQ,
      wholeSellerMoQ: product.wholeSellerMoQ,
      semiWholeSellerMoQ: product.semiWholeSellerMoQ,
      metaTagDescription: product.metaTagDescription?.trim(),
      metaTagKeyword: product.metaTagKeyword?.trim(),
      metaTagTitle: product.metaTagTitle?.trim(),
      statusId: product.statusId,
      purchasePrice:product.purchasePrice,
      //productImages: product.productImages,
      productGroupId:product.productGroupId,
      isDefault:product.isDefault,
      taxRateId:product.taxRateId,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateProduct = (product: Product, productImages: ProductImageUploadRequest[]): Promise<Result> => {
  
  return axios
    .put(`${Product_BY_ID_URL}/${product.id}`, {
      id: product.id,
      name: product.name?.trim(),
      barcode: product.barcode?.trim(),
      description: product.description?.trim(),
      itemGroupId: product.itemGroupId,
      colorId: product.colorId,
      polishingTypeId: product.polishingTypeId,
      isActive: product.isActive,
      initialStockBalance: product.initialStockBalance,
      lowStockAlert: product.lowStockAlert,
      spSemiWholeSeller: product.spSemiWholeSeller,
      spRetail: product.spRetail,
      spWholeSeller: product.spWholeSeller,
      retailMoQ: product.retailMoQ,
      wholeSellerMoQ: product.wholeSellerMoQ,
      semiWholeSellerMoQ: product.semiWholeSellerMoQ,
      metaTagDescription: product.metaTagDescription?.trim(),
      metaTagKeyword: product.metaTagKeyword?.trim(),
      metaTagTitle: product.metaTagTitle?.trim(),     
      purchasePrice:product.purchasePrice,
      productImages: productImages,
      productGroupId:product.productGroupId,
      isDefault:product.isDefault,
      taxRateId:product.taxRateId,

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}


const updatePrice=(price:updateProductPriceRequests[]):Promise<Result>=>{
  return axios
  .put(`${Price_LIST_URL}`, { updateProductPriceRequests : price })
}
export {

  getProductById,
  getProductList,
  createProduct,
  updateProduct,
  updatePrice

}
