import React, { useEffect, useState } from 'react'
import { AgentCommission } from '../core/_models'
import { getAgentCommissionList } from '../core/_requests'
import { PaginationFilter } from '../../../../../_metronic/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrencyInINR } from '../../../../core/models/CurrencyFormatter';


const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,
    
};
const AgentCommissionPage = () => {
    const {id}=useParams()
const[commissionList,setCommissionList]=useState<AgentCommission[]>([])
const navigate=useNavigate()
useEffect(()=>{
const fetchCommission=async()=>{
    let result: any
    const updatedPaginationFilter: PaginationFilter = {
        ...paginationFilter,
        advancedFilter: {
            field: 'agentId',
            operator: 'eq',
            value: parseInt(id as string),
        },

    };
    await getAgentCommissionList(updatedPaginationFilter).then((v) => {

        result = v.data as AgentCommission[]       
        setCommissionList(result)
    })
}

fetchCommission()
},[])
  return (
    <div className="card pt-4 mb-6 mb-xl-9">

    <div className="card-header border-0">

        <div className="card-title">
            <h2>Commission History</h2>
        </div>

    </div>

    <div className="card-body pt-0 pb-5">

        <div
            id="kt_table_agentcommission_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
            <div className="table-responsive">
                <table
                    className="table align-middle table-row-dashed gy-5 dataTable no-footer"
                    id="kt_table_agentcommission"
                >
                    <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                        <tr className="text-start text-muted text-uppercase gs-0">
                            <th
                                className="min-w-100px sorting"
                                tabIndex={0}
                                aria-controls="kt_table_agentcommission"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="order No.: activate to sort column ascending"
                                style={{ width: "144.578px" }}

                            >
                                order No.
                            </th>
                            <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="kt_table_agentcommission"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                                style={{ width: "122.688px" }}
                            >
                                Status
                            </th>
                            <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="kt_table_agentcommission"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Amount: activate to sort column ascending"
                                style={{ width: "118.375px" }}
                            >
                                Amount
                            </th>

                            <th
                                className="min-w-100px sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date"
                                style={{ width: "227.188px" }}
                            >
                                Earn Commission
                            </th>
                        </tr>
                    </thead>
                    <tbody className="fs-6 fw-semibold text-gray-600">

                        {commissionList?.length > 0 ?
                            (commissionList?.map((order) => (
                                <tr onClick={() => { navigate(`/sales/sale-order/detail/${order.saleOrderId}`) }} style={{ cursor: 'pointer' }}>
                                    <td>
                                        <a

                                            className="text-gray-600 text-hover-primary mb-1"
                                        >
                                            {order.orderNumber}
                                        </a>
                                    </td><td>
                                        {order.agentCommissionStatusName === 'Cancelled' ?
                                            <span className="badge badge-light-danger">
                                                {order.agentCommissionStatusName}
                                            </span> : <span className="badge badge-light-success">
                                                {order.agentCommissionStatusName}
                                            </span>}
                                    </td>
                                    <td>{order.orderTotal?formatCurrencyInINR(order.orderTotal):0.0} </td>

                                    <td>{order.earnCommission !==null ?order.earnCommission : ' -'} </td>
                                </tr>
                            ))) : <span className=' '><h3 className='text-danger mt-5'>No Record found</h3></span>}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
  )
}

export default AgentCommissionPage