import React from 'react'
import { useQuery } from 'react-query'
import { QUERIES } from '../../../../../../_metronic/helpers'
import { getGSTById, getOrganizatinProfile } from '../../../core/_requests'

import GeneralSettingPage from './GeneralSettingPage'

const General = () => {
    const {isLoading:isOrgLoading, data: organizationData, error: orgError} = useQuery([`${QUERIES.ORGANIZATION_PROFILE}`], async () => {
        return await getOrganizatinProfile()
      })
      const {isLoading:isGstLoading, data: gstData, error: gstError} = useQuery([`${QUERIES.GST_SETTING}`], async () => {
        return await getGSTById()
      })
     if (!isOrgLoading && !orgError && organizationData && gstData) {
    
        return <GeneralSettingPage orgData={organizationData}  gstData={gstData} />     }  

      return (
      
        null
      )
}
export default General