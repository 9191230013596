import { ID, Response } from '../../../../../_metronic/helpers/crud-helper/models';
export interface NewArrival {
  id?: ID
  name?: ''
  productGroupIds?: number[]
  uploadImage?: uploadImage
  isActive?: boolean,
  statusId?: number
  newArrivalImagePath?: string
  newArrivalProductGroups?: newArrivalProductGroups
  imagePath?:string
}
export type NewArrivalQueryResponse = Response<Array<NewArrival>>

export type uploadImage = {
  name?: string,
  extension?: string,
  data?: string
}

export const initialNewArrival: NewArrival = {
  name: '',
  productGroupIds: undefined,
  isActive: false,
  statusId: 0,

}

export type newArrivalProductGroups = [{
  id?: number,
  newArrivalId?: number,
  newArrivalName?: string,
  productGroupId?: number,
  productGroupName?: string,
  imagePath?: string,
  spSemiWholeSeller?: number,
  spRetail?: number,
  spWholeSeller?: number

}
]
