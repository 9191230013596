import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { CollectionListWrapper } from './CollectionList'
import CollectionAddPage from './pages/CollectionAddPage'
import { CollectionWrapper } from './pages/CollectionWrapper'





const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CollectionPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Collections</PageTitle>
              <CollectionListWrapper />
            </>
          }
        />

        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Collection</PageTitle>
              <CollectionAddPage />
            </>
          }
        />

        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Edit Collection</PageTitle>
              <CollectionWrapper />
            </>
          }
        /> 

      </Route>
      <Route index element={<Navigate to='/cms/collection/list' />} />
    </Routes>
  )
}

export default CollectionPage
