import { useNavigate } from "react-router-dom"
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"

const ReportDetailPage = () => {
    const navigate = useNavigate()
    return (<>
        <KTCard>
            <KTCardBody>

                <div className="fs-4 fw-bold mb-4">All Reports</div>
                <div className="table-responsive">
                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px gy-7 gs-7">
                        <thead className="thead-dark">
                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0" >
                                <th>REPORT NAME</th>
                                <th>TYPE</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                          
                            <tr>
                                <td >Product Stock</td>
                                <td>Stock</td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4"><a onClick={() => navigate('/reports/stocks/productstock/list')}>Open Report</a></td>

                            </tr>

                            <tr>
                                <td>Sales By Customer</td>
                                <td>Sales</td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4"><a onClick={() => navigate('/reports/stocks/sales-customer/list')}>Open Report</a> </td>
                            </tr>
                            <tr>
                                <td >
                                    Sales By Agent
                                </td>
                                <td>
                                    Sales
                                </td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4"><a onClick={() => navigate('/reports/stocks/sales-agent/list')} >Open Report</a></td>
                            </tr>
                            <tr>
                                <td >
                                    Sales By Product
                                </td>
                                <td>
                                    Sales
                                </td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4"><a onClick={() => navigate('/reports/stocks/sales-product/list')} >Open Report</a></td>

                            </tr>
                            <tr>
                                <td>
                                    Sales By ProductGroup

                                </td>
                                <td>
                                    Sales
                                </td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4">
                                    <a onClick={() => navigate('/reports/stocks/sales-product-group/list')}>Open Report</a>
                                </td>
                            </tr>


                            <tr>
                                <td>Purchase By Vendor</td>
                                <td>Purchase </td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4"><a onClick={() => navigate('/reports/stocks/purchase-vendor/list')}>Open Report</a></td>
                            </tr>

                            <tr>
                                <td>
                                    Purchase By Product
                                </td>
                                <td>
                                    Purchase
                                </td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4"><a onClick={() => navigate('/reports/stocks/purchase-product/list')}>Open Report</a></td>
                            </tr>
                            <tr>
                                <td>
                                    Purchase By ProductGroup
                                </td>
                                <td>
                                    Purchase
                                </td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4"> <a onClick={() => navigate('/reports/stocks/purchase-product-group/list')} >Open Report</a></td>
                            </tr>


                            <tr>
                                <td>Expense</td>
                                <td>Expense</td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4"><a onClick={() => navigate('/reports/stocks/expense/list')}>Open Report</a></td>
                            </tr>

                            <tr>
                                <td>
                                   Expense Category
                                </td>
                                <td>
                                    Expense 
                                </td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4">
                                <a onClick={() => navigate('/reports/stocks/expense-category/list')} >Open Report</a>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                   Product Low Stock
                                </td>
                                <td>
                                   Low Stock  
                                </td>
                                <td className="table-cursor btn btn-light btn-active-light-primary btn-sm mt-4">
                                <a onClick={() => navigate('/reports/lowstock/product/list')} >Open Report</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>




            </KTCardBody>

        </KTCard>
    </>)
}
export default ReportDetailPage