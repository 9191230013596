import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"

import { Result } from '../../../../core/models/Result'
import { Collection, CollectionQueryResponse } from './_models'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Collection_LIST_URL = `${API_URL}/collection/search`
const Collection_BY_ID_URL = `${API_URL}/collection`
const Collection_ADD_URL = `${API_URL}/collection`

const getCollectionList = async (filter: PaginationFilter): Promise<CollectionQueryResponse> => {
  return await axios
    .post(`${Collection_LIST_URL}`, filter)
    .then((d: AxiosResponse<CollectionQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getCollectionById = async (id: ID): Promise<Result | Collection> => {
  return axios
    .get(`${Collection_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Collection>) => response.data)
    .then((response: Collection) => response)
    .catch((err: Result) => {
      return err
    })
}
const createCollection = async (collection: Collection): Promise<Result> => {
  return await axios
    .post(Collection_ADD_URL, {
      name:collection.name,
      pageId:collection.pageId,
      sectionId:collection.sectionId,
      productGroupIds:collection.productGroupIds
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateCollection = (collection: Collection): Promise<Result> => {
  return axios
    .put(`${Collection_BY_ID_URL}/${collection.id}`, {
      id: collection.id,
      name:collection.name,
      pageId:collection.pageId,
      sectionId:collection.sectionId,
      productGroupIds:collection.productGroupIds,
      isActive:collection.isActive
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteCollection = async (id: ID): Promise<Result | Collection> => {
  return axios
    .delete(`${Collection_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Collection>) => response.data)
    .then((response: Collection) => response)
    .catch((err: Result) => {
      return err
    })
}


export {
  getCollectionList,
  getCollectionById,
  createCollection,
  updateCollection,
  deleteCollection,
 

}
