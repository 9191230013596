import {useQuery} from 'react-query'
import React  from 'react'
import { QUERIES } from '../../../../../_metronic/helpers'
import { getCustomerVendor } from '../../core/_requests'
import { CustomerVendorForm } from './CustomerVendorForm'


export const CustomerVendorPage = () => {
  const {isLoading, data: result, error: error} = useQuery([`${QUERIES.CUSTOMER_VENDOR}`], async () => {
    return await getCustomerVendor()
  })
  
 if (!isLoading && !error && result) {

    return <CustomerVendorForm data={result} />
  }
  return (
  
    null
  )
}

