import { FC, useEffect, useState } from 'react'
import { Result } from '../../../../../core/models/Result'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import { updateEmailSetting, updateOrganizatinProfile } from '../../../core/_requests'
import { OrganizationProfile, updateLogoImage } from '../../../core/_models'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { KTCard, KTCardBody, PaginationFilter, isNotEmpty } from '../../../../../../_metronic/helpers'
import { useImmer } from 'use-immer'
import { SelectOptionProps } from '../../../../../core/models/SelectOptionProps'
import { State } from 'react-inlinesvg'
import { getStateList } from '../../../../../core/shared/core/_request'
import CustomSelect from '../../../../../core/shared/components/CustomSelect'
import { Link } from 'react-router-dom'
import { useImage } from '../../../../../core/models/ImageContext'
import UploadImage from '../../../../../core/shared/components/UploadImage'
import { ImageModel } from '../../../../../core/models/ImageModel'

import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils'
const API_URL = process.env.REACT_APP_IMAGE_API_URL



const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
  advancedFilter: {
    field: "isActive",
    operator: "eq",
    value: true,
  },
};

type Props = {
  data: Result | OrganizationProfile
}

const url = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
const orgSchema = Yup.object().shape({
  organizationName: Yup.string().required('Please enter Organization Name.'),

  fax: Yup.string(),
  website: Yup.string().matches(url, 'URL is not valid'),
  firstName: Yup.string().required('Please enter first Name'),
  lastName: Yup.string().required('Please enter last Name'),
  emailAddress: Yup.string().email("Please enter valid email ID.").required('Please enter email address'),
  mobileNumber: Yup.string().matches(/^[7-9]\d{9}$/, {
    message: "Please enter valid phone number.",
    excludeEmptyString: false,
  }).required('Please enter mobile number'),
  // address: Yup.object().shape({
  //   addressLine1: Yup.string().required('Please enter Address Line 1'),
  //   addressLine2: Yup.string(),
  //   city: Yup.string().required('Please enter City.'),
  //   stateId: Yup.number().required('Please enter State.'),
  //   zipCode: Yup.string().required('Please enter Zip Code.'),
  // }),

})
export const OrganizationProfileForm: FC<Props> = ({ data }) => {
  let { setImage, } = useImage();
  const orgProfile = data as OrganizationProfile

  const initialValues: OrganizationProfile = {
    id: orgProfile.id,
    logo: orgProfile.logo,
    organizationName: orgProfile.organizationName,
    phoneNumber: orgProfile.phoneNumber,
    fax: orgProfile.fax,
    website: orgProfile.website,
    firstName: orgProfile.firstName,
    lastName: orgProfile.lastName,
    emailAddress: orgProfile.emailAddress,
    mobileNumber: orgProfile.mobileNumber,
    address: {
      id: orgProfile.address?.id,
      organisationProfileAddressId: orgProfile.address?.organisationProfileAddressId,
      addressLine1: orgProfile.address?.addressLine1,
      addressLine2: orgProfile.address?.addressLine2,
      city: orgProfile.address?.city,
      stateId: orgProfile.address?.stateId,
      stateName: orgProfile.address?.stateName,
      countryId: orgProfile.address?.countryId,
      countryName: orgProfile.address?.countryName,
      zipCode: orgProfile.address?.zipCode,
      phoneNumber: orgProfile.address?.phoneNumber,
    },

  }

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    validationSchema:orgSchema,

    onSubmit: async (formValues, { setSubmitting }) => {

      setSubmitting(true)
      let profileData: OrganizationProfile = {
        id: formValues.id,
        logo: formValues.logo,
        organizationName: formValues.organizationName,
        phoneNumber: formValues.phoneNumber,
        fax: formValues.fax,
        website: formValues.website,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        emailAddress: formValues.emailAddress,
        mobileNumber: formValues.mobileNumber,
        address: {
          ...formValues.address
        }
      }
      if (isNotEmpty(selectedImage.data)) {

        profileData.updateLogoImage = selectedImage;
      }
      let result: Result
      result = await updateOrganizatinProfile(profileData)
      setImage({
        name: selectedImage.name as string,
        extension: selectedImage.extension as string,
        data: selectedImage.data as string,
      });
      // imagePath = orgProfile.logo as string
      toast.success("Organization Profile Updated Successfully.")
    },
  })

  const [selectedImage, setSelectedImage] = useImmer<updateLogoImage>({
    name: '',
    extension: '',
    data: '',

  })
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const fileName = file.name;
        const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
        const base64Image = reader.result as ''
        setSelectedImage({
          name: nameWithoutExtension,
          extension: `.${file.type.split('/')[1]}`,
          data: base64Image,

        })
        // setImage({
        //   name: nameWithoutExtension,
        //   extension: `.${file.type.split('/')[1]}`,
        //   data: base64Image,
        // });
      }
      reader.readAsDataURL(file)
    }
  }

  const [stateList, setStateList] = useState<SelectOptionProps[]>([])

  useEffect(() => {

    let result: any

    getStateList(paginationFilter).then((v) => {
      result = v.data as State[]
      let stateArray: any[] = []
      result.map((item: any) => {
        return stateArray.push({ value: item.id, label: item.name })
      })
      setStateList(stateArray)
    })
  }, [])

  return (
    <FormikProvider value={formik}>

      <form
        id='kt_modal_add_orgprofile_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate

      >

        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
          // role="button"
          // data-bs-toggle="collapse"
          // data-bs-target="#kt_account_profile_details"
          // aria-expanded="true"
          // aria-controls="kt_account_profile_details"
          >

            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Profile Details</h3>
            </div>

          </div>

          <div id="kt_account_settings_profile_details" className="collapse show">


            <div className="card-body border-top p-9">

              <div className="row mb-6">

                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  Organization Logo
                </label>

                <div className="col-lg-8">

                  <div
                    className='image-input image-input-empty image-input-outline image-input-placeholder mb-3'
                    data-kt-image-input='true'
                  >
                    <div className='image-input-wrapper w-150px h-150px'>
                      {
                        selectedImage.data != '' ? <img src={selectedImage.data} alt='Uploaded1' className='w-100 h-100' /> :
                          (orgProfile.logo && (<img src={`${API_URL}/${orgProfile.logo}`} alt='Uploaded2' className='w-100 h-100' />))

                      }
                    </div>
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      aria-label='Change avatar'
                      data-bs-original-title='Change avatar'
                      data-kt-initialized={1}
                    >
                      <i className='ki-duotone ki-pencil fs-7'>
                        <span className='path1' />
                        <span className='path2' />
                      </i>
                      <input
                        type='file'
                        accept='.png, .jpg, .jpeg'
                        {...formik.getFieldProps('productGroupImage')}
                        onChange={handleImageUpload}
                        name='productGroupImage'
                      />
                      <input type='hidden' name='avatar_remove' />
                    </label>
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='cancel'
                      data-bs-toggle='tooltip'
                      aria-label='Cancel avatar'
                      data-bs-original-title='Cancel avatar'
                      data-kt-initialized={1}
                    >
                      <i className='ki-duotone ki-cross fs-2'>
                        <span className='path1' />
                        <span className='path2' />
                      </i>
                    </span>
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='remove'
                      data-bs-toggle='tooltip'
                      aria-label='Remove avatar'
                      data-bs-original-title='Remove avatar'
                      data-kt-initialized={1}
                    >
                      <i className='ki-duotone ki-cross fs-2'>
                        <span className='path1' />
                        <span className='path2' />
                      </i>
                    </span>
                  </div>
                  <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                </div>

              </div>

              <div className="row mb-6">

                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  Organization Name
                </label>

                <div className="col-lg-8">

                  <div className="row">

                    <div className="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                        type='text'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          { 'is-invalid': formik.touched.organizationName && formik.errors.organizationName },
                          {
                            'is-valid': formik.touched.organizationName && !formik.errors.organizationName,
                          }
                        )}
                        placeholder='organizationName'
                        {...formik.getFieldProps('organizationName')}
                        name='organizationName'
                      />
                      {formik.touched.organizationName && formik.errors.organizationName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.organizationName}</span>
                          </div>
                        </div>
                      )}
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                    </div>
                  </div>
                </div>

              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  Website
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.website && formik.errors.website },
                      {
                        'is-valid': formik.touched.website && !formik.errors.website,
                      }
                    )}
                    placeholder='website'
                    {...formik.getFieldProps('website')}
                    name='website'
                  />
                  {formik.touched.website && formik.errors.website && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.website}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>

        </div>

        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"

          >

            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Primary Contact</h3>
            </div>

          </div>

          <div id="kt_account_settings_profile_details" className="collapse show">


            <div className="card-body border-top p-9">

              <div className="row mb-6">

                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  Full Name
                </label>

                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.firstName && formik.errors.firstName },
                      {
                        'is-valid': formik.touched.firstName && !formik.errors.firstName,
                      }
                    )}
                    placeholder='firstName'
                    {...formik.getFieldProps('firstName')}
                    name='firstName'
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.firstName}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>

                <div className="col-lg-4 fv-row fv-plugins-icon-container">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.lastName && formik.errors.lastName },
                      {
                        'is-valid': formik.touched.lastName && !formik.errors.lastName,
                      }
                    )}
                    placeholder='lastName'
                    {...formik.getFieldProps('lastName')}
                    name='lastName'
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.lastName}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>
                {/*end::Col*/}
              </div>





              <div className="row mb-6">

                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  <span className="required">Mobile Number</span>
                  <span
                    className="ms-1"
                    data-bs-toggle="tooltip"
                    aria-label="Phone number must be active"
                    data-bs-original-title="Phone number must be active"
                    data-kt-initialized={1}
                  >
                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                  </span>{" "}
                </label>

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      { 'is-invalid': formik.touched.mobileNumber && formik.errors.mobileNumber },
                      {
                        'is-valid': formik.touched.mobileNumber && !formik.errors.mobileNumber,
                      }
                    )}
                    placeholder='mobileNumber'
                    {...formik.getFieldProps('mobileNumber')}
                    name='mobileNumber'
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.mobileNumber}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>
                {/*end::Col*/}
              </div>

              <div className="row mb-6">

                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  Email Address
                </label>

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.emailAddress && formik.errors.emailAddress },
                      {
                        'is-valid': formik.touched.emailAddress && !formik.errors.emailAddress,
                      }
                    )}
                    placeholder='emailAddress'
                    {...formik.getFieldProps('emailAddress')}
                    name='emailAddress'
                  />
                  {formik.touched.emailAddress && formik.errors.emailAddress && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.emailAddress}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>
              </div>







            </div>
          </div>

        </div>

        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"

          >

            <div className="card-title m-0">
              <h3 className="fw-bold m-0">Communication Address</h3>
            </div>

          </div>

          <div id="kt_account_settings_profile_details" className="collapse show">


            <div className="card-body border-top p-9">

              <div className="row mb-6">

                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  AddressLine1
                </label>

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      // {
                      //   'is-invalid': formik.touched.address?.addressLine1 && formik.errors.address?.addressLine1,
                      //   'is-valid': formik.touched.address?.addressLine1 && !formik.errors.address?.addressLine1,
                      // }
                    )}
                    placeholder='addressLine1'
                    {...formik.getFieldProps('address.addressLine1')}
                    name='address.addressLine1'
                  />
                  {/* {formik.touched.address?.addressLine1 && formik.errors.address?.addressLine1 && (
    <div className='fv-plugins-message-container'>
      <div className='fv-help-block'>
        <span role='alert'>{formik.errors.address.addressLine1}</span>
      </div>
    </div>
  )} */}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>

                {/*end::Col*/}
              </div>

              <div className="row mb-6">

                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  AddressLine2
                </label>

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      // { 'is-invalid': formik.touched.addressLine2 && formik.errors.addressLine2 },
                      // {
                      //   'is-valid': formik.touched.addressLine2 && !formik.errors.addressLine2,
                      // }
                    )}
                    placeholder='addressLine2'
                    {...formik.getFieldProps('address.addressLine2')}
                    name='address.addressLine2'
                  />
                  {/* {formik.touched.addressLine2 && formik.errors.addressLine2 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.addressLine2}</span>
                      </div>
                    </div>
                  )} */}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>
                {/*end::Col*/}
              </div>


              <div className="row mb-6">

                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  State
                </label>

                <div className="col-lg-8 fv-row">
                  <Field
                    className={clsx(
                      'form-select-solid ms-1',
                      // { 'is-invalid': formik.touched.stateId && formik.errors.stateId },
                      // {
                      //   'is-valid': formik.touched.stateId && !formik.errors.stateId,
                      // }
                    )}
                    component={CustomSelect}
                    options={stateList}
                    {...formik.getFieldProps('address.stateId')}
                    name='address.stateId'
                    //  selectedValue={formik.values.stateName}
                    onDropDownChange={(e: { value: any }) => formik.setFieldValue('address.stateId', e?.value)}
                  ></Field>
                  {/* {formik.touched.stateId && formik.errors.stateId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.stateId}</span>
                      </div>
                    </div>
                  )} */}
                </div>

              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  City
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      // { 'is-invalid': formik.touched.city && formik.errors.city },
                      // {
                      //   'is-valid': formik.touched.city && !formik.errors.city,
                      // }
                    )}
                    placeholder='city'
                    {...formik.getFieldProps('address.city')}
                    name='address.city'
                  />
                  {/* {formik.touched.city && formik.errors.city && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.city}</span>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  ZipCode
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      // { 'is-invalid': formik.touched.zipCode && formik.errors.zipCode },
                      // {
                      //   'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
                      // }
                    )}
                    placeholder='zipCode'
                    {...formik.getFieldProps('address.zipCode')}
                    name='address.zipCode'
                  />
                  {/* {formik.touched.zipCode && formik.errors.zipCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.zipCode}</span>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  Fax
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.fax && formik.errors.fax },
                      {
                        'is-valid': formik.touched.fax && !formik.errors.fax,
                      }
                    )}
                    placeholder='fax'
                    {...formik.getFieldProps('fax')}
                    name='fax'
                  />
                  {formik.touched.fax && formik.errors.fax && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.fax}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">

                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  <span className="">Contact Phone</span>
                  <span
                    className="ms-1"
                    data-bs-toggle="tooltip"
                    aria-label="Phone number must be active"
                    data-bs-original-title="Phone number must be active"
                    data-kt-initialized={1}
                  >
                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                  </span>{" "}
                </label>

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      // { 'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber },
                      // {
                      //   'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                      // }
                    )}
                    placeholder='phoneNumber'
                    {...formik.getFieldProps('address.phoneNumber')}
                    name='address.phoneNumber'
                  />
                  {/* {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.phoneNumber}</span>
                      </div>
                    </div>
                  )} */}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>
                {/*end::Col*/}
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                {MustHavePermission(SWSection.Store, SWResource.OrganizationProfiles, SWAction.Update) ?
                  <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit">Save Changes</button>
                  : null}
              </div>


            </div>
          </div>

        </div>


      </form>

    </FormikProvider>
  )
}

export default OrganizationProfileForm 