const QUERIES = {
  UNIT_LIST: 'unit-list',
  PROCESS_LIST: 'process-list',
  PRODUCTS_LIST: 'products-list',
  PRODUCTGROUP_LIST: 'productgroup-list',
  SALES_ORDER_LIST: 'salesOrder-list',
  WORK_ORDER_LIST: 'workOrder-list',
  PURCHASE_ORDER_LIST: 'purchase-list',
  STAFF_LIST: 'staff-list',
  CUSTOMER_LIST: 'customer-list',
  VENDOR_LIST: 'vendor-list',
  CATEGORY_LIST: 'categories-list',
  BRAND_LIST: 'brand-list',
  MANUFACTURER_LIST: 'manufacturer-list',
  PRODUCTSTOCK_LIST: 'productstock-list',
  PAYMENT_LIST: 'payment-list',
  RAWMATERIAL_STOCK_LIST: 'raw-list',
  SHIPMENT_LIST: 'shipment-list',
  PURCHASERECEIVE_LIST: 'purchase-receive-list',
  PACKAGE_LIST: 'package-list',
  AUTONUMBERGENERATION: 'autonumber-list',
  PURCHASE_ORDER_ITEM_List: 'purchase_order_item_list',
  SALES_ORDER_ITEM_List: 'sale_order_item_list',
  ACCOUNT_LIST: 'account-list',
  ACCOUNT_GROUP: 'account_group-list',
  BANK_ACCOUNT: 'bank-account',
  CREDIT_NOTE_SETTING: 'credit-note-setting',
  CUSTOMER_VENDOR: 'customer-vendor',
  EMAIL_SETTING: 'email-setting',
  SO_SETTING: 'so-setting',
  EMAIL_TEMPLATE: 'email-template',
  EXPENSE_CATEGORY: 'expense-category',
  EXPENSE: 'expense',
  PO_SETTING: 'po-setting',
  GST_SETTING: 'gst-setting',
  INVOICE_SETTING: 'invoice-setting',
  INVOICE: 'invoice',
  ORGANIZATION_PROFILE: 'organization-profile',
  TERM: 'term-list',
  AGENT: 'agent-list',
  PAYMENT_MODE: 'payment_mode',
  ROLE_LIST: 'role_list',
  BANNER:'banner',
  SEO_LIST:'seo-list',
  NEW_ARRIVALS:'new-arrivals',
  COLLECTIONS:'collections-list',
  TESTIMONIALS:'testimonials',
  MID_BANNER:'mid-banner',
  STATUS_HISTORY:'status-history',



}

export { QUERIES }
