
import { useState } from 'react'
import { KTCard, KTCardBody, KTIcon, PaginationFilter } from '../../../../../_metronic/helpers'
import { useListView, ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { getExpenseList } from '../core/_requests'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ExpenseModal } from './ExpenseModal'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'
import { formatCurrencyInINR } from '../../../../core/models/CurrencyFormatter'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import ExpenseAdvancedSearch from './ExpenseAdvancedSearch'
import { useNavigate } from 'react-router-dom'



const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
  advancedFilter: {
    logic: 'and',
    filters: [{

      field: "isActive",
      operator: "eq",
      value: true,

    },]
  }
};
const ExpenseList = () => {
  const { itemIdForUpdate } = useListView()
  const [filters, setFilters] = useState({});
  const navigate = useNavigate()
  const { data: expenseResponse, isLoading, refetch } = useQuery(['expense', filters], () => {
    return getExpenseList({ ...filters });
  });


  const handleSearch = (searchParams) => {

    const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: searchParams.advancedFilter
    };
    setFilters(updatedPaginationFilter);
    refetch()
  };


  const { setItemIdForUpdate } = useListView()

  const openEditAccountModal = (id: any) => {
    setItemIdForUpdate(id)
  }


  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
      
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Actions
          <KTIcon iconName='down' className='fs-5 m-0' />
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {/* {MustHavePermission(SWSection.Store, SWResource.Vendors, SWAction.Update) ? */}

          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => { openEditAccountModal(rowData.id) }}>
              Edit
            </a>
          </div>
          {/* : null} */}
          {/* {MustHavePermission(SWSection.Store, SWResource.Vendors, SWAction.View) ? */}

          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => navigate(`/purchase/expense/detail/${rowData.id}`)}>
              Detail
            </a>
          </div>
          {/* : null} */}


        </div>
      </div>
    );
  };

  const formatDate = (rowData: any, dateFormat: string) => {
    const date = new Date(rowData);
    return format(date, dateFormat);
  }

  return (
    <>
      <ExpenseAdvancedSearch onSearch={handleSearch} />
      <KTCard>
        <KTCardBody>
          <>
            <div className="d-flex justify-content-end mt-4">
              <div className="m-0">
              </div>
            </div>
            <div>
              <DataTable

                className='table align-middle table-row-dashed gy-5 dataTable no-footer'
                value={expenseResponse?.data}
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
              >

                <Column

                  className='text-gray-600 fw-bold fs-6'
                  field='expenseCategory'
                  header={
                    <div className='text-start text-muted fw-bolder gs-0 min-w-125px'>
                      Expense Category
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                />
                <Column

                  className='text-gray-600 fw-bold fs-6'
                  field='accountName'
                  header={
                    <div className='text-start text-muted fw-bolder gs-0 min-w-125px'>
                      Account Name
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                />
                <Column

                  className='text-gray-600 fw-bold fs-6'
                  field='expenseDate'
                  header={
                    <div className='text-start text-muted fw-bolder gs-0 min-w-125px'>
                      Expense Date
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                  body={(rowData) => formatDate(rowData.expenseDate, 'dd MMM yyyy')}

                />
                <Column

                  className='text-gray-600 fw-bold fs-6'
                  field='expenseAmount'
                  header={
                    <div className='text-start text-muted fw-bolder gs-0 min-w-125px'>
                      Expense Amount
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                  body={(rowData) => formatCurrencyInINR(rowData.expenseAmount)}
                />
                {MustHavePermission(SWSection.Store, SWResource.Expenses, SWAction.Update) ?
                  <Column field="" header={
                    <div className='text-start text-muted fw-bolder gs-0 min-w-125px'>
                      ACTIONS
                    </div>
                  } body={actionBodyTemplate} />
                  :
                  null}
              </DataTable>
            </div>
          </>
        </KTCardBody>
      </KTCard>

      {itemIdForUpdate !== undefined && <ExpenseModal />}
    </>
  )
}

const ExpenseWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ExpenseList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ExpenseWrapper }
