/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'

import {useQueryRequest} from '../../core/QueryRequestProvider'
import { useDebounce } from '../../../../../../_metronic/helpers/crud-helper/helpers'
import { StatusFilter, initialQueryState } from '../../../../../../_metronic/helpers/crud-helper/models'
import { KTIcon } from '../../../../../../_metronic/helpers'


const CollectionListSearchComponent = () => {
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')


   const debouncedSearchTerm = useDebounce(searchTerm, 150)
   useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({keyword: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] 
  )
 
  
  
  
  
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-brand-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search collection'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export {CollectionListSearchComponent}
