
import {useQuery} from 'react-query'

import React  from 'react'
import { QUERIES } from '../../../../../../_metronic/helpers'
import {  getInvoiveSetting } from '../../../core/_requests'

import { InvoiceForm } from './InvoiceForm'




export const InvoiceSettingPage = () => {


  const {isLoading, data: result, error: error} = useQuery([`${QUERIES.INVOICE_SETTING}`], async () => {
    return await getInvoiveSetting()
  })
  
 if (!isLoading && !error && result) {

    return <InvoiceForm data={result} />
  }
  return (
  
    null
  )
}

