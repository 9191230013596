import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import { ToastContainer } from 'react-toastify'
import {  HelmetProvider, HelmetServerState } from 'react-helmet-async';
import { ImageProvider } from './core/models/ImageContext'



const helmetContext :{helmet:HelmetServerState}= {
  helmet: {
    title: {
      toComponent: function (): React.Component<any> {
        throw new Error('Function not implemented.')
      }
    },
    base: {
      toComponent: function (): React.Component<any> {
        throw new Error('Function not implemented.')
      }
    },
    bodyAttributes: {
      toComponent: function (): React.HTMLAttributes<HTMLBodyElement> {
        throw new Error('Function not implemented.')
      }
    },
    htmlAttributes: {
      toComponent: function (): React.HTMLAttributes<HTMLHtmlElement> {
        throw new Error('Function not implemented.')
      }
    },
    link: {
      toComponent: function (): React.Component<any> {
        throw new Error('Function not implemented.')
      }
    },
    meta: {
      toComponent: function (): React.Component<any> {
        throw new Error('Function not implemented.')
      }
    },
    noscript: {
      toComponent: function (): React.Component<any> {
        throw new Error('Function not implemented.')
      }
    },
    script: {
      toComponent: function (): React.Component<any> {
        throw new Error('Function not implemented.')
      }
    },
    style: {
      toComponent: function (): React.Component<any> {
        throw new Error('Function not implemented.')
      }
    },
    titleAttributes: {
      toComponent: function (): React.Component<any> {
        throw new Error('Function not implemented.')
      }
    },
    priority: {
      toComponent: function (): React.Component<any> {
        throw new Error('Function not implemented.')
      }
    }
  }
}

const App = () => {
  return (

    <HelmetProvider context={helmetContext}>

      <Suspense fallback={<LayoutSplashScreen />}>

        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <ImageProvider>
                  <Outlet />
                </ImageProvider>
                <MasterInit />
              </AuthInit>
              <ToastContainer />
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>

      </Suspense>
     </HelmetProvider>

  )
}

export { App }
