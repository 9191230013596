import { lazy, FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../../_metronic/helpers'
import PaymentPage from './payment/payment/PaymentPage'
import InvoicePage from './invoice/InvoicePage'
import POSPage from './pos/POSPage'
import AgentPage from './agent/AgentPage'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../core/shared/core/permissionUtils'
import UnAuthorized from '../errors/components/UnAuthorized'

const SalesRoutes = () => {
  const CustomerPage = lazy(() => import('./customer/CustomerPage'))
  const PackagePage = lazy(() => import('./package/PackagePage'))
  const SalesOrderPage = lazy(() => import('./salesOrder/SalesOrderPage'))
  const ShipmentPage = lazy(() => import('./shipment/ShipmentPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='customers/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Customers, SWAction.Search) ?
                <CustomerPage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        <Route
          path='sale-order/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.SaleOrders, SWAction.Search) ?
                <SalesOrderPage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        <Route
          path='invoice/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Search) ?
                <InvoicePage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        <Route
          path='package/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Packages, SWAction.Search) ?
                <PackagePage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        <Route
          path='shipment/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Shipments, SWAction.Search) ?
                <ShipmentPage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        <Route
          path='pos/*'
          element={
            <SuspensedView>
              <POSPage />
            </SuspensedView>
          }
        />
        <Route
          path='agent/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.Search) ?
                <AgentPage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        <Route
          path='payment/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { SalesRoutes }
