import { Link, Navigate, useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { MustHavePermission, SWAction, SWResource, SWSection } from '../../../../../core/shared/core/permissionUtils'
const NewArrivalListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const navigate=useNavigate()
  const openAddUserModal = () => {  
   
    navigate('/cms/newarrival/add')
         }

  return (
    <div className="d-flex align-items-center gap-2 gap-lg-3">
   
      
     
      
       {/* {MustHavePermission(SWSection.Store, SWResource.Brands, SWAction.Create) ? */}
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add
      </button>
       {/* : null} */}

    </div>
  )
}

export { NewArrivalListToolbar }
