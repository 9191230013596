import { createContext, useContext, ReactNode, useState, useEffect } from 'react';

interface ImageContextProps {
  imageSelected: {
    name: string;
    extension: string;
    data: string;
  };
  setImage: (imageData: { name: string; extension: string; data: string }) => void;
 
}

const ImageContext = createContext<ImageContextProps | undefined>(undefined);

export const ImageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [imageSelected, setImageSelected] = useState({
    name: '',
    extension: '',
    data: '',
  });

  const setImage = (imageData: { name: string; extension: string; data: string }) => {
    setImageSelected(imageData);
   

  };



  return (
    <ImageContext.Provider value={{ imageSelected, setImage }}>
      {children}
    </ImageContext.Provider>
  );
};

export const useImage = (): ImageContextProps => {
  const context = useContext(ImageContext);
  if (!context) {
    throw new Error('useImage must be used within an ImageProvider');
  }
  return context;
};
