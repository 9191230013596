import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
// import RawMaterialListWrapper from './RawMaterialListWrapper'
// import RawMaterialDetails from './RawMaterialDetails'

import RawMaterialDetails from './ProductStockDetails'
import {ProductStockListWrapper} from './ProductStockListWrapper'
import ProductStockDetails from './ProductStockDetails'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const ProductStockPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Products</PageTitle>
              <ProductStockListWrapper />
            </>
          }
        />

        {/* <Route
          path='detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Detail Products</PageTitle>
              <ProductStockDetails />
            </>
          }
        /> */}
      </Route>
    </Routes>
  )
}
export default ProductStockPage
