
/* eslint-disable */
import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import Flatpickr from 'react-flatpickr';
import { toast } from 'react-toastify'
import { Result } from '../../../../../core/models/Result'
import { KTCard, KTCardBody, PaginationFilter, isNotEmpty } from '../../../../../../_metronic/helpers'
import { camelize } from '../../../../../core/utils/StringHelpers'
import { Loading } from '../../../../../core/shared/components/Loading'
import { GSTSetting } from '../../../core/_models'
import { updateGST } from '../../../core/_requests'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils';
import { SelectOptionProps } from '../../../../../core/models/SelectOptionProps';
import { getPlaceOfSupplyList } from '../../../../../core/shared/core/_request';
import { PlaceOfSupply } from '../../../../../core/shared/core/_model';
import CustomSelect from '../../../../../core/shared/components/CustomSelect';
type Props = {
  isGSTLoading: boolean
  data: GSTSetting | Result
}


const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,

}
const editGSTSchema = Yup.object().shape({

  isBusinessRegisterGST: Yup.boolean(),
  gstin: Yup.string()
    .when("isBusinessRegisterGST", {
      is: true,
      then(schema) {
        return schema.required('Please enter GSTIN.')
      }
    }),
  businessLegalName: Yup.string()
    .when("isBusinessRegisterGST", {
      is: true,
      then(schema) {
        return schema.required('Please enter business Legal Name.')
      }
    }),
  businessTradeName: Yup.string()
    .when("isBusinessRegisterGST", {
      is: true,
      then(schema) {
        return schema.required('Please enter business Trade Name.')
      }
    }),
  gstRegisteredOn: Yup.string()
    .when("isBusinessRegisterGST", {
      is: true,
      then(schema) {
        return schema.required('Please enter date of gst registration')
      }
    }),
    placeOfSupplyId:Yup.string()



})

const GSTForm: FC<Props> = ({ data, isGSTLoading }) => {
  const [placeOfSupplyList, setPlaceOfSupplyList] = useState<SelectOptionProps[]>([])

  let gst = data as GSTSetting;
  const initialValues: GSTSetting = {
    id: gst.id,
    isBusinessRegisterGST: gst.isBusinessRegisterGST,
    gstin: gst.gstin,
    businessLegalName: gst.businessLegalName,
    businessTradeName: gst.businessTradeName,
    gstRegisteredOn: gst.gstRegisteredOn,
    compositionScheme: gst.compositionScheme,
    reverseCharge: gst.reverseCharge,
    importExport: gst.importExport,
    placeOfSupplyId:gst.placeOfSupplyId
  }

  useEffect(() => {
        getPlaceOfSupplyList(paginationFilter).then((v) => {
      let result: any
      result = v.data as PlaceOfSupply[]
      let supplyArray: any[] = []
      result.map((item: any) => {
        return supplyArray.push({ value: item.id, label: item.name })
      })
      setPlaceOfSupplyList(supplyArray)
    })
  }, [])

  const formik = useFormik({

    initialValues: initialValues,
    validationSchema: editGSTSchema,

    onSubmit: async (formValues, { setSubmitting, setFieldError }) => {
      setSubmitting(true)


      try {
        let result: Result

        if (isNotEmpty(gst.id)) {

          if (formik.values.isBusinessRegisterGST === false) {
            formValues.businessLegalName = '',
              formValues.gstin = '',
              formValues.businessTradeName = '',
              formValues.gstRegisteredOn = '',
              formValues.compositionScheme = false,
              formValues.reverseCharge = false,
              formValues.importExport = false,
              formValues.isBusinessRegisterGST = false,
              formValues.placeOfSupplyId=undefined
          }
          result = await updateGST(formValues)
          if (result.hasOwnProperty('succeeded') && result?.succeeded) {
            setSubmitting(true)
            toast.success('GST created successfully');

          }
          else {
            toast.error('Error Occurred while updating GST.');
            if (result.statusCode === 400) {
              result.propertyResults.map((error) =>
                setFieldError(camelize(error.propertyName), error.errorMessage)
              )
            }
          }
        }
      } catch (ex) {

        toast.error('Error Occurred .');
        console.error(ex)
      }
    },
  })


  return (

    <FormikProvider value={formik}>
      <KTCard>
        <KTCardBody>
          <form
            id='kt_modal_add_gst_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div
              className='d-flex flex-column'
              id='kt_modal_add_gst_scroll'
              data-kt-scroll='true'
            >

              <div className="mt-3 mb-4">
                <div className="d-flex align-items-center mt-2 bg-light p-4 rounded" style={{ height: '70px' }}>
                  <span className='me-4'>Is your business registered for GST? </span>
                  <label className="form-check form-switch form-switch-sm">
                    <input
                      type="checkbox"
                      {...formik.getFieldProps('isBusinessRegisterGST')}
                      name='isBusinessRegisterGST'
                      className="me-3 form-check-input cursor-pointer"
                    />
                    <div className="control__indicator" />
                  </label>
                  <span className="switch-slider" data-checked="✓" data-unchecked="✕" />
                </div>
              </div>

              {formik.values.isBusinessRegisterGST ? <>
                <div className='row mb-7'>
                  <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                    <p className='text-danger'>GSTIN</p>
                    <p className='text-muted'> Maximum 15 digits</p>
                  </label>

                  <div className="col-lg-8 fv-row fv-plugins-icon-container">
                    <input

                      {...formik.getFieldProps('gstin')}
                      type='text'
                      name='gstin'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                        { 'is-invalid': formik.touched.gstin && formik.errors.gstin },
                        {
                          'is-valid': formik.touched.gstin && !formik.errors.gstin,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isGSTLoading} />
                    {formik.touched.gstin && formik.errors.gstin && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.gstin}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <div className='row mb-7'>

                  <label className="col-lg-4 col-form-label  fw-semibold fs-6">Business Legal Name</label>
                  <div className="col-lg-8 fv-row fv-plugins-icon-container">
                    <input

                      {...formik.getFieldProps('businessLegalName')}
                      type='text'
                      name='businessLegalName'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                        { 'is-invalid': formik.touched.businessLegalName && formik.errors.businessLegalName },
                        {
                          'is-valid': formik.touched.businessLegalName && !formik.errors.businessLegalName,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isGSTLoading} />
                    {formik.touched.businessLegalName && formik.errors.businessLegalName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.businessLegalName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <div className='row mb-7'>
                  <label className="col-lg-4 col-form-label  fw-semibold fs-6">Business Trade Name</label>
                  <div className="col-lg-8 fv-row fv-plugins-icon-container">
                    <input

                      {...formik.getFieldProps('businessTradeName')}
                      type='text'
                      name='businessTradeName'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                        { 'is-invalid': formik.touched.businessTradeName && formik.errors.businessTradeName },
                        {
                          'is-valid': formik.touched.businessTradeName && !formik.errors.businessTradeName,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isGSTLoading} />
                    {formik.touched.businessTradeName && formik.errors.businessTradeName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.businessTradeName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <div className='row mb-7'>
                  <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                    GST Registered On
                  </label>

                  <div className="col-lg-8 fv-row fv-plugins-icon-container">
                    <Flatpickr                    
                      className='form-control'
                      placeholder='Pick date'
                      {...formik.getFieldProps('gstRegisteredOn')}
                      name={'gstRegisteredOn'}
                      onChange={([date]) => {
                        formik.setFieldValue('gstRegisteredOn', date);
                        console.log(date);
                      }} />

                    {formik.touched.gstRegisteredOn && formik.errors.gstRegisteredOn && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.gstRegisteredOn}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-7">
                <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                    Place Of Supply
                  </label>

                  <div className="col-lg-8 fv-row fv-plugins-icon-container">
                <Field
                className={clsx(
                  'form-select-solid ms-1',
                  { 'is-invalid': formik.touched.placeOfSupplyId && formik.errors.placeOfSupplyId },
                  {
                    'is-valid': formik.touched.placeOfSupplyId && !formik.errors.placeOfSupplyId,
                  }
                )}
                {...formik.getFieldProps('placeOfSupplyId')}
                name="placeOfSupplyId"
                options={placeOfSupplyList}
                component={CustomSelect}
                placeholder="Select account type"
                isMulti={false}
                onDropDownChange={(e:any) =>  formik.setFieldValue('placeOfSupplyId',e.value) }
              >
              </Field>
              </div>
                </div>

                <div className='row mb-7'>
                  <label className="col-lg-4 col-form-label  fw-semibold fs-6">Composition Scheme</label>
                  <div className="col-lg-8 fv-row fv-plugins-icon-container">
                    <input
                      className='form-check-input ms-1'
                      type='checkbox'
                      {...formik.getFieldProps('compositionScheme')}
                      checked={{ ...formik.getFieldProps('compositionScheme') }.value} /><span className='ms-4'>My business is registered for Composition Scheme</span>
                    {formik.touched.compositionScheme && formik.errors.compositionScheme && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.compositionScheme}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className="col-lg-4 col-form-label  fw-semibold fs-6">Reverse Charge</label>
                  <div className="col-lg-8 fv-row fv-plugins-icon-container">
                    <input
                      className='form-check-input ms-1'
                      type='checkbox'
                      {...formik.getFieldProps('reverseCharge')}
                      checked={{ ...formik.getFieldProps('reverseCharge') }.value} /><span className='ms-4'>Enable Reverse Charge in Sales transactions</span>
                    {formik.touched.reverseCharge && formik.errors.reverseCharge && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.reverseCharge}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className="col-lg-4 col-form-label  fw-semibold fs-6"> Import/Export</label>
                  <div className="col-lg-8 fv-row fv-plugins-icon-container">
                    <input
                      className='form-check-input ms-1'
                      type='checkbox'
                      {...formik.getFieldProps('importExport')}
                      checked={{ ...formik.getFieldProps('importExport') }.value} /><span className='ms-4'>My business is involved in SEZ/Overseas Trading</span>

                    {formik.touched.importExport && formik.errors.importExport && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.importExport}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>




              </> : ''}





              <div className="card-footer d-flex justify-content-end py-6 px-9">
                {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                {MustHavePermission(SWSection.Store, SWResource.GSTSettings, SWAction.Update) ?
                  <button type='submit'
                    className='btn btn-primary'
                    data-kt-regions-modal-action='submit'
                    disabled={isGSTLoading || formik.isSubmitting || !formik.isValid || !formik.touched}><span className='indicator-label'>Save Changes</span>
                    {(formik.isSubmitting || isGSTLoading) && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}</button> : null}
              </div>
            </div>
          </form>
          {(formik.isSubmitting || isGSTLoading) && <Loading />}
        </KTCardBody>
      </KTCard>
    </FormikProvider >
  )
}

export { GSTForm }
