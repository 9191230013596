import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import clsx from "clsx";
import { isNotEmpty } from "../../../../../_metronic/helpers/crud-helper/helpers";
import { Result } from "../../../../core/models/Result";
import { camelize } from "../../../../core/utils/StringHelpers";
import { StatusOptions } from "../../../../core/models/StatusOptions";
import CustomSelect from "../../../../core/shared/components/CustomSelect";
import { Loading } from "../../../../core/shared/components/Loading";
import { SelectOptionProps } from "../../../../core/models/SelectOptionProps";
import { PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models";
import { getStateList } from "../../../../core/shared/core/_request";
import { State } from "../../../../core/shared/core/_model";
import {  KTSVG } from "../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Agent, initialAgent } from "../core/_models";
import {  updateAgent } from "../core/_requests";
import Flatpickr from 'react-flatpickr';
import { ImageModel } from "../../../../core/models/ImageModel";
import UploadImage from "../../../../core/shared/components/UploadImage";
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";

type Props = {
  isAgentLoading: boolean;
  data: Result | Agent;
};

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
  advancedFilter: {
    field: "isActive",
    operator: "eq",
    value: true,
  },


};

const AgentSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z0-9\-_', .]*$/, "Only alphanumeric, _, -, ' and space are allowed for this field")
    .required("Please enter First name"),
  lastName: Yup.string()
    .matches(/^[A-Za-z0-9\-_', .]*$/, "Only alphanumeric, _, -, ' and space are allowed for this field")
    .required("Please enter Last name"),
  companyName: Yup.string()
    .matches(/^[A-Za-z0-9\-_', .]*$/, "Only alphanumeric, _, -, ' and space are allowed for this field"),
  mobileNumber: Yup.string()
    .matches(/^[7-9]\d{9}$/, "Please enter valid phone number.",
      )
    .required("Please enter Phone Number"),
  whatsappNumber: Yup.string()
    .matches(/^[7-9]\d{9}$/,  "Please enter valid phone number.",
      ),

  commissionPercent: Yup.number()
    .max(99.99, 'Commssion cannot be more than 100%')
    .moreThan(-1, 'Commission percent cannot be negative')
  ,
  addressLine1: Yup.string()
    .matches(/^[A-Za-z0-9-_', .]*$/, "Only alphanumneric, _,-,' are allowed for this field "),
  addressLine2: Yup.string()
    .matches(/^[A-Za-z0-9-_', .]*$/, "Only alphanumneric, _,-,' are allowed for this field "),
  city: Yup.string()
    .matches(/^[A-Za-z0-9-_', ]*$/, "Only alphanumneric, _,-,' are allowed for this field "),
  emailAddress: Yup.string()
    .email()
    .required("Please enter Email Address"),
  statusId: Yup.number(),
  zipCode: Yup.string().matches(/^[0-9]{6}$/, 'Must be exactly 6 digits'),

});

const AgentEditPage: FC<Props> = ({ isAgentLoading, data }) => {
  const [selectedImage, setSelectedImage] = useState<ImageModel>({})
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  const today = new Date();
  const minDate = new Date('1900-01-01');
  const navigate = useNavigate()
 

  let agentData = data as Agent
  const initialValues = {
    id: agentData.id,
    firstName: agentData.firstName || initialAgent.firstName,
    lastName: agentData.lastName || initialAgent.lastName,
    companyName: agentData.companyName || initialAgent.companyName,
    emailAddress: agentData.emailAddress || initialAgent.emailAddress,
    mobileNumber: agentData.mobileNumber || initialAgent.mobileNumber,
    whatsappNumber: agentData.whatsappNumber || initialAgent.whatsappNumber,
    dateOfBirth: agentData.dateOfBirth,
    dateOfAnniversary: agentData.dateOfAnniversary,
    rateId: agentData.rateId || initialAgent.rateId,
    imagePath: agentData.imagePath,
    addressLine1: agentData.addressLine1 || initialAgent.addressLine1,
    addressLine2: agentData.addressLine2 || initialAgent.addressLine2,
    city: agentData.city || initialAgent.city,
    stateId: agentData.stateId || initialAgent.stateId,
    zipCode: agentData.zipCode || initialAgent.zipCode,
    commissionPercent: agentData.commissionPercent || initialAgent.commissionPercent,
    enableLogin: agentData.enableLogin,
    userName: agentData.userName,
    password: agentData.password,
    isActive: agentData.isActive,
    statusId: (agentData.isActive ? 1 : 0) || initialAgent.statusId,
    userId: agentData.userId,
    uploadImage: agentData.uploadImage
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: AgentSchema,

    onSubmit: async (formValues, { setFieldError, setSubmitting }) => {
      console.log("formValues", formValues)
 
      setSubmitting(true);
      try {

        if (new Date(formValues.dateOfBirth as Date) > today || new Date(formValues.dateOfBirth as Date) < minDate) {
          formik.setFieldError('dateOfBirth', 'Please enter valid Date')
          return
        }

        if (formValues.dateOfAnniversary as Date > today || (formValues.dateOfAnniversary as Date) < minDate) {
          formik.setFieldError('dateOfAnniversary', 'Please enter valid Date')
          return
        }
        if (formValues.userId === null && formValues.enableLogin === true && formValues.userName === null && formValues.password === undefined) {
          formik.setFieldError('userName', 'Please enter Username')
          return
        }

        else if (formValues.userId === null && formValues.enableLogin === true && (formValues.password === undefined || formValues.password === null)) {
          formik.setFieldError('password', 'Please enter password')

          return
        }

        else if (formValues.userId === null && formValues.enableLogin === true && !passwordRegex.test(formValues.password as string)) {
          formik.setFieldError('password', 'Password should be atleast 6 characters including letter,digit and 1 special symbol')         
          return;
        }
        if (formValues.dateOfBirth) {
          const dob = toZonedTime(formValues.dateOfBirth, 'Asia/Kolkata');
          formValues.dateOfBirth = format(dob, 'yyyy-MM-dd')

        }
        if (formValues.dateOfAnniversary) {
          const doa = toZonedTime(formValues.dateOfAnniversary, 'Asia/Kolkata');
          formValues.dateOfAnniversary = format(doa, 'yyyy-MM-dd')

        }
        let result: Result;
        if (isNotEmpty(selectedImage.data)) {
          formValues.uploadImage = selectedImage;
        }
        formValues.isActive = formValues.statusId === 1
        result = await updateAgent(formValues);

        if (result.hasOwnProperty("succeeded") && result?.succeeded) {
          setSubmitting(true);
          toast.success("Agent updated successfully.")
          navigate('/sales/agent/list')

        } else {
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage),
              toast.error("Error while updating Agent")
            );
          }
        }
      } catch (ex) {
        console.error(ex);
      }
    },
  });

  const [stateList, setStateList] = useState<SelectOptionProps[]>([])

  useEffect(() => {
    if (agentData.mobileNumber === agentData.whatsappNumber) {
      setPhoneNumber(true)
    }
  }, [agentData])
  const [phoneNumber, setPhoneNumber] = useState(false)

  useEffect(() => {
    let result: any
    getStateList(paginationFilter).then((v) => {
      result = v.data as State[]
      let stateArray: any[] = []
      result.map((item: any) => {
        return stateArray.push({ value: item.id, label: item.name })
      })
      setStateList(stateArray)
    })
  }, [])

  const customerType = [
    { value: 1, label: 'Wholeseller' },
    { value: 2, label: 'SemiWholeseller' },
    { value: 3, label: 'Retail' }
  ]
  const setWhatsapp = () => {
    if (!phoneNumber) {
      formik.setFieldValue('whatsappNumber', formik.values.mobileNumber);
    } else {
      formik.setFieldValue('whatsappNumber', '');
    }

    setPhoneNumber(!phoneNumber);
  };

  return (
    <FormikProvider value={formik}>


      <form
        id="kt_modal_add_agent_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className="d-flex flex-column flex-lg-row"
          id="kt_modal_add_agent_scroll"
        >
          <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title ms-7'>
                  <h2>Agent Profile Image</h2>
                </div>
              </div>
              <UploadImage
                ImagePath={`${agentData.imagePath}`}
                HandleUpload={(img: ImageModel) => {
                }}
                HandleRemove={() => { setSelectedImage({}) }} />
            </div>
          </div>

          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10 '>
            <div className='card'>
              <div className="card-header">
                <div className="card-title">
                  <h2>Edit Agent</h2>
                </div>
              </div>
              <div className='card-body'>
                <div className="row mb-4">
                  <div className="col-6 col-md-6 mb-3">
                    <label className="required fw-bold fs-6 mb-2 ms-1">
                      First Name
                    </label>

                    <input
                      placeholder="First Name"
                      {...formik.getFieldProps("firstName")}
                      type="text"
                      name="firstName"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.firstName && formik.errors.firstName,
                        },
                        {
                          "is-valid":
                            formik.touched.firstName && !formik.errors.firstName,
                        }
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isAgentLoading}

                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.firstName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-6 col-md-6 mb-3">
                    <label className="required fw-bold fs-6 mb-2 ms-1">
                      Last Name
                    </label>
                    <input
                      placeholder="Last Name"
                      {...formik.getFieldProps("lastName")}
                      type="text"
                      name="lastName"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.lastName && formik.errors.lastName,
                        },
                        {
                          "is-valid":
                            formik.touched.lastName && !formik.errors.lastName,
                        }
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.lastName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-6 col-md-6 mb-3">
                    <label className="fw-bold fs-6 mb-2 ms-1">Company Name</label>
                    <input
                      placeholder="Company Name"
                      {...formik.getFieldProps("companyName")}
                      type="text"
                      name="companyName"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.companyName && formik.errors.companyName,
                        },
                        {
                          "is-valid":
                            formik.touched.companyName && !formik.errors.companyName,
                        }
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.companyName && formik.errors.companyName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.companyName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-6 col-md-6 mb-3">
                    <label className="required fw-bold fs-6 mb-2 ms-1">Email</label>

                    <input
                      placeholder="Email"
                      {...formik.getFieldProps("emailAddress")}
                      type="email"
                      name="emailAddress"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.emailAddress &&
                            formik.errors.emailAddress,
                        },
                        {
                          "is-valid":
                            formik.touched.emailAddress &&
                            !formik.errors.emailAddress,
                        }
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.emailAddress && formik.errors.emailAddress && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.emailAddress}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-6 col-md-6 mb-3">
                    <label className="required fw-bold fs-6 mb-2 ms-1">
                      Mobile Number
                    </label>
                    <input
                      placeholder="Mobile Number"
                      {...formik.getFieldProps("mobileNumber")}
                      type="text"
                      name="mobileNumber"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.mobileNumber &&
                            formik.errors.mobileNumber,
                        },
                        {
                          "is-valid":
                            formik.touched.mobileNumber &&
                            !formik.errors.mobileNumber,
                        }
                      )}
                      autoComplete="off"
                      onChange={(e) => {
                        if (phoneNumber) {
                          formik.setFieldValue('whatsappNumber', e.target.value);
                          formik.setFieldValue('mobileNumber', e.target.value)
                        }
                        else {
                          formik.setFieldValue('mobileNumber', e.target.value)

                        }

                      }}
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.mobileNumber &&
                      formik.errors.mobileNumber && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {formik.errors.mobileNumber}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>


                  <div className="col-6 col-md-6 mb-3">
                    <label className="fw-bold fs-6 mb-2 ms-1">
                      Whatsapp Number same as Mobile Number
                    </label>
                    <input
                      className="form-check-input ms-3"
                      type="checkbox"
                      checked={phoneNumber}
                      onChange={setWhatsapp}
                      disabled={formik.isSubmitting || isAgentLoading}
                    />

                    <input
                      placeholder="Whatsapp Number"
                      {...formik.getFieldProps("whatsappNumber")}
                      type="text"
                      name="whatsappNumber"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.whatsappNumber &&
                            formik.errors.whatsappNumber,
                        },
                        {
                          "is-valid":
                            formik.touched.whatsappNumber &&
                            !formik.errors.whatsappNumber,
                        }
                      )}
                      autoComplete="off"
                      onChange={(e) => {
                        if (phoneNumber) {
                          formik.setFieldValue('whatsappNumber', e.target.value);
                          formik.setFieldValue('mobileNumber', e.target.value)
                        }
                        else {
                          formik.setFieldValue('whatsappNumber', e.target.value)
                        }

                      }}
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.whatsappNumber &&
                      formik.errors.whatsappNumber && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {formik.errors.whatsappNumber}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-6 col-md-6 mb-3">
                    <label className="fw-bold fs-6 mb-2 ms-1">AddressLine 1</label>

                    <input
                      placeholder="addressLine1"
                      {...formik.getFieldProps("addressLine1")}
                      type="text"
                      name="addressLine1"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.addressLine1 && formik.errors.addressLine1,
                        },
                        {
                          "is-valid":
                            formik.touched.addressLine1 && !formik.errors.addressLine1,
                        }
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.addressLine1 && formik.errors.addressLine1 && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.addressLine1}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-6 col-md-6 mb-3">
                    <label className="fw-bold fs-6 mb-2 ms-1">AddressLine 2</label>

                    <input
                      placeholder="AddressLine 2"
                      {...formik.getFieldProps("addressLine2")}
                      type="text"
                      name="addressLine2"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.addressLine2 && formik.errors.addressLine2,
                        },
                        {
                          "is-valid":
                            formik.touched.addressLine2 && !formik.errors.addressLine2,
                        }
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.addressLine2 && formik.errors.addressLine2 && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.addressLine2}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-4 ">
                  <div className="col-6 col-md-6 mb-3">
                    <label className="required fw-bold fs-6 mb-2 ms-1">
                      State
                    </label>
                    <Field
                      className={clsx(
                        'form-select-solid ms-1',
                        { 'is-invalid': formik.touched.stateId && formik.errors.stateId },
                        {
                          'is-valid': formik.touched.stateId && !formik.errors.stateId,
                        }
                      )}
                      component={CustomSelect}
                      options={stateList}
                      placeholder='Select State'
                      id='stateId'
                      name='stateId'
                      onChange={(e: { value: any }) => formik.setFieldValue('stateId', e?.value)}
                    ></Field>
                    {formik.touched.stateId && formik.errors.stateId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.stateId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-6 col-md-6 mb-3">
                    <label className="required fw-bold fs-6 mb-2 ms-1">
                      City
                    </label>
                    <input
                      placeholder="city"
                      {...formik.getFieldProps("city")}
                      type="text"
                      name="city"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.city && formik.errors.city,
                        },
                        {
                          "is-valid":
                            formik.touched.city && !formik.errors.city,
                        }
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.city}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-6 col-md-6 mb-3">
                    <label className="fw-bold fs-6 mb-2 ms-1">ZipCode</label>

                    <input
                      placeholder="zipCode"
                      {...formik.getFieldProps("zipCode")}
                      type="text"
                      name="zipCode"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.zipCode && formik.errors.zipCode,
                        },
                        {
                          "is-valid":
                            formik.touched.zipCode && !formik.errors.zipCode,
                        }
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.zipCode && formik.errors.zipCode && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.zipCode}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-6 col-md-6 mb-3">
                    <label className="fw-bold fs-6 mb-2 ms-1">CommissionPercent</label>

                    <input
                      placeholder="commissionPercent"
                      {...formik.getFieldProps("commissionPercent")}
                      type="text"
                      name="commissionPercent"
                      className={clsx(
                        "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                        {
                          "is-invalid":
                            formik.touched.commissionPercent && formik.errors.commissionPercent,
                        },
                        {
                          "is-valid":
                            formik.touched.commissionPercent && !formik.errors.commissionPercent,
                        }
                      )}
                      autoComplete="off"
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.commissionPercent && formik.errors.commissionPercent && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.commissionPercent}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-4">
                        <div className="col-6 col-md-6 mb-3">
                          <label className="fw-bold fs-6 mb-2 ms-1">Date of Birth</label>

                          <div>
                            <Flatpickr
                              className='form-control'
                              placeholder='Pick date'
                              options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                allowInput: false

                              }}
                              {...formik.getFieldProps('dateOfBirth')}
                              onChange={(date) => {
                                console.log(date)
                                formik.setFieldValue('dateOfBirth', date[0])
                              }}
                            />


                            {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">{formik.errors.dateOfBirth}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-6 col-md-6 mb-3">
                          <label className="fw-bold fs-6 mb-2 ms-1">Date Of Anniversary</label>

                          <div>
                            <Flatpickr
                              className='form-control'
                              placeholder='Pick date'
                              options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                allowInput: false

                              }}
                              {...formik.getFieldProps('dateOfAnniversary')}
                              onChange={(date) => {
                                console.log(date)
                                formik.setFieldValue('dateOfAnniversary', date[0])
                              }}
                            />


                            {formik.touched.dateOfAnniversary && formik.errors.dateOfAnniversary && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">{formik.errors.dateOfAnniversary}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                </div>

                <div className="row mb-7">
                  <div className="col-6 col-md-6 ">
                    <label className="fw-bold fs-6 mb-2 ms-1">Rate Applicable</label>

                    <Field
                      className="form-select-solid"
                      options={customerType}
                      component={CustomSelect}
                      {...formik.getFieldProps("rateId")}
                    ></Field>
                    {formik.touched.rateId && formik.errors.rateId && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.rateId}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-6 col-md-6">
                    <label className='required fw-bold fs-6 mb-2 ms-1'>Status</label>
                    <Field
                      className={clsx(
                        'form-select-solid ms-1',
                        { 'is-invalid': formik.touched.statusId && formik.errors.statusId },
                        {
                          'is-valid': formik.touched.statusId && !formik.errors.statusId,
                        }
                      )}
                      {...formik.getFieldProps('statusId')}

                      name="statusId"
                      options={StatusOptions}
                      component={CustomSelect}
                      placeholder="Select Status"
                      isMulti={false}
                    ></Field>
                  </div>
                </div>

                {/* <div className="row mb-7">
                  <div className="col-6 col-md-6 mt-10 mb-3 ">
                    <div className="form-check form-check-custom form-check-solid">
                      <label
                        className="d-flex align-items-center fs-6 fw-bold  me-4"
                        htmlFor="enableLogin"
                      >
                        Enable Login
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="enableLogin"
                        checked={{ ...formik.getFieldProps("enableLogin") }.value}
                        {...formik.getFieldProps("enableLogin")}
                        disabled={formik.isSubmitting || isAgentLoading} />
                    </div>
                  </div>
                </div> */}
                {formik.values.userId !== null ? <div className="row mb-4">
                  <div className="col-6 col-md-6 mt-10 mb-3 ">
                    <div className="form-check form-check-custom form-check-solid">
                      <label
                        className="d-flex align-items-center fs-6 fw-bold  me-4"
                        htmlFor="enableLogin"
                      >
                        Enable Login
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="enableLogin"
                        checked={{ ...formik.getFieldProps("enableLogin") }.value}
                        {...formik.getFieldProps("enableLogin")}
                        disabled={formik.isSubmitting || isAgentLoading} />
                    </div>
                  </div>
                </div> : <><div className="row mb-7">
                  <div className="col-6 col-md-6 mt-10 mb-3 ">
                    <div className="form-check form-check-custom form-check-solid">
                      <label
                        className="d-flex align-items-center fs-6 fw-bold  me-4"
                        htmlFor="enableLogin"
                      >
                        Enable Login
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="enableLogin"
                        checked={{ ...formik.getFieldProps("enableLogin") }.value}
                        {...formik.getFieldProps("enableLogin")}
                        disabled={formik.isSubmitting || isAgentLoading} />
                    </div>
                  </div>
                </div>

                  {formik.values.enableLogin ?

                    <div className="row mb-7">
                      <div className="col-6 col-md-6 mb-3">
                        <label className="fw-bold fs-6 mb-2 ms-1">UserName</label>

                        <input
                          placeholder="UserName"
                          {...formik.getFieldProps("userName")}
                          type="text"
                          name="userName"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                            {
                              "is-invalid": formik.touched.userName && formik.errors.userName,
                            },
                            {
                              "is-valid": formik.touched.userName && !formik.errors.userName,
                            }
                          )}

                          autoComplete="off"
                          disabled={formik.isSubmitting || isAgentLoading} />
                        {formik.touched.userName && formik.errors.userName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{formik.errors.userName}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-6 col-md-6 mb-3">
                        <label className="fw-bold fs-6 mb-2 ms-1">Password</label>

                        <input
                          placeholder="password"
                          {...formik.getFieldProps("password")}
                          type="password"
                          name="password"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1",
                            {
                              "is-invalid": formik.touched.password && formik.errors.password,
                            },
                            {
                              "is-valid": formik.touched.password && !formik.errors.password,
                            }
                          )}
                          autoComplete="off"
                          disabled={formik.isSubmitting || isAgentLoading} />
                        {formik.touched.password && formik.errors.password && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">{formik.errors.password}</span>
                            </div>
                          </div>
                        )}
                      </div>

                    </div> : ''}</>}


                
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        className='btn btn-light me-3'
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_1"
                        disabled={formik.isSubmitting}
                      >
                        Discard
                      </button>
                      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h2>Confirmation</h2>
                              <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <KTSVG
                                  path="/media/icons/duotune/arrows/arr061.svg"
                                  className="svg-icon svg-icon-2x"
                                />
                              </div>
                            </div>
                            <div className="modal-body">
                              <div className="w-100">

                                {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
                            <i className="las la-exclamation-circle"></i>
                          </div> */}

                                <div className="fv-row mb-10 text-center">
                                  <h5>Are you sure you want to discard ?</h5>
                                </div>

                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                onClick={() => navigate('/sales/agent/list')}
                              >
                                Yes
                              </button>
                              <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-color-modal-action='submit'
                      // disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>Submit</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                </div>
                </div>
            </div>
          </div>
        </div>
      </form>
      {(formik.isSubmitting || isAgentLoading) && <Loading />}

    </FormikProvider >
  );
};


export { AgentEditPage };
