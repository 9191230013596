import { useQuery } from 'react-query'
import { useListView } from '../core/ListViewProvider'

import { getEmailTemplateById} from '../core/_requests'
import { isNotEmpty, QUERIES } from '../../../../../_metronic/helpers'
import { EmailTemplateForm } from './EmailTemplateForm'


const EmailTemplateModalWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: result,
    error,
  } = useQuery(
    `${QUERIES.EMAIL_TEMPLATE}-${itemIdForUpdate}`,
    async () => {
      return await getEmailTemplateById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <EmailTemplateForm isEmailTemplateLoading={isLoading} data={{ id: undefined }} />
  }

  if (!isLoading && !error && result) {
    return <EmailTemplateForm isEmailTemplateLoading={isLoading} data={result} />
  }

  return null
}

export { EmailTemplateModalWrapper }
