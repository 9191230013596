import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import {  ExpenseCategoryWrapper } from './pages/ExpenseCategoryList'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../core/shared/core/permissionUtils'
import UnAuthorized from '../../errors/components/UnAuthorized'
const Breadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ExpenseCategoryPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='list'
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Expense Category </PageTitle>
                            {MustHavePermission(SWSection.Store, SWResource.ExpenseCategories, SWAction.Search) ?
                            <ExpenseCategoryWrapper />
                            :<UnAuthorized/>}
                        </>
                    }
                />             

            </Route>
        </Routes>
    )
}

export default ExpenseCategoryPage
