/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
// import {useQuery} from '@tanstack/react-query'
import {useQueryRequest} from './QueryRequestProvider'
import {
  createResponseContext,
  stringifyRequestQuery,
} from '../../../../../../_metronic/helpers/crud-helper/helpers'
import {WithChildren} from '../../../../../../_metronic/helpers'
import {
  PaginationResponse,
  initialQueryResponse,
} from '../../../../../../_metronic/helpers/crud-helper/models'
import {QUERIES} from '../../../../../../_metronic/helpers/crud-helper/consts'
import {Payment} from './_model'
import {getPaymentList} from './_request'
import {useQuery} from 'react-query'

const QueryResponseContext = createResponseContext<Payment>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])


  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`${QUERIES.PAYMENT_LIST}-${query}`],
    () => {
      return getPaymentList(state)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationResponse = {
    currentPage: 1,
    pageSize: 10,
  }
  const {response} = useQueryResponse()
  if (!response || !response.pagination) {
    return defaultPaginationState
  }

  return response.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
