import React, { FC, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Filter, KTCard, KTCardBody, PaginationFilter } from '../../../../_metronic/helpers';
import { getProductList } from '../product/product-list/core/_requests';
import { Product } from '../product/product-list/core/_models';
import { SelectOptionProps } from '../../../core/models/SelectOptionProps';

import Barcode from 'react-barcode';
import GenerateBarcode from './Barcode';
import { useReactToPrint } from 'react-to-print'
import PrintButton from './PrintButton';
import { getProductGroupList } from '../productGroup/productgroup-list/core/_requests';
import { Color } from 'chart.js';
import clsx from 'clsx';
import { PolishingType } from '../../../core/shared/core/_model';
import { getColorList, getPolishingTypeList } from '../../../core/shared/core/_request';
import { useLocation } from 'react-router-dom';
import { ProductGroup } from '../productGroup/productgroup-list/core/_models';


const PrintBarcodePage: FC = () => {
  const location = useLocation();
  const selectedIds = location.state?.selectedIds ?? [];
  const componentRef = useRef<PrintButton>(null);
  const [productList, setProductList] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [showPolishingType, setShowPolishingType] = useState(true);
  const [showColorName, setShowColorName] = useState(true);
  const [showPrice, setShowPrice] = useState<number>(0);
  const [quantities, setQuantities] = useState<{ [productId: number]: number }>({});
  const [selectAllColors, setSelectAllColors] = useState(false);
  const [selectAllPolishingType, setSelectAllPolishingType] = useState(false);
  const [colorList, setColorList] = useState<SelectOptionProps[]>([]);
  const [productGroupList, setProductGroupList] = useState<SelectOptionProps[]>([]);
  const [polishingTypeList, setPolishingTypeList] = useState<SelectOptionProps[]>([]);
  const [colorIds, setColorIds] = useState<SelectOptionProps[]>([]);
  const [polishIds, setPolishIds] = useState<SelectOptionProps[]>([])

  const handleQuantityChange = (productId: any, newQuantity: number) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
  };

  const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,
  };


  const handlePolishingTypeChange = () => {
    setShowPolishingType(!showPolishingType);
  };

  const handleColorNameChange = () => {
    setShowColorName(!showColorName);
  };

  const handlePriceChange = (e: any) => {
    setShowPrice(e);
  };
  const handlePreview = () => {
    setPreviewVisible(true);
  };

  const handleReset = () => {
    setPreviewVisible(false);

  };

  const printPaymentReceiptPdf = (event) => {

    event.preventDefault();
    (document.getElementById('printReceipt') as HTMLElement).click();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // print barcode
  const loadPrintBlock = () => {
    return (
      <div className='d-none'>
        <button id='printReceipt' onClick={handlePrint}>Print this out!</button>
        <PrintButton ref={componentRef} products={selectedProducts} pricesep={showPrice} quantity={quantities as unknown as number} />
      </div>
    );
  };

  const ref = useRef<Barcode>(null);
  const generateBarcode = () => {

    const barcodeElements: React.ReactNode[] = [];

    selectedProducts.forEach((product, index) => {
      const barcodeValue = `${product.barcode || ''}`;
      const productQuantity = quantities[product.id as number] || 1
      for (let i = 0; i < productQuantity; i++) {
        barcodeElements.push(
          <div key={`${index}-${i}`} className="col-md-3 mb-3 me-7">
            {showPolishingType && <div className="text-capitalize ms-4">{product.polishingTypeName}</div>}
            <GenerateBarcode value={barcodeValue} displayValue={false} format="CODE128" width={1} height={40} textAlign={"center"} margin={0} />
            {/* <Barcode ref={ref} value={barcodeValue} format="CODE128" width={1} displayValue={false} height={30} textAlign={"center"} /> */}
            {showColorName && <div className="text-capitalize ms-4">{product.colorName} {product.spSemiWholeSeller}{showPrice}{product.spRetail}</div>}
          </div>

        );
      }
    });

    return (<>
      <div className="row w-100" style={{ border: '1px solid #ccc', margin: '10px auto', padding: '10px', pageBreakBefore: 'always' }}>
        {barcodeElements}</div>


    </>
    )
  };

  const paperSizeOptions = [
    { value: 1, label: '40 per sheet (a4)(1.799 * 1.003)' },
    { value: 2, label: '30 per sheet (2.625 * 1' },
    { value: 3, label: '20 per sheet (4 * 1' },
    { value: 4, label: '10 per sheet (4 * 2' },

  ];



  const displayProducts = () => {

    setSelectedProducts((prevProducts) => [...prevProducts, ...productList as Product[]]);
    setSelectAllColors(false);
    setSelectAllPolishingType(false);
    setPolishIds([]);
    setColorIds([])
    // setProductGroupList([])
  }

  const handleProductChange = async (selectedOption) => {
    
    if (selectedOption) {
      try {
        //setSelectedProductGroup(selectedOption);
        //setProductGroupList(selectedOption)
        const updatedPaginationFilter: PaginationFilter = {
          ...paginationFilter,
          advancedFilter: {
            filters: [
              { field: 'isActive', operator: 'eq', value: true },
              {
                field: "productGroupId",
                operator: "eq",
                value: selectedOption.value,
              }
            ],
            logic: "and"
          }
        };

        const products = (await getProductList(updatedPaginationFilter)).data
        setProductList(products as Product[])

      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    }
  };


  const handleColorChange = (e) => {
    setColorIds(e)
  }

  const handlePolishingChange = (e) => {
    setPolishIds(e)
  }
  const handleRemoveProduct = (index: number) => {
    setSelectedProducts((prevProducts) => [...prevProducts.slice(0, index), ...prevProducts.slice(index + 1)]);
  };
  useEffect(() => {

    if (ref.current) {
      handlePrint()
    }
  }, [ref.current]);


  useEffect(() => {

    const fetchInitialData = async () => {
      let result: any


      let colorArray: any[] = []

      await getColorList(paginationFilter).then((v) => {
        result = v.data as Color[]
        result.map((item: any) => {
          return colorArray.push({ value: item.id, label: item.name, colorCode: item.colorCode })
        })
        setColorList(colorArray);
      })

      let polishingTypeArray: any[] = []

      await getPolishingTypeList(paginationFilter).then((v) => {
        result = v.data as PolishingType[]
        result.map((item: any) => {
          return polishingTypeArray.push({ value: item.id, label: item.name })
        })
        setPolishingTypeList(polishingTypeArray)
      })


      let productGrpArray: any[] = []

      await getProductGroupList(paginationFilter).then((v) => {
        result = v.data as ProductGroup[];
        result && result.map((item: ProductGroup) => {
          return productGrpArray.push({ value: item.id, label: item.name })
        });
        setProductGroupList(productGrpArray);
      })
    }
    fetchInitialData();

  }, []);
  
  // useEffect(() => {
  //   let result: any
  //   let productGrpArray: any[] = []
  //   getProductGroupList(paginationFilter).then((v) => {
  //   result = v.data as ProductGroup[];
  //    productGrpArray= result.map((item: ProductGroup) => ({
  //       value: item.id,
  //       label: item.name,
  //     }));
  //     setProductGroupList(productGrpArray);
  //   })

  // }, []);

  const handleSelectAllColors = (e: any) => {
    const isChecked = e.target.checked;
    setSelectAllColors(isChecked);

    if (isChecked) {
      let allColorIds: any[] = []
      colorList.map((option) => {
        return allColorIds.push({ value: option.value, label: option.label })
      }
      )
      setColorIds(allColorIds as []);
      

    } else {
      setColorIds([]);
    }
  };

  const handleSelectAllPolishingType = (e: any) => {
    const isChecked = e.target.checked;

    setSelectAllPolishingType(isChecked);

    if (isChecked) {
      let allPT: any[] = []
      polishingTypeList.map((option) => {
        return allPT.push({ value: option.value, label: option.label })
      }
      )
      setPolishIds(allPT as []);

    } else {
      setPolishIds([]);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      let allProducts: Product[] = [];
      for (let id of selectedIds) {
        const updatedPaginationFilter: PaginationFilter = {
          ...paginationFilter,
          advancedFilter: {
            filters: [
              { field: 'isActive', operator: 'eq', value: true },
              {
                field: "id",
                operator: "eq",
                value: id,
              }
            ],
            logic: "and"
          }
        };

        try {
          const products = (await getProductList(updatedPaginationFilter)).data;
          allProducts = [...allProducts, ...products as Product[]];
        } catch (error) {
          console.error(`Error fetching products for id ${id}`, error);
        }
      }

    setSelectedProducts(allProducts as Product[]);
     displayProducts()
    }
    fetchProducts();


  }, [selectedIds !== undefined])

  // if (selectedIds) {

  //   const fetchProducts = async () => {

  //     let allProducts: Product[] = [];
  //     for (let id of selectedIds) {
  //       const updatedPaginationFilter: PaginationFilter = {
  //         ...paginationFilter,
  //         advancedFilter: {
  //           filters: [
  //             { field: 'isActive', operator: 'eq', value: true },
  //             {
  //               field: "id",
  //               operator: "eq",
  //               value: id,
  //             }
  //           ],
  //           logic: "and"
  //         }
  //       };

  //       try {
  //         const products = (await getProductList(updatedPaginationFilter)).data;
  //         allProducts = [...allProducts, ...products as Product[]];
  //       } catch (error) {
  //         console.error(`Error fetching products for id ${id}`, error);
  //       }
  //     }

  //     setProductList(allProducts as Product[]);
  //   }
  //   fetchProducts();
  // }


  return (
    <>
      {selectedProducts.length !== 0 ? loadPrintBlock() : ''}

      <KTCard>
        <div className='card-header'>
          <div className='card-title'>
            <h2>Print Barcode</h2>
          </div>
        </div>
        <KTCardBody>
          <div className='row mb-8 align-items-end'>
            <div className='col-3 col-md-3'>
              <label className='form-label'>Select Product:</label>
              <Select
              options={productGroupList}
              onChange={(e)=>handleProductChange(e)}
              />

              

              {/* <Select
                //name='productGroupId'
                className={clsx(
                  'form-select-solid ms-1',
                )}
                onChange={(e) => handleProductChange(e)}
                options={productGroupList}
                placeholder='Select a product'
                isMulti={false}
              /> */}
            </div>
            <div className="col-3 col-md-3">
              <label className="required form-label">Color</label>
              <label className='form-check-label ms-5'>
                <input
                  className='form-check-input ms-7 me-4'
                  type='checkbox'
                  name='allColors'
                  checked={selectAllColors}
                  onChange={handleSelectAllColors}
                />Select All Colors</label>

              <Select
                className={clsx(
                  'form-select-solid ms-1',
                )}
                //value={colorIds}
                name="colorIds"
                options={colorList}
                onChange={(selectedcolor) => handleColorChange(selectedcolor)}
                placeholder="Select Color"
                isMulti={true}
              ></Select>

            </div>

            <div className="col-4 col-md-4">
              <label className="required form-label">Polishing Type</label>
              <label className='form-check-label ms-5'>
                <input
                  className='form-check-input ms-7 me-4'
                  type='checkbox'
                  name='allPolishingType'
                  checked={selectAllPolishingType}
                  onChange={handleSelectAllPolishingType}
                />Select All Polishing Type</label>
              <Select
                className={clsx(
                  'form-select-solid ms-1',
                )}
                //value={polishIds}
                name="polishingTypeIds"
                options={polishingTypeList}
                onChange={(selectedPolishing) => handlePolishingChange(selectedPolishing)}
                placeholder="Select PolishingType"
                isMulti={true}
              ></Select>


            </div>
            <div className='col-2 col-md-2'>
              <button className='btn btn-primary mt-6' onClick={displayProducts}> Add</button>
            </div>
          </div>

          {selectedProducts.length > 0 && (
            <div>
              <h3>Selected Products</h3>
              <table className='table'>
                <thead style={{ height: '40px' }}>
                  <th className='bg-light p-4'>Product Name</th>
                  <th className='bg-light p-4'>Quantity</th>
                  <th className='bg-light p-4'>Actions</th>
                </thead>
                <tbody>
                  {selectedProducts.map((product, index) => (
                    <tr key={index}>
                      <td>{product.name}</td>
                      <td> <input
                        type='number'
                        className='form-control'
                        value={quantities[product.id as number] ||1}
                        onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value, 10))}
                        //min={1}
                      />
                      </td>
                      <td>
                        <button
                          type='button'
                          data-repeater-delete=''
                          className='btn btn-sm btn-icon btn-light-danger mt-2'
                          onClick={() => handleRemoveProduct(index)}
                        >
                          <i className='ki-duotone ki-cross fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                          </i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className='row mb-5'><div className='col-3 col-md-3'></div>



              </div>
            </div>
          )}
          <div className="row">
            <div className="ml-auto mb-5 col-6 col">
              <label className="form-label">Paper Size:</label>
              <Select
                className="react-select-container ms-1"
                classNamePrefix="react-select"
                // onChange={(selectedOption) => setSelectedPaperSize(selectedOption)}
                options={paperSizeOptions}
                placeholder="Select paper size"
              />
            </div>

            <div className="d-flex col-6 col">
              <div className="mt-3">
                <div>Show Polishing Type</div>
                <div className="d-flex align-items-center mt-2">
                  <label className="form-check form-switch form-switch-sm">
                    <input
                      type="checkbox"
                      name="showPolishingType"
                      className="me-3 form-check-input cursor-pointer"
                      checked={showPolishingType}
                      onChange={handlePolishingTypeChange}
                    />
                    <div className="control__indicator" />
                  </label>
                  <span className="switch-slider" data-checked="✓" data-unchecked="✕" />
                </div>
              </div>
              <div className="mt-3 ms-10 mb-5">
                <div>Show Color Name</div>
                <div className="align-items-center mt-2">
                  <label className="form-check form-switch form-switch-sm">
                    <input
                      type="checkbox"
                      name="showColorName"
                      className="me-3 form-check-input cursor-pointer"
                      checked={showColorName}
                      onChange={handleColorNameChange}
                    />
                    <div className="control__indicator" />
                  </label>
                  <span className="switch-slider" data-checked="✓" data-unchecked="✕" />
                </div>
              </div>
              <div className='mt-3 ms-10'>
                <label>Price Separator</label>
                <input
                  type='text'
                  name='priceseparator'
                  className='form-control'
                  onChange={(newValue: any) => {
                    handlePriceChange(newValue.target.value)
                  }}
                ></input>
              </div>
              {/* <div className="mt-3 ms-10">
                <div>Show Price</div>
                <div className="d-flex align-items-center mt-2">
                  <label className="form-check form-switch form-switch-sm">
                    <input
                      type="checkbox"
                      name="showPrice"
                      className="me-3 form-check-input cursor-pointer"
                      checked={showPrice}
                      onChange={handlePriceChange}
                    />
                    <div className="control__indicator" />
                  </label>
                  <span className="switch-slider" data-checked="✓" data-unchecked="✕" />
                </div>
              </div> */}
            </div>
          </div>


          <div className="d-xl-flex align-items-center justify-content-between">
            <div className="d-xl-flex align-items-center justify-content-between">
              <button type="button" className="btn btn-success me-5 text-white mb-2" onClick={handlePreview}>
                Preview
              </button>
              <button type="button" className="btn btn-danger me-5 mb-2" onClick={handleReset}>
                Reset
              </button>
              <button type="button" className="btn btn-primary me-5 mb-2 btn btn-primary" onClick={printPaymentReceiptPdf}>
                Print
              </button>
              {/* <ReactToPrint
                trigger={() => <button className="btn btn-primary me-5 mb-2">Print</button>}
                content={() => ref.current}
              /> */}
            </div>
          </div>

          <div className="col-md-12 d-flex d-wrap justify-content-between flex-column overflow-auto">

            {previewVisible && (
              <div className="row mt-5">
                <div className="col-12">
                  <h3>Preview</h3>
                  {generateBarcode()}


                </div>
              </div>
            )}
          </div>
          {loadPrintBlock()}
        </KTCardBody>
      </KTCard>

    </>
  );
};

export default PrintBarcodePage;
