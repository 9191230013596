import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { SEOListWrapper } from './SEOList'
import SEOAddPage from './pages/SEOAddPage'
import { SEOWrapper } from './pages/SEOWrapper'




const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SEOPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>SEO List</PageTitle>
              <SEOListWrapper />
            </>
          }
        />

        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add SEO</PageTitle>
              <SEOAddPage />
            </>
          }
        />

        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Edit SEO</PageTitle>
              <SEOWrapper />
            </>
          }
        />

      </Route>
      <Route index element={<Navigate to='/cms/seo/list' />} />
    </Routes>
  )
}

export default SEOPage
