import { ID, Response } from "../../../../../_metronic/helpers/crud-helper/models";
export interface Testimonial {
    id?: ID
    name?:string
    uploadImage?: updateImage
    imagePath?: string
    testimonialImagePath?: string
    description ?: string
    designation ?: string
   
}
export type TestimonialQueryResponse = Response<Array<Testimonial>>

export type updateImage = {
    name?: string,
    extension?: string,
    data?: string
}

export const initialTestimonial: Testimonial = {
    name:'',
    imagePath:'',
    testimonialImagePath:'',
    description:'',
    designation:''
   
}


