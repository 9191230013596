import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { Filter, initialQueryState, PaginationFilter } from '../../../../../../../../_metronic/helpers';
import { SelectOptionProps } from '../../../../../../../core/models/SelectOptionProps';
import { useQueryRequest } from '../../../core/QueryRequestProvider';

import { getPaymentModeList } from '../../../../../../settings/paymode/core/_requests';
import { PaymentMode } from '../../../../../../settings/paymode/core/_models';
import Flatpickr from 'react-flatpickr';
// import 'flatpickr/dist/flatpickr.min.css';
import { getCategoryList } from '../../../../../../catalog/category/category-list/core/_requests';
import { Category } from '../../../../../../catalog/category/category-list/core/_models';
import { getProductGroupList } from '../../../../../../catalog/productGroup/productgroup-list/core/_requests';
import { ProductGroup } from '../../../../../../catalog/productGroup/productgroup-list/core/_models';
import { getProductList } from '../../../../../../catalog/product/product-list/core/_requests';
import { Product } from '../../../../../../catalog/product/product-list/core/_models';
import { useNavigate } from 'react-router-dom';
import { getColorList, getPolishingTypeList } from '../../../../../../../core/shared/core/_request';
import { Color, PolishingType } from '../../../../../../../core/shared/core/_model';




const ProductAdvancedSearch = () => {
    const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
    const [categoryList, setCategoryList] = useState<SelectOptionProps[]>([]);
    const [productGroupList, setProductGroupList] = useState<SelectOptionProps[]>([]);
    const [productList, setProductList] = useState<SelectOptionProps[]>([]);

    const { state, updateState } = useQueryRequest()
    const [filterStatus, updateFilterStatus] = useState<boolean | undefined>()
    const [filterCategoryId, updateFilterCategoryId] = useState<number[] | undefined>(undefined)
    const [filterProductGroupId, updateFilterProductGroupId] = useState<number[] | undefined>(undefined)
    const [filterProductId, updateFilterProductId] = useState<number[] | undefined>(undefined)
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
    // const [filterlastUpdatedOnFrom, setFilterlastUpdatedOnFrom] = useState<string | undefined>(undefined);
    // const [filterlastUpdatedOnTo, setFilterlastUpdatedOnTo] = useState<string | undefined>(undefined);
    const [filterColorId, updateFilterColorId] = useState<number[] | undefined>(undefined)
    const [filterPolishingTypeId, updateFilterPolishingTypeId] = useState<number[] | undefined>(undefined)
    const [colorList, setColorList] = useState<SelectOptionProps[]>([]);
    const [polishingTypeList, setPolishingTypeList] = useState<SelectOptionProps[]>([]);

    const paginationFilter: PaginationFilter = {
        pageNumber: 1,
        pageSize: 500,
    };

    const [dateState, setDateState] = useState<any>({
        date1: new Date(),
        date2: new Date()
    });

    useEffect(() => {
        let result: any[] = []
        const updatedPaginationFilter: PaginationFilter = {
            ...paginationFilter,
            advancedFilter: {
              field: 'isActive',
              operator: 'eq',
              value: true,
            },
          }

        getCategoryList(updatedPaginationFilter).then((v) => {
            result = v.data as Category[]
            const categoryArray: any[] = []
            result.map((item: any) => {
                return categoryArray.push({ value: item.id, label: item.printName })
            })
            setCategoryList(categoryArray)
        })

        getProductGroupList(updatedPaginationFilter).then((v) => {
            result = v.data as ProductGroup[]
            let productGroupArray: any[] = []
            result.map((item: any) => {
                return productGroupArray.push({ value: item.id, label: item.name })
            })
            setProductGroupList(productGroupArray)
        })

        getProductList(paginationFilter).then((v) => {
            result = v.data as Product[]
            let productArray: any[] = []
            result.map((item: any) => {
                return productArray.push({ value: item.id, label: item.name })
            })
            setProductList(productArray)
        })

        getColorList(updatedPaginationFilter).then((v) => {
            result = v.data as Color[]
            let colorArray: any[] = []
            result.map((item: any) => {
              return colorArray.push({ value: item.id, label: item.name })
            })
            setColorList(colorArray)
          })

          getPolishingTypeList(updatedPaginationFilter).then((v) => {
            result = v.data as PolishingType[]
            let ptArray: any[] = []
            result            
            .map((item: any) => {
              return ptArray.push({ value: item.id, label: item.name })
            })
            setPolishingTypeList(ptArray)
          })

    }, [])

    // const handleDateChange = (selectedDates: Date[], dateName: string) => {
    //     if (dateName === 'from') {
    //         setFilterlastUpdatedOnFrom(selectedDates[0]?.toISOString().split('T')[0]);
    //     } else if (dateName === 'to') {
    //         setFilterlastUpdatedOnTo(selectedDates[0]?.toISOString().split('T')[0]);
    //     }
    // };

    const updateSearchFilters = () => {

        let filters: Filter[] = []
        if (filterStatus != undefined) {
            filters.push({
                field: 'isActive',
                operator: 'eq',
                value: filterStatus
            })
        }

        if (filterCategoryId != undefined) {
            let categoryFilter: Filter[] = []

            filterCategoryId.map((id) => {
                categoryFilter.push({
                    field: 'product.productGroup.category.Id',
                    operator: 'eq',
                    value: id
                })
            })

            if (categoryFilter.length > 1) {
                filters.push({
                    filters: categoryFilter,
                    logic: 'or'
                })
            }
            else if (categoryFilter.length == 1) {
                filters.push(categoryFilter[0])
            }
        }

        if (filterProductGroupId != undefined) {
            let ProductGroupFilter: Filter[] = []

            filterProductGroupId.map((id) => {
                ProductGroupFilter.push({
                    field: 'product.productGroup.Id',
                    operator: 'eq',
                    value: id
                })
            })

            if (ProductGroupFilter.length > 1) {
                filters.push({
                    filters: ProductGroupFilter,
                    logic: 'or'
                })
            }
            else if (ProductGroupFilter.length == 1) {
                filters.push(ProductGroupFilter[0])
            }
        }

        if (filterProductId != undefined) {
            let ProductFilter: Filter[] = []

            filterProductId.map((id) => {
                ProductFilter.push({
                    field: 'productId',
                    operator: 'eq',
                    value: id
                })
            })

            if (ProductFilter.length > 1) {
                filters.push({
                    filters: ProductFilter,
                    logic: 'or'
                })
            }
            else if (ProductFilter.length == 1) {
                filters.push(ProductFilter[0])
            }
        }

        // if (filterlastUpdatedOnFrom || filterlastUpdatedOnTo) {
        //     let lastUpdatedOnFilter: Filter[] = [];

        //     if (filterlastUpdatedOnFrom) {
        //         lastUpdatedOnFilter.push({
        //             field: 'lastUpdatedOn',
        //             operator: 'gte',
        //             value: filterlastUpdatedOnFrom,
        //         });
        //     }

        //     if (filterlastUpdatedOnTo) {
        //         lastUpdatedOnFilter.push({
        //             field: 'lastUpdatedOn',
        //             operator: 'lte',
        //             value: filterlastUpdatedOnTo,
        //         });
        //     }

        //     filters.push({
        //         filters: lastUpdatedOnFilter,
        //         logic: 'and',
        //     });
        // }

        if (filterColorId != undefined) {
            let colorFilter: Filter[] = []

            filterColorId.map((id) => {
                colorFilter.push({
                    field: 'product.colorId',
                    operator: 'eq',
                    value: id
                })
            })

            if (colorFilter.length > 1) {
                filters.push({
                    filters: colorFilter,
                    logic: 'or'
                })
            }
            else if (colorFilter.length == 1) {
                filters.push(colorFilter[0])
            }
        }

        if (filterPolishingTypeId != undefined) {
            let polishingTypeFilter: Filter[] = []

            filterPolishingTypeId.map((id) => {
                polishingTypeFilter.push({
                    field: 'product.polishingTypeId',
                    operator: 'eq',
                    value: id
                })
            })

            if (polishingTypeFilter.length > 1) {
                filters.push({
                    filters: polishingTypeFilter,
                    logic: 'or'
                })
            }
            else if (polishingTypeFilter.length == 1) {
                filters.push(polishingTypeFilter[0])
            }
        }


        if (filters.length > 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: {
                    filters: filters,
                    logic: 'and'
                }, ...initialQueryState
            })
        }

        else if (filters.length === 1) {
            updateState({
                keyword: searchTerm,
                advancedFilter: filters[0],
                ...initialQueryState
            })
        }
        else {
            updateState({
                keyword: searchTerm,
                advancedFilter: undefined,
                ...initialQueryState
            })
        }
    }

    return (
        <>

            <div className="card mb-7">
                <div className="card-body">
                    <div className="row mb-7">
                        <div className="col-lg-6">
                            <div className="input-group">
                                <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    id="txtsearchText"
                                    placeholder="Search Product"
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <button
                                className="btn btn-link"
                                onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
                            >
                                Advanced Search
                            </button>
                        </div>
                        <div className='col-lg-2 '>
                            <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}>Back
                            </button>
                        </div>
                    </div>

                    {advancedSearchVisible && (
                        <>
                            <div className="collapse" id="kt_advanced_search_form">
                                <div className="separator separator-dashed mt-9 mb-6"></div>
                            </div>


                            <div className="row g-5 mb-5">

                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Category
                                    </label>
                                    <Select
                                        className='form-select-solid ms-1'
                                        name="categoryId"
                                        options={categoryList}
                                        placeholder="Select Category"
                                        onChange={(e) => updateFilterCategoryId((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                        isClearable={true}
                                        isMulti={true}
                                    ></Select>
                                </div>
                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Product Group
                                    </label>
                                    <Select
                                        className='form-select-solid ms-1'
                                        name="productGroupId"
                                        options={productGroupList}
                                        placeholder="Select ProductGroup"
                                        onChange={(e) => updateFilterProductGroupId((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                        isClearable={true}
                                        isMulti={true}
                                    ></Select>
                                </div>
                                <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark" >
                                        Product
                                    </label>
                                    <Select
                                        className='form-select-solid ms-1'
                                        name="productId"
                                        options={productList}
                                        placeholder="Select Product"
                                        onChange={(e) => updateFilterProductId((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                        isClearable={true}
                                        isMulti={true}
                                    ></Select>
                                </div>

                                {/* <div className="col-lg-4">
                                    <label className="fs-6 form-label fw-bold text-dark">
                                        Last Updated On
                                    </label>
                                    <div className="row g-3">
                                        <div className="col">
                                        <Flatpickr
                                                    value={dateState.date1}
                                                    options={{ dateFormat: 'Y-m-d' }}
                                                    onChange={(selectedDates) => handleDateChange(selectedDates, 'from')}
                                                    className="form-control form-control-solid"
                                                    placeholder="From"
                                                />
                                        </div>
                                        <div className="col">
                                        <Flatpickr
                                                    value={dateState.date2}
                                                    options={{ dateFormat: 'Y-m-d' }}
                                                    onChange={(selectedDates) => handleDateChange(selectedDates, 'to')}
                                                    className="form-control form-control-solid"
                                                    placeholder="To"
                                                />
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className='row g-5 mb-5'>
                                        <div className="col-lg-4">
                                            <label className="fs-6 form-label fw-bold text-dark" >
                                                Color
                                            </label>
                                            <Select
                                                className='form-select-solid ms-1'
                                                name="colorId"
                                                options={colorList}
                                                placeholder="Select color"
                                                onChange={(e) => updateFilterColorId((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                                isClearable={true}
                                                isMulti={true}
                                            ></Select>
                                        </div>


                                        <div className="col-lg-4">
                                            <label className="fs-6 form-label fw-bold text-dark" >
                                                PolishingType
                                            </label>
                                            <Select
                                                className='form-select-solid ms-1'
                                                name="polishingTypeId"
                                                options={polishingTypeList}
                                                placeholder="Select polishingtype"
                                                onChange={(e) => updateFilterPolishingTypeId((e as SelectOptionProps[]).map((item: SelectOptionProps) => item.value))}
                                                isClearable={true}
                                                isMulti={true}
                                            ></Select>
                                        </div>
                                    </div>
                        </>
                    )}

                </div>

            </div >
        </>
    );

}

export default ProductAdvancedSearch