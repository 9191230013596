import { CollectionListSearchComponent } from "./CollectionListSearchComponent"
import { CollectionListToolbar } from "./CollectionListToolbar"

const CollectionListHeader = () => {

  return (
    <div className='card-header border-0 pt-6'>
      <CollectionListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <CollectionListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default CollectionListHeader
