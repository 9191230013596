import { FC, useEffect, useState } from 'react'
import { useImmer } from 'use-immer';
import { useNavigate } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { PaginationFilter, KTSVG } from '../../../../../_metronic/helpers';
import { Result } from '../../../../core/models/Result';
import CustomSelect from '../../../../core/shared/components/CustomSelect';
import { Loading } from '../../../../core/shared/components/Loading';
import { camelize } from '../../../../core/utils/StringHelpers';
import { getProductColor, getSalesOrderList } from '../../salesOrder/salesOrder-list/core/_requests';
import { Invoice, invoiceItemList, OtherCharges } from '../core/_models';
import { updateInvoice } from '../core/_requests';
import Moment from "moment";
import Flatpickr from 'react-flatpickr';
import { SalesOrderSetting } from '../../../settings/core/_models';
import { getSalesOrderSetting } from '../../../settings/core/_requests';
import ProductSearchbar from '../../../../core/shared/components/ProductSearchbar';
import { Product } from '../../../catalog/product/product-list/core/_models';
import { Customer, CustomerDetailDto } from '../../customer/customer-list/core/_models';
import { getCustomerById } from '../../customer/customer-list/core/_requests';
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps';
import { ColorDetails, SaleOrderDto } from '../../salesOrder/salesOrder-list/core/_models';
import { getOtherChargeList, getPlaceOfSupplyList, getPolishingTypeList, getStateList } from '../../../../core/shared/core/_request';
import Select from 'react-select';
import { getProductById } from '../../../catalog/product/product-list/core/_requests';
import { ProductGroup } from '../../../catalog/productGroup/productgroup-list/core/_models';
import { getProductGroupList } from '../../../catalog/productGroup/productgroup-list/core/_requests';
import { PlaceOfSupply, PolishingType } from '../../../../core/shared/core/_model';
import { Category } from '../../../catalog/category/category-list/core/_models';
import { getCategoryList } from '../../../catalog/category/category-list/core/_requests';
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";
type Props = {
  data: Result | Invoice;
};
const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
};


const InvoiceEditForm: FC<Props> = ({ data }) => {

  const invoiceData = data as Invoice
  let invoiceDate = Moment(invoiceData.invoiceDate);
  let dueDate = Moment(invoiceData.dueDate);


  useEffect(() => {
    getCustomerById(invoiceData.customerId).then((v) => {
      const result = v as CustomerDetailDto;
      setCustomerData(result)
    })
  }, [invoiceData])


  const initialValues = {
    id: invoiceData.id,
    saleOrderId: invoiceData.saleOrderId,
    invoiceNumber: invoiceData.invoiceNumber,
    invoiceStatusId: invoiceData.invoiceStatusId,
    customerId: invoiceData.customerId,
    customerName: invoiceData.customerName,
    roundOff: invoiceData.roundOff,
    discountApplicable: invoiceData.discountApplicable,
    invoiceDate: invoiceDate.toDate(),
    dueDate: dueDate.toDate(),
    billAddressLine1: invoiceData.billAddressLine1,
    billAddressLine2: invoiceData.billAddressLine2,
    billCityName: invoiceData.billCityName,
    billCountryId: invoiceData.billCountryId,
    billCountryName: invoiceData.billCountryName,
    billStateId: invoiceData.billStateId,
    billStateName: invoiceData.billStateName,
    shipAddressLine1: invoiceData.shipAddressLine1,
    shipAddressLine2: invoiceData.shipAddressLine2,
    shipCityName: invoiceData.shipCityName,
    shipCountryId: invoiceData.shipCountryId,
    shipCountryName: invoiceData.shipCountryName,
    shipStateId: invoiceData.shipStateId,
    shipStateName: invoiceData.shipStateName,
    orderTotal: invoiceData.orderTotal,
    orderSubTotal: invoiceData.orderSubTotal,
    totalDiscountedPrice: invoiceData.totalDiscountedPrice,
    totalTaxAmount: invoiceData.totalTaxAmount,
    totalOtherCharges: invoiceData.totalOtherCharges,
    placeOfSupplyId: invoiceData.placeOfSupplyId
  }

  let initialSaleOrderItems: invoiceItemList[] = [];
  invoiceData?.itemList.map((item, index) => {
    initialSaleOrderItems.push({
      invoiceItemId: item.invoiceItemId,
      rowNumber: initialSaleOrderItems.length,
      productId: item.productId,
      productName: item.productName,
      polishingTypeId: item.polishingTypeId,
      polishingTypeName: item.polishingTypeName,
      colorName: item.colorName,
      colorId: item.colorId,
      quantity: item.quantity,
      productPrice: item.productPrice,
      subTotal: item.subTotal,
      discountPercent: item.discountPercent,
      discountAmount: item.discountAmount,
      taxPercent: item.taxPercent,
      taxAmount: item.taxAmount,
      totalInclusiveTax: item.totalInclusiveTax,
      total: item.total,
      isDeleted: item.isDeleted,
      productImagePath: item.productImagePath,

    });
  })

  let initialOtherCharges: OtherCharges[] = [];
  invoiceData.otherCharges.map((item) => {
    initialOtherCharges.push({
      rowNumber: initialOtherCharges.length,
      id: item.id,
      value: item.value,
      label: item.label,
      sign: item.sign,
      isDeleted: item.isDeleted
    })
  })

  const [categoryList, setCategoryList] = useState<SelectOptionProps[]>([]);
  const [productGroupList, setProductGroupList] = useState<SelectOptionProps[]>([]);
  const [selectedProductGroup, setSelectedProductGroup] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [polishId, setPolishId] = useState<number | null>(null)
  const [colorList, setColorList] = useState<ColorDetails[]>([]);
  const [polishingTypeList, setPolishingTypeList] = useState<SelectOptionProps[]>([]);
  const [quantities, setQuantities] = useState<{ [colorId: number]: number }>({});
  const [otherCharges, setOtherCharges] = useImmer<OtherCharges[]>(initialOtherCharges)
  const [saleOrderItems, updateSaleOrderItems] = useImmer<invoiceItemList[]>(initialSaleOrderItems);
  const [customerData, setCustomerData] = useState<Customer>()
  const [errorMessage, updateErrorMessage] = useState<string>('')
  const [isFormVisible, setFormVisibility] = useState(false);
  const [placeOfSupply, setPlaceOfSupply] = useState<SelectOptionProps[]>([]);
  const [saleOrderList, setSaleOrderList] = useState<SelectOptionProps[]>([]);
  const [isBillingFormVisible, setBillingFormVisibility] = useState(false);
  const [error, setError] = useState<string>('')
  const [errorMessages, setErrorMessages] = useState<{ [index: number]: string }>({});
  const isValidNumberString = (str) => /^-?\d+(\.\d+)?$/.test(str);

  const handleOtherChargeValueChange = (index: number, value: number) => {
    setOtherCharges(draft => {
      draft[index].value = value;
    });
    if (!isValidNumberString(value)) {
      setErrorMessages(prev => ({ ...prev, [index]: 'Invalid input: Not a valid number' }));
      return;
    }
    if (value < 0) {
      setErrorMessages(prev => ({ ...prev, [index]: 'Value cannot be negative' }));

    } else {
      setOtherCharges(draft => {
        draft[index].value = value;
      });
      setErrorMessages(prev => ({ ...prev, [index]: '' }));
    }
  };
  const toggleBillingFormVisibility = () => {
    setBillingFormVisibility(!isBillingFormVisible);
    setError('')
  };
  const toggleFormVisibility = () => {
    setFormVisibility(!isFormVisible);
  };

  const [dateState, setDateState] = useState<any>({
    date1: new Date(),
    date2: new Date()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: invoiceSchema,
    onSubmit: async (formValues, { setFieldError, setSubmitting }) => {
      updateErrorMessage('')
      if (saleOrderItems.filter((item) => !item.isDeleted).length == 0) {
        updateErrorMessage('Alteast one product is required')
        window.scrollTo(0, 0);

        return;
      }
      if (saleOrderItems.filter((item) => !item.isDeleted && item.productId == 0).length > 0) {
        updateErrorMessage('Select Valid Product')
        window.scrollTo(0, 0);

        return;
      }
      if (saleOrderItems.filter((item) => !item.isDeleted && item.quantity == 0).length > 0) {
        updateErrorMessage('Please ensure that the quantity cannot be zero')
        window.scrollTo(0, 0);

        return;
      }
      if (formValues?.orderTotal as number <= 0) {
        updateErrorMessage('Please ensure that the total amount is greater than or equal to zero.')
        window.scrollTo(0, 0);

        return;
      }
      setSubmitting(true)
      try {
        const invDate = toZonedTime(formValues.invoiceDate as Date, 'Asia/Kolkata');
       

        const DueDate = toZonedTime(formValues.dueDate as Date, 'Asia/Kolkata');
        let result: Result
        let invoice: Invoice = {
          id: formValues.id,
          customerId: formValues.customerId as number,
          saleOrderId: formValues.saleOrderId as unknown as number,
          invoiceDate: format(invDate , 'yyyy-MM-dd'),
          dueDate: format(DueDate , 'yyyy-MM-dd'),
          orderTotal: formValues.orderTotal,
          orderSubTotal: formValues.orderSubTotal,
          totalDiscountedPrice: formValues.totalDiscountedPrice,
          totalTaxAmount: formValues.totalTaxAmount,
          totalOtherCharges: formValues.totalOtherCharges,
          roundOff: formValues.roundOff,
          otherCharges: otherCharges.filter((item) => !item.isDeleted),
          itemList: saleOrderItems.filter((item) => !item.isDeleted),
          billAddressLine1: formValues.billAddressLine1,
          billAddressLine2: formValues.billAddressLine2,
          billCityName: formValues.billCityName,
          billStateId: formValues.billStateId,
          billStateName: formValues.billStateName,
          billCountryId: formValues.billCountryId,
          billCountryName: formValues.billCountryName,
          shipAddressLine1: formValues.shipAddressLine1,
          shipAddressLine2: formValues.shipAddressLine2,
          shipCityName: formValues.shipCityName,
          shipStateId: formValues.shipStateId,
          shipStateName: formValues.shipStateName,
          shipCountryId: formValues.shipCountryId,
          shipCountryName: formValues.shipCountryName,
          placeOfSupplyId: formValues.placeOfSupplyId
        }

        result = await updateInvoice(invoice)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('Invoice updated successfully!');
          navigate('/sales/invoice/list')
        }
        else {
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage)
            )
          }
          toast.error('An error occurred while saving the invoice.');
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  const [deliverDate, setDeliveryDate] = useState<SalesOrderSetting>()
  useEffect(() => {
    getSalesOrderSetting().then((v) => {
      setDeliveryDate(v as SalesOrderSetting);
    });
  }, []);


  useEffect(() => {

    calculateTotal()
    const invoiceDate = formik.values.invoiceDate;
    const dueDate = new Date(invoiceDate);
    // if (deliverDate !== undefined && deliverDate.defaultDeliveryTime !== undefined) {
    //   dueDate.setDate(invoiceDate.getDate() + deliverDate?.defaultDeliveryTime);
    //   formik.setFieldValue('dueDate', dueDate);
    // }
  }, [saleOrderItems,  otherCharges,]);

  const calculateTotal = () => {

    let tempTotal: number = 0;
    let total: number = 0;
    let subTotal: number = 0;
    let otherChargesTotal: number = 0;
    let discount: number = 0
    let taxAmount: number = 0

    saleOrderItems.filter(t => !t.isDeleted).map((item) => {
      subTotal = subTotal + (item.subTotal as number);
      discount = discount + (item.discountAmount as number)
      taxAmount = taxAmount + (item.taxAmount as number)

    })

    otherCharges.filter(t => !t.isDeleted).map((item) => {

      if (item.sign === 1) {
        otherChargesTotal = otherChargesTotal + (item?.value as number);
      } else if (item.sign === 2) {
        otherChargesTotal = otherChargesTotal - (item?.value as number);
      }
    })

    tempTotal = subTotal + otherChargesTotal - discount + taxAmount;
    total = Math.round(tempTotal)
    formik.setFieldValue('orderSubTotal', subTotal);
    formik.setFieldValue('totalDiscountedPrice', discount);
    formik.setFieldValue('roundOff', total - tempTotal);
    formik.setFieldValue('orderTotal', total);
    formik.setFieldValue('totalTaxAmount', taxAmount)
  };


  function useFetchAndMapData(getDataFunction: any, mapFunction: any) {
    const [dataList, setDataList] = useState([]);
    useEffect(() => {

      const updatedPaginationFilter: PaginationFilter = {
        ...paginationFilter,
        advancedFilter: {
          field: 'isActive',
          operator: 'eq',
          value: true,
        },
      }
      getDataFunction(updatedPaginationFilter).then((response: { data: any; }) => {
        const result = response.data;
        const mappedData = result.map((item: any) => mapFunction(item));
        setDataList(mappedData);
      });
    }, []);

    return dataList;
  }



  const stateList = useFetchAndMapData(getStateList, (item: any) => ({
    value: item.id,
    label: item.name,
  }));

  const chargesList = useFetchAndMapData(getOtherChargeList, (item: any) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    let result;
    getPlaceOfSupplyList(paginationFilter).then((v) => {
      result = v.data as PlaceOfSupply[];
      let placeOfSupplyArray: any[] = [];
      result.map((item) => {
        placeOfSupplyArray.push({ value: item.id, label: item.alias });
      });
      setPlaceOfSupply(placeOfSupplyArray);
    });

    getSalesOrderList(paginationFilter).then((v) => {
      result = v.data as SaleOrderDto[];
      let saleOrderArray: any[] = [];
      result.map((item) => {
        saleOrderArray.push({ value: item.id, label: item.orderNumber });
      });
      setSaleOrderList(saleOrderArray);
    })
  }, [])
  const navigate = useNavigate();
  // const bankAccount = useFetchAndMapData(getBankAccountList, (item: any) => ({
  //   value: item.id,
  //   label: item.accountName,
  // }))
  const sign = [
    { value: 1, label: 'add' },
    { value: 2, label: 'less' },

  ]

  const onProductsChange = (e: Product, quantity: number = 1) => {
    updateSaleOrderItems((soitems) => {
      var soItem = soitems.find(p => p.productId == e.id);


      if (soItem !== undefined) {
        soItem.quantity = (soItem.quantity as number) + quantity;

      }
      else {
        let perProductPrice: number = 0, discountAmount: number = 0;
        let total: number = 0, subTotal = 0;
        let taxAmount: number = 0;
        let temptotal: number = 0;
        if (customerData?.rateId === 1) {
          perProductPrice = e.spWholeSeller as number;
        }
        else if (customerData?.rateId === 2) {
          perProductPrice = e.spSemiWholeSeller as number;
        }
        else {
          perProductPrice = e.spRetail as number;
        }

        subTotal = perProductPrice * parseInt(quantity.toString(), 10)

        if (customerData === undefined) {
          discountAmount = 0;

        } else {
          discountAmount =
            (subTotal * (customerData?.discountPercent as number)) / 100;
        }
        temptotal = subTotal - discountAmount
        if (e.taxRateValue === undefined) {
          taxAmount = 0
        }
        else {
          taxAmount = (temptotal * (e.taxRateValue as number)) / 100;
        }
        total = temptotal + taxAmount


        soitems.push({
          rowNumber: soitems.length,
          polishingTypeName: e.polishingTypeName,
          polishingTypeId: e.polishingTypeId,
          colorId: e.colorId,
          colorName: e.colorName,
          productId: e.id as number,
          productName: e.name,
          quantity: quantity,
          productPrice: perProductPrice,
          subTotal: (perProductPrice * quantity),
          discountPercent: customerData?.discountPercent,
          discountAmount: discountAmount,
          total: total,
          isDeleted: false,
          taxPercent: e.taxRateValue || 0,
          taxAmount: taxAmount || 0,
          totalInclusiveTax: total
        });
      }
    })
  };

  const updateItemTotal = (rowNumber: number) => {
    updateSaleOrderItems((soitems) => {


      const soItem = soitems.find(t => t.rowNumber === rowNumber);
      let unitPrice: number
      let qty: number
      let discPercent: number
      let total: number = 0, discAmt: number = 0;
      let taxValue: number = 0, taxAmt: number = 0;
      let tempTotal: number = 0;
      let totalTaxAmt: number = 0;
      if (soItem != null) {
        unitPrice = soItem.productPrice as number;
        qty = soItem.quantity as number;
        discPercent = soItem.discountPercent as number;
        taxValue = soItem.taxPercent as number
        if (discPercent > 0) {
          discAmt = (unitPrice * qty) * discPercent / 100
        }
        tempTotal = (unitPrice * qty) - discAmt
        if (taxValue > 0) {
          taxAmt = (tempTotal * taxValue) / 100
        }

        total = (unitPrice * qty);
        totalTaxAmt = tempTotal + taxAmt
        soItem.subTotal = total
        soItem.discountAmount = discAmt;
        soItem.total = tempTotal;
        soItem.totalInclusiveTax = totalTaxAmt;
        soItem.taxAmount = taxAmt

      }


    });
  }

  const handleAddRow = () => {
    setOtherCharges((ocitems) => {
      ocitems.push({
        rowNumber: ocitems.length,
        value: 0,
        isDeleted: false
      })
    })

  };

  const totalDiscountAmount = () => {
    let totalDiscount: number = 0;
    saleOrderItems.filter(t => !t.isDeleted).map((soitem) => {
      totalDiscount += (soitem.discountAmount || 0)
    })
    return totalDiscount.toFixed(2);
  }
  const totalTaxAmount = () => {
    let totalTaxAmt: number = 0;
    saleOrderItems.filter(t => !t.isDeleted).map((soitem) => {
      totalTaxAmt += (soitem.taxAmount || 0)
    })
    return totalTaxAmt.toFixed(2);
  }
  const dateChange = (date: Date) => {
 
    const dueDate = new Date(date);
    if (deliverDate !== undefined && deliverDate.defaultDeliveryTime !== undefined) {
      dueDate.setDate(dueDate.getDate() + deliverDate.defaultDeliveryTime);
      formik.setFieldValue('dueDate', dueDate);
    }
  }
  useEffect(() => {

      formik.setFieldValue('shipAddressLine1', invoiceData.shipAddressLine1)
      formik.setFieldValue('shipAddressLine2', invoiceData.shipAddressLine2)
      formik.setFieldValue('shipStateId', invoiceData.shipStateId)
      formik.setFieldValue('shipStateName', invoiceData.shipStateName)

      formik.setFieldValue('shipCityName', invoiceData.shipCityName)
      formik.setFieldValue('billAddressLine1', invoiceData.billAddressLine1)
      formik.setFieldValue('billAddressLine2', invoiceData.billAddressLine2)
      formik.setFieldValue('billStateId', invoiceData.billStateId)
      formik.setFieldValue('billStateName', invoiceData.billStateName)

      formik.setFieldValue('billCityName', invoiceData.billCityName)

 

  }, [invoiceData])
  useEffect(() => {
    let result: any
    const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: {
        field: 'isActive',
        operator: 'eq',
        value: true,
      },

    };
    getCategoryList(updatedPaginationFilter).then((v) => {
      result = v.data as Category[];
      let categoryArray: any[] = [];
      result.map((item) => {
        categoryArray.push({ value: item.id, label: item.parentChildCategoryName });
      });
      setCategoryList(categoryArray);
    });


    getPolishingTypeList(updatedPaginationFilter).then((v) => {
      result = v.data as PolishingType[]
      let polishingTypeArray: any[] = []
      result.map((item: any) => {
        return polishingTypeArray.push({ value: item.id, label: item.name })
      })
      setPolishingTypeList(polishingTypeArray)
    })


  }, [selectedCategory]);

  const onCategoryChange = (e) => {

    const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: {
        filters: [
          { field: 'isActive', operator: 'eq', value: true },

          {
            field: "categoryId",
            operator: "eq",
            value: e.value,
          }
        ],
        logic: "and"
      }
    };

    getProductGroupList(updatedPaginationFilter)
      .then((response) => {
        const result = response.data as ProductGroup[];
        let pgArray: any[] = []
        result.map((item: any) => {
          return pgArray.push({ value: item.id, label: item.name })
        })
        setProductGroupList(pgArray);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  const handleSearch = () => {
    getProductColor(selectedProductGroup as number, polishId as number)
      .then((response) => {
        const result = response as ColorDetails[]
        setColorList(result)
      })

  };

  const handlePolishingChange = (e) => {
    setPolishId(e.value)
  }


  const handleQuantityChange = (colorId: number, quantity: number) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [colorId]: quantity
    }));
  };

  const handleAddClick = () => {
    colorList.forEach(async color => {
      const quantity = quantities[color.colorId] || 1;
      try {
        const product = await getProductById(color.productId);
        onProductsChange(product as Product, quantity);
      } catch (err) {
        console.error(`Error fetching product by ID ${color.productId}:`, err);
      }
    });
    setSelectedProductGroup(null);
    setSelectedCategory(null);
    setPolishId(null)
    setPolishingTypeList([])
    setColorList([])
    setQuantities({})
  };


  const clearFilters = () => {

    setSelectedProductGroup(null);
    setSelectedCategory(null);
    setPolishId(null)
    setPolishingTypeList([])
    setColorList([])
    setQuantities({})

  }

  const handleDelete = (e: React.ChangeEvent<HTMLInputElement>, rowNumber: number) => {
    updateSaleOrderItems(draft => {
      return draft.filter(item => item.rowNumber !== rowNumber);
    });
  };
  return (
    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_invoice_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column flex-row-fluid"'
          id='kt_modal_add_invoice_scroll'
        >
          <div className="d-flex flex-column flex-row-fluid gap-4 gap-lg-10 mb-7">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="kt_ecommerce_add_invoice_general" role="tab-panel">
              {error !== '' && (
                  <div className='alert alert-danger'>
                    {error}
                  </div>
                )}
                {errorMessage != '' && (
                  <div className='alert alert-danger'>
                    {errorMessage}
                  </div>
                )}
                <div className="d-flex flex-column gap-7 gap-lg-10">
                  <div className="card card-flush">
                    <div className="card-header">
                      <div className="card-title">
                        <h2>Order Details</h2>
                      </div>

                    </div>
                    <div className="card-body pt-0">
                      <div className='row mb-0'>
                        <div className=" col-3 col-md-3">
                          <label className="form-label required">Customer</label>

                          <Field
                            className='form-control'
                            disabled={true}
                            value={initialValues?.customerName}
                          ></Field>
                        </div>
                        <div className="col-3 col-md-3 ">
                          <label className="form-label">Sale Order Number</label>

                          <Field
                            disabled
                            className='form-select-solid'
                            options={saleOrderList}
                            component={CustomSelect}
                            {...formik.getFieldProps('saleOrderId')}
                            name={'saleOrderId'}
                          >
                          </Field>
                          {formik.touched.saleOrderId && formik.errors.saleOrderId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.saleOrderId}</span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-3 col-md-3 ">
                          <label className="form-label required">Invoice Date</label>
                          <div>
                            <Flatpickr
                              className='form-control'
                              placeholder='Pick date'
                              {...formik.getFieldProps('invoiceDate')}
                              name={'invoiceDate'}
                              value={formik.values.invoiceDate}
                              onChange={([date1]) => {
                                dateChange(date1)
                                 //setDateState({ date1 });
                                 formik.setFieldValue('invoiceDate', date1)
  
                               }}
                            />
                          </div>

                          {formik.touched.invoiceDate && formik.errors.invoiceDate && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {/* <span role='alert'>{formik.errors.orderDate}</span> */}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-3 col-md-3">
                          <label className="form-label">Due Date</label>
                          <div>
                            <Flatpickr
                              className='form-control'
                              placeholder='Pick date'
                              {...formik.getFieldProps('dueDate')}
                              name={'dueDate'}
                              value={formik.values.dueDate}
                              onChange={([date1]) => {
                                formik.setFieldValue('dueDate', date1)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {invoiceData ? <div className='row'>
                      <h4 className=' mt-6 mb-3'>Customer Details:</h4>
                      <h5>{` Name: ${invoiceData.customerName}`}</h5>
                      <div className='col-6'>
                          <span onClick={toggleBillingFormVisibility}>
                            <h6>
                              Billing Address{' '}
                              <a className='btn btn-light btn-active-light-primary btn-sm'>
                                <span className="ki-duotone ki-pencil">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </span>
                              </a>
                            </h6>
                          </span>
                          {isBillingFormVisible ? (
                            <><div className='row mb-4'>
                              <div className='col-6 col-md-6'>
                                <label className='mb-2'> AddressLine1</label>
                                <Field
                                  className='form-control'
                                  type='text'
                                  name={'billAddressLine1'}

                                >

                                </Field>
                              </div>
                              <div className='col-6 col-md-6'>
                                <label className='mb-2'> AddressLine2</label>
                                <Field
                                  className='form-control'
                                  type='text'
                                  name={'billAddressLine2'}

                                >
                                </Field>
                              </div>
                            </div><div className='row mb-7'>
                                <div className='col-6 col-md-6'>
                                  <label className='mb-2'> State</label>
                                  <Field
                                    className='form-select-solid'
                                    options={stateList}
                                    component={CustomSelect}
                                    name={'billStateId'}
                                  >
                                  </Field>
                                </div>
                                <div className='col-6 col-md-6'>
                                  <label className='mb-2'> City</label>
                                  <Field
                                    className='form-control'
                                    type='text'
                                    name={'billCityName'}
                                  >
                                  </Field>
                                </div>
                              </div></>) : <>
                              {(invoiceData.shipAddressLine1 !== '' && invoiceData.shipAddressLine2 !== '' || invoiceData.shipCityName !== '' || invoiceData.shipStateName !== '') ?
                           <p>                         
                            {invoiceData.shipAddressLine1}<br />
                            {invoiceData.shipAddressLine2}<br />
                            {invoiceData.shipStateName}<br />
                            {invoiceData.shipCityName}<br />
                            </p>: <p>No Billing details are available</p>}
                          </>

                          }
                        </div>
                      <div className='col-6'>
                        <span onClick={toggleFormVisibility}>
                          <h6>
                            Shipping Address{' '}
                            <a className='btn btn-light btn-active-light-primary btn-sm'>
                              <span className="ki-duotone ki-pencil">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </span>
                            </a>
                          </h6>
                        </span>
                        {isFormVisible ? (
                          <><div className='row mb-4'>
                            <div className='col-6 col-md-6'>
                              <label className='mb-2'> AddressLine1</label>
                              <Field
                                className='form-control'
                                type='text'
                                name={'shipAddressLine1'}

                              >

                              </Field>
                            </div>
                            <div className='col-6 col-md-6'>
                              <label className='mb-2'> AddressLine2</label>
                              <Field
                                className='form-control'
                                type='text'
                                name={'shipAddressLine2'}

                              >
                              </Field>
                            </div>
                          </div><div className='row mb-7'>
                              <div className='col-6 col-md-6'>Edit Sales Order

                                <label className='mb-2'> State</label>
                                <Field
                                  className='form-select-solid'
                                  options={stateList}
                                  component={CustomSelect}
                                  name={'shipStateId'}
                                >
                                </Field>
                              </div>
                              <div className='col-6 col-md-6'>
                                <label className='mb-2'> City</label>
                                <Field
                                  className='form-control'
                                  type='text'
                                  name={'shipCityName'}
                                >
                                </Field>
                              </div>
                            </div></>) : <>
                          {(invoiceData.shipAddressLine1 !== '' && invoiceData.shipAddressLine2 !== '' || invoiceData.shipCityName !== '' || invoiceData.shipStateName !== '') ?
                           <p>                         
                            {invoiceData.shipAddressLine1}<br />
                            {invoiceData.shipAddressLine2}<br />
                            {invoiceData.shipStateName}<br />
                            {invoiceData.shipCityName}<br />
                            </p>
                            
                            
                            
                            : <p>No shipping Address available</p>}

                        </>
                        }
                      </div>
                    </div> : ''}


                      <div className='row mb-4 mt-5'>
                        <div className='col-4 col-md-4'>
                          <label>Place Of Supply</label>
                          <Field
                            className='form-select-solid'
                            options={placeOfSupply}
                            component={CustomSelect}
                            {...formik.getFieldProps('placeOfSupplyId')}
                            name={'placeOfSupplyId'}
                            onDropDownChange={(e) => {

                              formik.setFieldValue('placeOfSupplyId', e?.value)
                            }}
                          >
                          </Field>

                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10 ">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="kt_ecommerce_add_invoice_general" role="tab-panel">
                <div className="d-flex flex-column gap-7 gap-lg-10">
                  <div className="card card-flush py-4">
                    <div className="card-header">
                      <div className="card-title">
                        <h2>Item Details</h2>

                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <ProductSearchbar
                        onSelect={onProductsChange}
                      />
                    </div>
                    <div className='separator mb-8'></div>
                    <div className="card-body pt-0">
                      <div className="row mb-4 align-items-end">
                        <div className="col-lg-3 fv-row fv-plugins-icon-container">
                          <label className="form-label">Search Category</label>
                          <Select
                            name="categoryList"
                            className="react-select-container ms-1"
                            options={categoryList}
                            placeholder="Select a Category"
                            isMulti={false}
                            onChange={(selectedOption) => {
                              onCategoryChange(selectedOption)
                              setSelectedCategory(selectedOption?.value as number);
                            }}
                            value={categoryList.find(option => option.value === selectedCategory) || null}
                          />
                        </div>
                        <div className="col-lg-3 fv-row fv-plugins-icon-container">
                          <label className="form-label">Search product group</label>
                          <Select
                            name="categoryList"
                            className="react-select-container ms-1"
                            options={productGroupList}
                            placeholder="Select a ProductGroup"
                            isMulti={false}
                            onChange={(selectedOption) => {
                              setSelectedProductGroup(selectedOption?.value as number);
                            }}
                            value={productGroupList.find(option => option.value === selectedProductGroup) || null}

                          />
                        </div>


                        <div className="col-lg-3 fv-row fv-plugins-icon-container">
                          <label className="form-label">Search PolishingType</label>
                          <Select
                            className=
                            'form-select-solid ms-1'
                            value={polishingTypeList.find(option => option.value === polishId) || null}
                            name="polishIds"
                            options={polishingTypeList}
                            onChange={(selectedPT) => handlePolishingChange(selectedPT)}
                            placeholder="Select PolishingType"
                          ></Select>
                        </div>
                        <div className="col-lg-1 fv-row fv-plugins-icon-container">
                          <button type='button' className="btn btn-primary mt-6" onClick={handleSearch}>
                            Search
                          </button>
                        </div>
                      </div>

                    </div>

                    <div className="card-body pt-0">

                      {colorList.length > 0 ?
                        <><div className='opt1 relative-options show-opt'>
                          <table className="color-tbl">
                            <tbody>
                              <tr>
                                {colorList.map((color, index) => (
                                  <td key={index}>
                                    <div className="form-group">
                                      <label className="clr-label">{color.colorName}
                                        <input type="number" className="color-opt form-color" value={quantities[color.colorId] || ""}
                                          onChange={(e) => handleQuantityChange(color.colorId, parseInt(e.target.value))} /> </label>
                                    </div>
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                          <div className='text-right'>

                            <button type='button' className="btn btn-primary me-5" onClick={
                              handleAddClick
                            }
                            >
                              Add
                            </button>
                            <button type='button' className="btn btn-primary" onClick={clearFilters}>
                              Clear
                            </button>
                          </div>

                        </>
                        : null}
                    </div>
                    <div className="card-body pt-0">
                      <div className='row pb-5' >
                        <div className="col-2 col-md-2 mb-3"> <label htmlFor='kt_create_products_form_products_productname' className='text-gray-400 fs-5 fw-semibold mb-3'>Product  </label></div>
                        <div className="col-2 col-md-2 mb-3"> <label htmlFor='kt_create_products_form_products_quantity' className='text-gray-400 fs-5 fw-semibold mb-3'>Quantity  </label></div>
                        <div className="col-2 col-md-2 mb-3"> <label htmlFor='kt_create_products_form_products_unitprice' className='text-gray-400 fs-5 fw-semibold mb-3'>Unit Price  </label></div>
                        <div className="col-2 col-md-2 mb-3"> <label htmlFor='kt_create_products_form_products_discountpercent' className='text-gray-400 fs-5 fw-semibold mb-3'>Discount Percent ( % ) </label></div>
                        <div className="col-2 col-md-2 mb-3"> <label htmlFor='kt_create_products_form_products_taxpercent' className='text-gray-400 fs-5 fw-semibold mb-3'>Tax Percent ( % ) </label></div>
                        <div className="col-1 col-md-1 mb-3"> <label htmlFor='kt_create_products_form_products_total' className='text-gray-400 fs-5 fw-semibold mb-3'> Total ( ₹ ) </label></div>
                        <div className="col-1 col-md-1 mb-3 text-center"> <label htmlFor='kt_create_products_form_products_actions' className='text-gray-400 fs-5 fw-semibold mb-3'>Actions </label></div>
                      </div>

                      <div>
                        {saleOrderItems.filter(t => !t.isDeleted).map((soitem, index) => (
                          <div className='row pb-4' key={soitem.rowNumber}>
                            <div className="col-2 col-md-2">
                              <p>{soitem.productName}</p>

                            </div>


                            <div className="col-2 col-md-2">
                              <Field
                                type='text'
                                className={clsx('form-control')}
                                onChange={(e: any) => {
                                  updateSaleOrderItems(soitems => {
                                    const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                    if (saleOrderItem != undefined) {
                                      saleOrderItem.quantity = e.target.value;
                                      updateItemTotal(soitem.rowNumber as number);


                                    }
                                  });
                                }}
                                name={`saleOrderItemList[${soitem.rowNumber}].quantity`}
                                value={saleOrderItems[soitem.rowNumber as number].quantity}
                              />
                            </div>
                            <div className="col-2 col-md-2">
                              <Field
                                type='text'
                                className={clsx('form-control')}
                                onChange={(e: any) => {
                                  updateSaleOrderItems(soitems => {
                                    const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                    if (saleOrderItem != undefined) {
                                      saleOrderItem.productPrice = e.target.value;
                                      updateItemTotal(soitem.rowNumber);
                                    }
                                  });
                                }}
                                name={`saleOrderItemList[${soitem.rowNumber}].productPrice`}
                                value={saleOrderItems[soitem.rowNumber].productPrice}
                              />
                            </div>
                            <div className="col-2 col-md-2 mb-3">
                              <Field


                                type='number'
                                className={clsx('form-control')}
                                name={`saleOrderItemList[${soitem.rowNumber}].discountPercent`}
                                value={saleOrderItems[soitem.rowNumber].discountPercent}
                                onChange={(e: any) => {
                                  updateSaleOrderItems(soitems => {
                                    const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                    if (saleOrderItem != undefined) {
                                      if (e.target.value >= 0 && e.target.value <= 100) {
                                        saleOrderItem.discountPercent = e.target.value;
                                        updateItemTotal(soitem.rowNumber);
                                      }

                                    }
                                  });
                                }}
                              />
                              <span className='mt-3'> Disc.amt: ( ₹ ){saleOrderItems[soitem.rowNumber].discountAmount?.toFixed(2)}</span>
                            </div>
                            <div className="col-2 col-md-2 mb-3">

                              {/* <label className='form-control'>{saleOrderItems[soitem.rowNumber].taxPercent?.toFixed(2)}</label> */}
                              <Field


                                type='number'
                                className={clsx('form-control')}
                                name={`saleOrderItemList[${soitem.rowNumber}].taxPercent`}
                                value={saleOrderItems[soitem.rowNumber].taxPercent}
                                onChange={(e: any) => {
                                  updateSaleOrderItems(soitems => {
                                    const saleOrderItem = soitems.find(t => t.rowNumber == soitem.rowNumber)
                                    if (saleOrderItem != undefined) {
                                      if (e.target.value >= 0 && e.target.value <= 100) {
                                        saleOrderItem.taxPercent = e.target.value;
                                        updateItemTotal(soitem.rowNumber);
                                      }

                                    }
                                  });
                                }}
                              />
                              <span className='mt-3'> Tax.amt: ( ₹ ){saleOrderItems[soitem.rowNumber].taxAmount?.toFixed(2)}</span>
                            </div>


                            <div className="col-1 col-md-1 mb-3">
                              <Field
                                type='number'
                                className={clsx('form-control')}
                                name={`saleOrderItemList[${soitem.rowNumber}].totalInclusiveTax`}
                                value={saleOrderItems[soitem.rowNumber].totalInclusiveTax}
                                disabled={true}
                              />
                            </div>
                            <div className="col-1 col-md-1 mb-3 text-center">
                              <button
                                type='button'
                                data-repeater-delete=''
                                className='btn btn-sm btn-icon btn-light-danger mt-2'
                                onClick={(e: any) => {
                                  handleDelete(e, soitem.rowNumber)
                                }}
                              >
                                <i className='ki-duotone ki-cross fs-1'>
                                  <span className='path1' />
                                  <span className='path2' />
                                </i>
                              </button>
                            </div>
                          </div>
                        ))}


                      </div>
                      <div className='separator mb-7'></div>
                      <div className='row mt-4'>
                        <div className='col-6 col-md-6 mb-3'>

                        </div>
                        <div className='col-6 col-md-6 mb-3 bg-light text-dark'>
                          <div className='d-flex justify-content-between mb-7'>
                            <label className='fs-4 mt-3'>Sub Total</label>
                            <span className='fs-4 mt-3 '>
                              ₹ {formik.values.orderSubTotal?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </span>

                          </div>
                          <div className='separator mb-7'></div>

                          {otherCharges
                            .filter(t => !t.isDeleted)
                            .map((ocitem, index) => (
                              <><div className='row mb-3'
                                key={ocitem.rowNumber}
                              >
                                <div className='col-5'>
                                  <Select
                                    options={chargesList}
                                    name={`otherCharges[${ocitem.rowNumber}].label`}
                                    value={otherCharges.find(x => x.label === otherCharges[ocitem.rowNumber].label)}
                                    onChange={(newValue: any) => {
                                      if (newValue === null) {
                                        setOtherCharges((oc) => {
                                          oc[ocitem.rowNumber].label = '';
                                        })
                                      }
                                      else {
                                        setOtherCharges((oc) => {
                                          oc[ocitem.rowNumber].label = newValue.label;
                                        })
                                      }
                                    }}

                                  />
                                </div>
                                <div className='col-6'>
                                  <div className="input-group mb-3">
                                    <Field className=''
                                      options={sign}
                                      component={CustomSelect}
                                      selectedValue={otherCharges[ocitem.rowNumber].sign}
                                      {...formik.getFieldProps(`otherCharges[${ocitem.rowNumber}].sign`)}
                                      name={`otherCharges[${ocitem.rowNumber}].sign`}
                                      onDropDownChange={(e: any) => {
                                        setOtherCharges((oc) => {
                                          oc[ocitem.rowNumber].sign = e.value;
                                        })
                                      }}
                                    />


                                    <Field
                                      type='number'
                                      {...formik.getFieldProps(`otherCharges[${ocitem.rowNumber}].value`)}
                                      name={`otherCharges[${ocitem.rowNumber}].value`}
                                      className={clsx('form-control me-10')}
                                      onChange={(e: any) => {
                                        const newvalue = parseFloat(e.target.value)
                                        handleOtherChargeValueChange(index, isNaN(newvalue) ? 0 : newvalue);
                                      }}
                                      value={otherCharges[ocitem.rowNumber].value || ''}
                                    />
                                  </div>
                                  <div>
                                    <span className='alert-danger'>{errorMessages[index]}</span>
                                  </div>
                                </div>

                                <div className='col-1'>
                                  <button
                                    type='button'
                                    data-repeater-delete=''
                                    className='btn btn-sm btn-icon btn-light-danger mt-2'
                                    onClick={(e: any) => {
                                      setOtherCharges((oc) => {
                                        oc[ocitem.rowNumber].isDeleted = true;
                                      })
                                    }}

                                  >
                                    <i className='ki-duotone ki-cross fs-1'>
                                      <span className='path1' />
                                      <span className='path2' />
                                    </i>
                                  </button>
                                </div>

                              </div></>
                            ))}
                          <div className='form-group mt-5 mb-5'>
                            <button type='button'

                              className='btn btn-sm btn-light-primary'
                              onClick={handleAddRow}>
                              + Add another Charges
                            </button>
                          </div>
                          <div className='d-flex justify-content-between mb-7'>

                            <label className='fs-4 mt-3'>Discount</label>
                            <span className='fs-4 mt-3 '>₹ {totalDiscountAmount()}</span>

                            {/* <span className='fs-4 mt-3 '>₹ {formik.values.totalDiscountedPrice.toFixed(2)}</span> */}
                          </div>

                          <div className='d-flex justify-content-between mb-7'>

                            <label className='fs-4 mt-3'>Tax Amount</label>
                            <span className='fs-4 mt-3 '>₹ {totalTaxAmount()}</span>
                          </div>

                          <div className='d-flex justify-content-between mb-7'>
                            <label className='fs-4 mt-3'>Round Off</label>

                            <span className='fs-4 mt-3 '>
                              {((formik.values.roundOff !== undefined && !Number.isNaN(parseInt(formik.values.roundOff.toString()))) ? formik.values.roundOff?.toFixed(2) : '0.0')}
                              {/* {formik.values.roundOff?.toFixed(2)} */}
                            </span>
                          </div>

                          <div className='separator mb-7'></div>
                          <div className='d-flex justify-content-between mb-7'>
                            <label className='fs-4 mt-3'>Total</label>
                            <span className='fs-4 mt-3 '>₹
                              {/* {formik.values.orderTotal?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                              {((formik.values.orderTotal !== undefined && !Number.isNaN(parseInt(formik.values.orderTotal.toString()))) ? formik.values.orderTotal?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}



                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          <div className='card'>
            <div className='card-body'>
            <div className='d-flex justify-content-end'>
              <button
                type='reset'
                className='btn btn-light me-3 border'
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_1"
                disabled={formik.isSubmitting}
              >
                Discard
              </button>
              <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2>Confirmation</h2>
                      <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr061.svg"
                          className="svg-icon svg-icon-2x"
                        />
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="w-100">

                        {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
												<i className="las la-exclamation-circle"></i>
											</div> */}

                        <div className="fv-row mb-10 text-center">
                          <h5>Are you sure you want to discard ?</h5>
                        </div>

                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light"
                        data-bs-dismiss="modal"
                        onClick={() => navigate('/sales/invoice/list')}
                      >
                        Yes
                      </button>
                      <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-color-modal-action='submit'
              //disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Submit</span>
                {(formik.isSubmitting) && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            </div>
          </div>

          </div>



        </div>
      </form>

      {(formik.isSubmitting) && <Loading />}
    </FormikProvider>
  )
}

export { InvoiceEditForm }
