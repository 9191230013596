import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"

import { Result } from '../../../../core/models/Result'
import { Banner, BannerQueryResponse, PageQueryResponse, SectionQueryResponse } from './_models'
import { link } from 'fs'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Banner_LIST_URL = `${API_URL}/banner/search`
const Banner_BY_ID_URL = `${API_URL}/banner`
const Banner_ADD_URL = `${API_URL}/banner`

const Page_LIST_URL = `${API_URL}/page/search`
const Section_LIST_URL = `${API_URL}/section/search`



const getBannerList = async (filter: PaginationFilter): Promise<BannerQueryResponse> => {
  return await axios
    .post(`${Banner_LIST_URL}`, filter)
    .then((d: AxiosResponse<BannerQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getBannerById = async (id: ID): Promise<Result | Banner> => {
  return axios
    .get(`${Banner_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Banner>) => response.data)
    .then((response: Banner) => response)
    .catch((err: Result) => {
      return err
    })
}
const createBanner = async (banner: Banner): Promise<Result> => {
  return await axios
    .post(Banner_ADD_URL, {
      name: banner.name?.trim(),
      uploadWebImage:banner.uploadWebImage,
      uploadMobileImage:banner.uploadMobileImage,
      mobileImagePath:banner.mobileImagePath,
      webImagePath:banner.webImagePath,
      pageId:banner.pageId,
      sectionId:banner.sectionId,
      shortDescription:banner.shortDescription,
      linkUrl:banner.linkUrl,
      title:banner.title
      
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateBanner = (banner: Banner): Promise<Result> => {
  return axios
    .put(`${Banner_BY_ID_URL}/${banner.id}`, {
      id: banner.id,
      name: banner.name?.trim(),
      uploadWebImage:banner.uploadWebImage,
      uploadMobileImage:banner.uploadMobileImage,
      mobileImagePath:banner.mobileImagePath,
      webImagePath:banner.webImagePath,
      pageId:banner.pageId,
      sectionId:banner.sectionId,
      shortDescription:banner.shortDescription,
      linkUrl:banner.linkUrl,
      title:banner.title

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteBanner = async (id: ID): Promise<Result | Banner> => {
  return axios
    .delete(`${Banner_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Banner>) => response.data)
    .then((response: Banner) => response)
    .catch((err: Result) => {
      return err
    })
}

const getPageList = async (filter: PaginationFilter): Promise<PageQueryResponse> => {
  return await axios
    .post(`${Page_LIST_URL}`, filter)
    .then((d: AxiosResponse<PageQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getSectionList = async (filter: PaginationFilter): Promise<SectionQueryResponse> => {
  return await axios
    .post(`${Section_LIST_URL}`, filter)
    .then((d: AxiosResponse<SectionQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
export {
  getBannerList,
  getBannerById,
  createBanner,
  updateBanner,
  deleteBanner,
  getPageList,
  getSectionList

}
