import axios, { AxiosResponse } from 'axios'
import { Result } from '../../../../core/models/Result'
import { ExpenseCategoryQueryResponse, ExpenseQueryResponse,  PurchaseByProductGroupStockQueryResponse, PurchaseByProductStockQueryResponse, PurchaseByVendorStockQueryResponse, SalesByAgentStockQueryResponse, SalesByCustomerStockQueryResponse, SalesByProductGroupStockQueryResponse, SalesByProductStockQueryResponse, StockFilter } from './_model'
import { PaginationFilter } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_STORE_API_URL
const SALES_BY_CUSTOMER_STOCK_LIST_URL = `${API_URL}/report/sales-by-customer`
const SALES_BY_AGENT_STOCK_LIST_URL = `${API_URL}/report/sales-by-agent`
const SALES_BY_PRODUCT_LIST_URL=`${API_URL}/report/sales-by-product`
const SALES_BY_PRODUCTGROUP_LIST_URL=`${API_URL}/report/sales-by-product-group`
const PURCHASE_BY_VENDOR_LIST_URL=`${API_URL}/report/purchases-by-vendor`
const PURCHASE_BY_PRODUCTGROUP_LIST_URL=`${API_URL}/report/purchases-by-product-group`
const PURCHASE_BY_PRODUCT_LIST_URL=`${API_URL}/report/purchases-by-product`
const EXPENSE_LIST_URL=`${API_URL}/report/expense-details`
const  EXPENSE_CATEGORY_LIST_URL=`${API_URL}/report/expense-by-category`

const getSalesByCustomerStock = (filters: StockFilter): Promise<SalesByCustomerStockQueryResponse> => {
  return axios
    .post(`${SALES_BY_CUSTOMER_STOCK_LIST_URL}`, filters)
    .then((d: AxiosResponse<SalesByCustomerStockQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getSalesByAgentStock = (filters: StockFilter): Promise<SalesByAgentStockQueryResponse> => {
  return axios
    .post(`${SALES_BY_AGENT_STOCK_LIST_URL}`, filters)
    .then((d: AxiosResponse<SalesByAgentStockQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}


const getSalesProductStock = (filters: StockFilter): Promise<SalesByProductStockQueryResponse> => {
  return axios
    .post(`${SALES_BY_PRODUCT_LIST_URL}`, filters)
    .then((d: AxiosResponse<SalesByProductStockQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getSalesProductGroupStock = (filters: StockFilter): Promise<SalesByProductGroupStockQueryResponse> => {
  return axios
    .post(`${SALES_BY_PRODUCTGROUP_LIST_URL}`, filters)
    .then((d: AxiosResponse<SalesByProductGroupStockQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getPurchaseVendorStock = (filters: StockFilter): Promise<PurchaseByVendorStockQueryResponse> => {
  return axios
    .post(`${PURCHASE_BY_VENDOR_LIST_URL}`, filters)
    .then((d: AxiosResponse<PurchaseByVendorStockQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getPurchaseProductStock = (filters: StockFilter): Promise<PurchaseByProductStockQueryResponse> => {
  return axios
    .post(`${PURCHASE_BY_PRODUCT_LIST_URL}`, filters)
    .then((d: AxiosResponse<PurchaseByProductStockQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getPurchaseProductGroupStock = (filters: StockFilter): Promise<PurchaseByProductGroupStockQueryResponse> => {
  return axios
    .post(`${PURCHASE_BY_PRODUCTGROUP_LIST_URL}`, filters)
    .then((d: AxiosResponse<PurchaseByProductGroupStockQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getExpenseReport = (filters: StockFilter): Promise<ExpenseQueryResponse> => {
  return axios
    .post(`${EXPENSE_LIST_URL}`, filters)
    .then((d: AxiosResponse<ExpenseQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getExpenseCategoryReport = (filters: StockFilter): Promise<ExpenseCategoryQueryResponse> => {
  return axios
    .post(`${EXPENSE_CATEGORY_LIST_URL}`, filters)
    .then((d: AxiosResponse<ExpenseCategoryQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
export {
  getSalesByCustomerStock,
  getSalesByAgentStock,
  getSalesProductStock,
  getSalesProductGroupStock,
  getPurchaseVendorStock,
  getPurchaseProductGroupStock,
  getPurchaseProductStock,
  getExpenseReport,
  getExpenseCategoryReport,
}
