import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { Toast } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import { Expense } from '../core/_models'
import { Result } from '../../../../core/models/Result'
import { PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers'
import { camelize } from '../../../../core/utils/StringHelpers'
import { createExpense, updateExpense, } from '../core/_requests'

import { Loading } from '../../../../core/shared/components/Loading'
import { getVendorList } from '../../vendor/vendor-list/core/_requests'
import { Vendor } from '../../vendor/vendor-list/core/_models'
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps'
import { getStaffList } from '../../../settings/staff/staff-list/core/_requests'
import { Staff } from '../../../settings/staff/staff-list/core/_models'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import { getExpenseCategoryList } from '../../expenseCategory/core/_requests'
import { ExpenseCategory } from '../../expenseCategory/core/_models'
import { getAccountList } from '../../../settings/account/core/_requests'
import { Account } from '../../../settings/account/core/_models'
import Flatpickr from 'react-flatpickr';
import { format } from 'date-fns'
import { toZonedTime } from "date-fns-tz";

type Props = {
  isExpenseLoading: boolean
  data: Expense | Result
}

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,

}

const expenseSchema = Yup.object().shape({
  // vendorId: Yup.string().required('Please enter vendor Name.'),
  expenseCategoryId: Yup.string().required('Please enter Expense Category Name.'),
  accountId: Yup.string().required('Please enter Bank Account Name.'),
  //expenseDate: Yup.date().required('Please enter Expense Date.'),
  expenseAmount: Yup.number().required('Please enter Expense Amount.').min(0, 'Expense Amount cannot be negative.'),

})

const ExpenseForm: FC<Props> = ({ data, isExpenseLoading }) => {
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  const [vendorList, setVendorList] = useState<SelectOptionProps[]>([])
  const [staffList, setStaffList] = useState<SelectOptionProps[]>([])
  const [accountList, setAccountList] = useState<SelectOptionProps[]>([])
  const [expenseList, setExpenseList] = useState<SelectOptionProps[]>([])
  const [showUser, setShowUser] = useState(true);
  const { refetch } = useQueryResponse()
  let expense = data as Expense;
  const initialValues = {
    id: expense.id,
    vendorId: expense.vendorId,
    // vendorName: '',
    expenseCategory: expense.expenseCategory,
    accountName: expense.accountName,
    staffId: expense.staffId,
    accountId: expense.accountId,
    expenseCategoryId: expense.expenseCategoryId,
    expenseAmount: expense.expenseAmount,
    notes: expense.notes,
    expenseDate: expense.expenseDate


  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: expenseSchema,

    onSubmit: async (expense, { setSubmitting, setFieldError }) => {

      setSubmitting(true)
      try {
        const exdate = toZonedTime(new Date(expense.expenseDate as Date), 'Asia/Kolkata');
        expense.expenseDate = format(exdate, 'yyyy-MM-dd')
        let result: Result
        if (isNotEmpty(expense.id)) {

          result = await updateExpense(expense)
        } else {
          result = await createExpense(expense)
        }
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          cancel(true)
          toast.success('Expense  created successfully');
        }
        else {
          toast.error('Error Occurred while updating expense .');
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage)
            )
          }
        }
      } catch (ex) {

        toast.error('Error Occurred .');
        console.error(ex)
      }
    },
  })

  useEffect(() => {

    let result: any
    let vendorArray: any[] = []

    getVendorList(paginationFilter).then((v) => {
      result = v.data as Vendor[]
      result.map((item: any) => {
        return vendorArray.push({ value: item.id, label: item.printName })
      })
      setVendorList(vendorArray)
    })
    let staffArray: any[] = []

    getStaffList(paginationFilter).then((v) => {
      result = v.data as Staff[]
      result
        .filter((item: Staff) => item.id !== initialValues.id)
        .map((item: any) => {
          return staffArray.push({ value: item.id, label: item.firstName })
        })
      setStaffList(staffArray)
    })
    let accountArray: any[] = []

    getAccountList(paginationFilter).then((v) => {
      result = v.data as Account[]
      result
        .filter((item: Account) => item.accountTypeName === 'Bank')
        .map((item: any) => {
          return accountArray.push({ value: item.id, label: item.name })
        })
      setAccountList(accountArray)
    })

    let expenseArray: any[] = []

    getExpenseCategoryList(paginationFilter).then((v) => {
      result = v.data as ExpenseCategory[]
      result

        .map((item: any) => {
          return expenseArray.push({ value: item.id, label: item.name })
        })
      setExpenseList(expenseArray)
    })
  }, []);

  return (

    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_expense_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column'
          id='kt_modal_add_expense_scroll'
          data-kt-scroll='true'
        >



          <div className='row mb-7'>
            <div className="col-6 col-md-6 mb-4">
              <label className='required fw-bold fs-6 mb-2 ms-1'>Expense Category</label>
              <Field
                className={clsx(
                  'form-select-solid ms-1',
                  { 'is-invalid': formik.touched.expenseCategoryId && formik.errors.expenseCategoryId },
                  {
                    'is-valid': formik.touched.expenseCategoryId && !formik.errors.expenseCategoryId,
                  }
                )}
                {...formik.getFieldProps('expenseCategoryId')}

                name="expenseCategoryId"
                options={expenseList}
                component={CustomSelect}
                placeholder="Select Expense Category"
                isMulti={false}
              ></Field>
              {formik.touched.expenseCategoryId && formik.errors.expenseCategoryId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.expenseCategoryId}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-6 col-md-6 mb-4">
              <label className='required fw-bold fs-6 mb-2 ms-1'>Bank Account Name</label>
              <Field
                className={clsx(
                  'form-select-solid ms-1',
                  { 'is-invalid': formik.touched.accountId && formik.errors.accountId },
                  {
                    'is-valid': formik.touched.accountId && !formik.errors.accountId,
                  }
                )}
                {...formik.getFieldProps('accountId')}

                name="accountId"
                options={accountList}
                component={CustomSelect}
                placeholder="Select Bank Account"
                isMulti={false}
              ></Field>
              {formik.touched.accountId && formik.errors.accountId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.accountId}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7'>
            <div className="col-6 col-md-6 mb-4">
              <label className='required fw-bold fs-6 mb-2 ms-1'>Expense Date</label>

              <Flatpickr
                className='form-control'
                placeholder='Pick date'
                {...formik.getFieldProps('expenseDate')}
                name={'expenseDate'}
                onChange={([date]) => {
                  formik.setFieldValue('expenseDate', date)

                }}

              />
            </div>
            <div className="col-6 col-md-6 mb-4">
              <label className='required fw-bold fs-6 mb-2 ms-1'>Expense Amount</label>
              <input
                placeholder='Expense Amount'
                {...formik.getFieldProps('expenseAmount')}
                type='number'
                name='expenseAmount'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.expenseAmount && formik.errors.expenseAmount },
                  {
                    'is-valid': formik.touched.expenseAmount && !formik.errors.expenseAmount,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isExpenseLoading}
              />
              {formik.touched.expenseAmount && formik.errors.expenseAmount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.expenseAmount}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7'>
          


          <div className="col-6 col-md-6 mb-4">
            <label className='fw-bold fs-6 mb-2 ms-1'>Vendor Name</label>
            <Field
              className={clsx(
                'form-select-solid ms-1',
                { 'is-invalid': formik.touched.vendorId && formik.errors.vendorId },
                {
                  'is-valid': formik.touched.vendorId && !formik.errors.vendorId,
                }
              )}
              {...formik.getFieldProps('vendorId')}

              name="vendorId"
              options={vendorList}
              component={CustomSelect}
              placeholder="Select Vendor"
              isMulti={false}
            ></Field>
            {formik.touched.vendorId && formik.errors.vendorId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.vendorId}</span>
                </div>
              </div>
            )}
          </div> 
          
          <div className="col-6 col-md-6 mb-4">
            <label className='fw-bold fs-6 mb-2 ms-1'>Staff Name</label>
            <Field
              className={clsx(
                'form-select-solid ms-1',
                { 'is-invalid': formik.touched.staffId && formik.errors.staffId },
                {
                  'is-valid': formik.touched.staffId && !formik.errors.staffId,
                }
              )}
              {...formik.getFieldProps('staffId')}

              name="staffId"
              options={staffList}
              component={CustomSelect}
              placeholder="Select Staff"
              isMulti={false}
            ></Field>
            {formik.touched.staffId && formik.errors.staffId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.staffId}</span>
                </div>
              </div>
            )}
          </div>
          {/* } */}


        </div>
          <div className='row mb-7'>
            <label className='fw-bold fs-6 mb-2 ms-1'>Notes</label>

            <textarea
              rows={3}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.notes && formik.errors.notes },
                {
                  'is-valid': formik.touched.notes && !formik.errors.notes,
                }
              )}
              disabled={formik.isSubmitting || isExpenseLoading}
              placeholder='notes'
              {...formik.getFieldProps('notes')}
              name='notes'
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.notes}</span>
                </div>
              </div>
            )}
          </div>






          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-regions-modal-action='cancel'
              disabled={formik.isSubmitting || isExpenseLoading}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-regions-modal-action='submit'
              disabled={isExpenseLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting || isExpenseLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      {(formik.isSubmitting || isExpenseLoading) && <Loading />}
    </FormikProvider >
  )
}

export { ExpenseForm }
