import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from "../../../../../../_metronic/helpers/crud-helper/models"
import { Manufacturer, ManufacturerQueryResponse } from './_models'
import { Result } from '../../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Manufacturer_LIST_URL = `${API_URL}/manufacturer/search`
const Manufacturer_BY_ID_URL = `${API_URL}/manufacturer`
const Manufacturer_ADD_URL = `${API_URL}/manufacturer`

const getManufacturerList = async (filter: PaginationFilter): Promise<ManufacturerQueryResponse> => {
  return await axios
    .post(`${Manufacturer_LIST_URL}`, filter)
    .then((d: AxiosResponse<ManufacturerQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getManufacturerById = async (id: ID): Promise<Result | Manufacturer> => {
  return axios
    .get(`${Manufacturer_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Manufacturer>) => response.data)
    .then((response: Manufacturer) => response)
    .catch((err: Result) => {
      return err
    })
}
const createManufacturer = async (manufacturer: Manufacturer): Promise<Result> => {
  return await axios
    .post(Manufacturer_ADD_URL, {
      name: manufacturer.name?.trim(),
      printName:manufacturer.printName?.trim()
      
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateManufacturer = (manufacturer: Manufacturer): Promise<Result> => {
  return axios
    .put(`${Manufacturer_BY_ID_URL}/${manufacturer.id}`, {
      id: manufacturer.id,
      name: manufacturer.name?.trim()  ,
      printName:manufacturer.printName?.trim(),
      isActive:manufacturer.isActive
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteManufacturer = async (id: ID): Promise<Result | Manufacturer> => {
  return axios
    .delete(`${Manufacturer_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Manufacturer>) => response.data)
    .then((response: Manufacturer) => response)
    .catch((err: Result) => {
      return err
    })
}
export {
  getManufacturerList,
  getManufacturerById,
  createManufacturer,
  updateManufacturer,
  deleteManufacturer

}
