import axios, { AxiosResponse } from 'axios'
import { Invoice, InvoiceQueryResponse, } from './_models'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"
import { FileResult, Result } from '../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const INVOICE_BYID_URL = `${API_URL}/invoices`
const INVOICE_ADD_URL = `${API_URL}/invoices`
const INVOICE_LIST_URL = `${API_URL}/invoices/search`
const GENERATE_PDF_URL = `${API_URL}/invoices/generatepdf`

const getInvoiceList = (filter: PaginationFilter): Promise<InvoiceQueryResponse> => {
  
  return axios
    .post(`${INVOICE_LIST_URL}`, filter)
    .then((d: AxiosResponse<InvoiceQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getInvoiceById = async (id: ID): Promise<Result | Invoice> => {
  return await axios
    .get(`${INVOICE_BYID_URL}/${id}`)
    .then((response: AxiosResponse<Invoice>) => response.data)
    .then((response: Invoice) => response)
    .catch((err: Result) => {
      return err
    })
}
const createInvoice = async (invoice: Invoice): Promise<Result> => {
  return await axios
    .post(INVOICE_ADD_URL, {
      saleOrderId: invoice.saleOrderId,
      customerId:invoice.customerId,
      invoiceDate: invoice.invoiceDate,
      dueDate: invoice.dueDate,
      orderTotal: invoice.orderTotal,
      orderSubTotal: invoice.orderSubTotal,
      totalDiscountedPrice: invoice.totalDiscountedPrice,
      totalTaxAmount: invoice.totalTaxAmount,
      totalOtherCharges: invoice.totalOtherCharges,
      roundOff: invoice.roundOff,
      billAddressLine1: invoice.billAddressLine1,
      billAddressLine2: invoice.billAddressLine2,
      billCityName: invoice.billCityName,
      billStateId: invoice.billStateId,
      billStateName: invoice.billStateName,
      billCountryId: invoice.billCountryId,
      billCountryName: invoice.billCountryName,
      shipAddressLine1: invoice.shipAddressLine1,
      shipAddressLine2: invoice.shipAddressLine2,
      shipCityName: invoice.shipCityName,
      shipStateId: invoice.shipStateId,
      shipStateName: invoice.shipStateName,
      shipCountryId: invoice.shipCountryId,
      shipCountryName: invoice.shipCountryName,
      termId: invoice.termId,
      accountGroupId: invoice.accountGroupId,
      placeOfSupplyId:invoice.placeOfSupplyId,
      itemList: invoice.itemList,
      otherCharges: invoice.otherCharges,
      paymentList: invoice.paymentList

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateInvoice = (invoice: Invoice): Promise<Result> => {
  return axios
    .put(`${INVOICE_BYID_URL}/${invoice.id}`, {
      id: invoice.id,
      saleOrderId: invoice.saleOrderId,
      customerId:invoice.customerId,
      invoiceDate: invoice.invoiceDate,
      dueDate: invoice.dueDate,
      orderTotal: invoice.orderTotal,
      orderSubTotal: invoice.orderSubTotal,
      totalDiscountedPrice: invoice.totalDiscountedPrice,
      totalTaxAmount: invoice.totalTaxAmount,
      totalOtherCharges: invoice.totalOtherCharges,
      roundOff: invoice.roundOff,
      billAddressLine1: invoice.billAddressLine1,
      billAddressLine2: invoice.billAddressLine2,
      billCityName: invoice.billCityName,
      billStateId: invoice.billStateId,
      billStateName: invoice.billStateName,
      billCountryId: invoice.billCountryId,
      billCountryName: invoice.billCountryName,
      shipAddressLine1: invoice.shipAddressLine1,
      shipAddressLine2: invoice.shipAddressLine2,
      shipCityName: invoice.shipCityName,
      shipStateId: invoice.shipStateId,
      shipStateName: invoice.shipStateName,
      shipCountryId: invoice.shipCountryId,
      shipCountryName: invoice.shipCountryName,
      termId: invoice.termId,
      accountGroupId: invoice.accountGroupId,
      placeOfSupplyId:invoice.placeOfSupplyId,
      itemList: invoice.itemList,
      otherCharges: invoice.otherCharges,
      paymentList: invoice.paymentList
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
const invoiceGeneratePdf = async (id: ID): Promise<Result | FileResult> => {
  return await axios
    .get(`${GENERATE_PDF_URL}/${id}`, {
      responseType: 'blob'
    })
    .then((response: AxiosResponse<any>) => {

      let filename = response.headers['content-disposition']
        .split(';')
        .find((n: any) => n.includes('filename='))
        .replace('filename=', '')
        .trim();

      var result: FileResult = {
        data: response.data,
        name: filename
      }

      return result
    })
    .catch((err: Result) => {
      return err
    })
}

export {
  getInvoiceById,
  getInvoiceList,
  createInvoice,
  updateInvoice,
  invoiceGeneratePdf
}
