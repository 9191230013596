import { useNavigate } from 'react-router-dom'
import {KTIcon} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'

const PaymentListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
     
    console.log("in modal")
    setItemIdForUpdate(null)
  }
const navigate=useNavigate()
  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3 justify-content-end'>
      <div className='m-0'>
        {/* <PaymentListFilter /> */}
      </div>
      <button type='button' className='btn btn-primary' onClick={()=>{navigate('/sales/payment/add-payment')}}>
          <KTIcon iconName='plus' className='fs-2' />
          Add
        </button>
    </div>
  )
}

export {PaymentListToolbar}
