
import { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon, PaginationFilter } from '../../../../../_metronic/helpers'
import { useListView, ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Role } from '../core/_models'
import { getRoleList } from '../core/_requests'
import { RoleModal } from './RoleModal'
import { Tag } from 'primereact/tag'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { InputText } from 'primereact/inputtext'

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}

const RoleList = () => {

  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const { itemIdForUpdate } = useListView()
  const [role, setRole] = useState<Role[]>([])
  useEffect(() => {
    getRoleList(paginationFilter).then((data) => {
      let result = data.data as Role[]
      setRole(result)
    })
  }, [itemIdForUpdate])


  const filteredData = role?.filter((item: Role) =>
    Object.values(item).some((value: string | boolean) =>
      typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
    )
  );


  const { setItemIdForUpdate } = useListView()
  const openAddAccountModal = () => {
    setItemIdForUpdate(null)
    // navigate('/settings/role/add')
  }

  const openEditAccountModal = (id: any) => {
    setItemIdForUpdate(id)
  }
  const getSeverity = (status: any) => {
    switch (status) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'danger';
    }
  };
  const statusBodyTemplate = (rowData: Role) => {

    return <Tag value={rowData.isActive ? 'Active' : 'Inactive'} severity={getSeverity(rowData.isActive ? 'active' : 'inactive')} className={`badge fw-bolder ${rowData.isActive ? 'badge-light-success' : 'badge-light-danger'}`} />;
  };
  const navigate = useNavigate()
  const actionBodyTemplate = (rowData: any) => {

    const isRoleAdmin = rowData.name === 'Admin';
    const isRoleBasic = rowData.name === 'Basic'
    const isRoleCustomer = rowData.name === 'Customer'
    const isRoleSuperAdmin = rowData.name === 'SuperAdmin'
    if (isRoleAdmin || isRoleBasic || isRoleSuperAdmin || isRoleCustomer) {
      return (
        null)
    }
    else {
      return (
        <div className="actions">
          <a
            href='#'
            className='btn btn-light btn-active-light-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            Actions
            <KTIcon iconName='down' className='fs-5 m-0' />
          </a>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
            data-kt-menu='true'
          >
            {/* {MustHavePermission(SWSection.Store, SWResource.Roles, SWAction.Update) ? */}
            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={() => { openEditAccountModal(rowData.id) }}>
                Edit
              </a>
            </div>
            {/* : null} */}
            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={() => navigate(`/settings/role/permission/${rowData.id}`)}>
                Update Permission
              </a>
            </div>

            {/* <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => {
              deleteRoleById(rowData.id)
              toast.success('Role deleted Successfully')
            }
            }>
              Delete
            </a>
          </div> */}
          </div>
        </div>
      );
    }
  };



  const renderHeader = () => (

    <><div className='d-flex align-items-center position-relative justify-content-between my-1'>
      {/* <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' /> */}
      <input
        type='text'
        data-kt-customer-table-filter='search'
        className='form-control form-control-solid w-250px ps-14'
        placeholder='Search Role'
        value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)} />
      <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add
      </button>
    </div>
    </>   
  );

  const header = renderHeader();
  return (
    <>
      <KTCard>
        <KTCardBody>
          <>           
            <div>
              <DataTable
                paginator rows={10} rowsPerPageOptions={[20, 30, 50]}
                className='table align-middle table-row-dashed gy-5 dataTable no-footer'
                value={filteredData}
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'
                header={header}
              >
                <Column

                  className='text-gray-600 fw-bold fs-6'
                  field='name'
                  header={
                    <div className='text-start text-muted fw-bolder gs-0 min-w-125px'>
                      ROLE NAME
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                />

                <Column field="isActive"
                  header={
                    <div className='text-start text-muted fw-bolder gs-0 min-w-125px'>
                      STATUS
                    </div>
                  }
                  filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={statusBodyTemplate} />

                {/* {MustHavePermission(SWSection.Store, SWResource.Roles, SWAction.Update) ? */}
                <Column field=""
                  header={
                    <div className='text-start text-muted fw-bolder gs-0 min-w-125px'>
                      ACTIONS
                    </div>
                  }
                  body={actionBodyTemplate} />
                {/* : null} */}
              </DataTable>
            </div>
          </>
        </KTCardBody>
      </KTCard>

      {itemIdForUpdate !== undefined && <RoleModal />}
    </>
  )
}

const RoleWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RoleList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { RoleWrapper }
