import { SEOListSearchComponent } from "./SEOListSearchComponent"
import { SEOListToolbar } from "./SEOListToolbar"

const SEOListHeader = () => {

  return (
    <div className='card-header border-0 pt-6'>
      <SEOListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <SEOListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default SEOListHeader
