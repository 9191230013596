import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { PaymentListWrapper } from './PaymentListWrapper'
import { PaymentDetailWrapper } from './PaymentDetailWrapper'
import { PaymentForm } from './PaymentForm'


const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const PaymentPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Payment</PageTitle>
              <PaymentListWrapper />
            </>
          }
        />

        <Route
          path='detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}> Payment Details</PageTitle>
              <PaymentDetailWrapper />
            </>
          }
        />

        <Route
          path='add-payment'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Payment</PageTitle>
              <PaymentForm />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default PaymentPage
