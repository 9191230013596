import { FC, useState, createContext, useContext, useMemo } from 'react'

import { ID, StatusViewContextProps, initialStatusView } from '../../../../../_metronic/helpers/crud-helper/models'
import { WithChildren } from '../../../../../_metronic/helpers'


//const ListViewContext = createContext<ListViewContextProps>(initialListView)
const ListViewContext = createContext<StatusViewContextProps>(initialStatusView)
const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialStatusView.itemIdForUpdate)
  const[actionType,setActionType]=useState<String>(initialStatusView.actionType as string)
  return (
    <ListViewContext.Provider
      value={{
        itemIdForUpdate,
        setItemIdForUpdate,
        actionType,
        setActionType,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)
export { ListViewProvider, useListView }
