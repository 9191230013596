import React from 'react'
import { useQuery } from 'react-query'
import { QUERIES } from '../../../../../../_metronic/helpers'
import { getCreditNote } from '../../../core/_requests'
import SaleSettingPage from './SaleSettingPage'

const SaleSetting = () => {
  const {isLoading:isCreditLoading, data: creditData, error: creditError} = useQuery([`${QUERIES.CREDIT_NOTE_SETTING}`], async () => {
    return await getCreditNote()
  })

  
 
 if (!isCreditLoading && !creditError && creditData
   ) {

    return <SaleSettingPage creditData={creditData} />     }  

  return (
  
    null
  )
}

export default SaleSetting