import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query';
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers';
import { getPaymentById } from './core/_request';
import PaymentDetailPage from './PaymentDetailPage';


const PaymentDetailWrapper = () => {
    const { id } = useParams();
    const enabledQuery: boolean = isNotEmpty(id)
    const {
        isLoading,
        data: result,
        error,
    } = useQuery(
        [`${QUERIES.PAYMENT_LIST}-${id}`],
        async () => {
            return await getPaymentById(id)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {

                console.error(err)
            },
        }
    )
    if (!isLoading && !error && result) {
        return <PaymentDetailPage isPaymentLoading={isLoading} data={result} />
    }
    return null
}
export { PaymentDetailWrapper }
