
import { KTCard } from '../../../../_metronic/helpers'
import TestimonialListHeader from './components/header/TestimonialListHeader'


import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { TestimonialTable } from './testimonial-table/TestimonialTable'



const TestimonialList = () => {
 
  return (
    <>
      <KTCard>
       <TestimonialListHeader/>
       <TestimonialTable /> 
      </KTCard>
    </>
  )
}

const TestimonialListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TestimonialList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TestimonialListWrapper}
