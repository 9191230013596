import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from '../../../../../_metronic/helpers'
import { Agent, AgentCommission, AgentCommissionQueryResponse, AgentPayout, AgentPayoutQueryResponse, AgentQueryResponse, TransactionHistory } from './_models'
import { Result } from '../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Agent_LIST_URL = `${API_URL}/agent/search`
const Agent_BY_ID_URL = `${API_URL}/agent`
const Agent_ADD_URL = `${API_URL}/agent`
const Agent_Record_URL=`${API_URL}/agent/record`
const Transaction_History_URL=`${API_URL}/agent/transactionhistory`
const Agent_Commission_URL=`${API_URL}/agentcommission/search`
const Agent_Payout_URL=`${API_URL}/agentpayout`
const Agent_Payout_List_URL=`${API_URL}/agentpayout/search`
const AGENT_COMMISSION_BY_ID=`${API_URL}/agentcommission/unpaid-commission`

const getAgentList = async (filter: PaginationFilter): Promise<AgentQueryResponse> => {
 
  return await axios
    .post(`${Agent_LIST_URL}`, filter)
    .then((d: AxiosResponse<AgentQueryResponse>) => {
      console.log("agent data",d.data)
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getAgentById = async (id: ID): Promise<Result | Agent> => {
  return axios
    .get(`${Agent_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Agent>) => response.data)
    .then((response: Agent) => response)
    .catch((err: Result) => {
      return err
    })
}

const createAgent = async (agent: Agent): Promise<Result> => {
  return await axios
    .post(Agent_ADD_URL, {
      firstName: agent.firstName?.trim(),
      lastname: agent.lastName?.trim(),
      companyName: agent.companyName?.trim(),
      addressLine1:agent.addressLine1,
      mobileNumber: agent.mobileNumber ,
      emailAddress: agent.emailAddress?.trim() ,
      addressLine2:agent.addressLine2,
      whatsappNumber: agent.whatsappNumber ,
      city:agent.city,
      rateId: agent.rateId ,
      stateId:agent.stateId,
      userName:agent.userName?.trim(),
      password:agent.password?.trim(),
      zipCode:agent.zipCode,
      dateOfBirth:agent.dateOfBirth,
      dateOfAnniversary:agent.dateOfAnniversary,
      commissionPercent:agent.commissionPercent,
      enableLogin:agent.enableLogin,
      uploadImage:agent.uploadImage
      
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateAgent = (agent: Agent): Promise<Result> => {
  return axios
    .put(`${Agent_BY_ID_URL}/${agent.id}`, {
      id: agent.id,
      firstName: agent.firstName?.trim(),
      lastname: agent.lastName?.trim(),
      companyName: agent.companyName?.trim(),
      addressLine1:agent.addressLine1,
      mobileNumber: agent.mobileNumber ,
      emailAddress: agent.emailAddress?.trim() ,
      addressLine2:agent.addressLine2,
      whatsappNumber: agent.whatsappNumber ,
      city:agent.city,
      rateId: agent.rateId ,
      stateId:agent.stateId,
      userName:agent.userName?.trim(),
      password:agent.password?.trim(),
      zipCode:agent.zipCode,
      dateOfBirth:agent.dateOfBirth,
      dateOfAnniversary:agent.dateOfAnniversary,
      commissionPercent:agent.commissionPercent,
      enableLogin:agent.enableLogin,
      isActive:agent.isActive,
      userId:agent.userId,
      uploadImage:agent.uploadImage,
      imagePath:agent.imagePath
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getRecordById = async (id: ID): Promise<Result | Agent> => {
  return axios
    .get(`${Agent_Record_URL}/${id}`)
    .then((response: AxiosResponse<Agent>) => response.data)
    .then((response: Agent) => response)
    .catch((err: Result) => {
      return err
    })
}

const transactionHistory = async (id: ID): Promise<Result |TransactionHistory > => {
  return axios
    .get(`${Transaction_History_URL}/${id}`)
    .then((response: AxiosResponse<TransactionHistory>) => response.data)
    .then((response: TransactionHistory) => response)
    .catch((err: Result) => {
      return err
    })
}

const getAgentCommissionList = async (filter: PaginationFilter): Promise<AgentCommissionQueryResponse> => {
 
  return await axios
    .post(`${Agent_Commission_URL}`, filter)
    .then((d: AxiosResponse<AgentCommissionQueryResponse>) => {
   
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}


const createAgentPayout = async (payment: AgentPayout): Promise<Result> => {
  return await axios
    .post(Agent_Payout_URL, {
      agentId: payment.agentId,
      amountPaid: payment.amountPaid,
      accountId:payment.accountId,
      paymentDate: payment.paymentDate,
      paymentModeId: payment.paymentModeId,
      referenceNumber: payment.referenceNumber,
      notes: payment.notes,
      agentCommissionIds:payment.agentCommissionIds
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const getAgentPayoutList = async (filter: PaginationFilter): Promise<AgentPayoutQueryResponse> => {
 
  return await axios
    .post(`${Agent_Payout_List_URL}`, filter)
    .then((d: AxiosResponse<AgentPayoutQueryResponse>) => {
     
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getAgentCommisssionById = async (id: number): Promise< AgentCommission> => {
  return axios
    .get(`${AGENT_COMMISSION_BY_ID}/${id}`)
    .then((response: AxiosResponse<AgentCommission>) => response.data)
    .then((response: AgentCommission) => response)
   
}

export {
  getAgentList,
  getAgentById,
  createAgent,
  updateAgent,
  getRecordById,
  transactionHistory,
  getAgentCommissionList,
  createAgentPayout,
  getAgentPayoutList,
  getAgentCommisssionById

}
