import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { BannerListWrapper } from './BannerList'
import BannerAddPage from './pages/BannerAddPage'
import { BannerWrapper } from './pages/BannerWraper'



const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BannerPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Banner</PageTitle>
              <BannerListWrapper />
            </>
          }
        />

        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Banner</PageTitle>
              <BannerAddPage />
            </>
          }
        />

        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Edit Banner</PageTitle>
              <BannerWrapper />
            </>
          }
        />

      </Route>
      <Route index element={<Navigate to='/cms/banner/list' />} />
    </Routes>
  )
}

export default BannerPage
