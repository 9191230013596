// @ts-nocheck
import {Column} from 'react-table'
import { Agent } from '../core/_models'
import { AgentCustomHeader } from './AgentCustomHeader'
import { AgentActionsCell } from './AgentActionsCell'
import { StatusCell } from '../../../../../_metronic/helpers/components/StatusCell'

const completeName = (firstName: string, lastName: string) => {
  return firstName + " " + lastName
}

const AgentColumns: ReadonlyArray<Column<Agent>> = [
  {
    Header: (props) => <AgentCustomHeader tableProps={props} title='Agent Name' className='min-w-125px' />,
    id: 'firstName',
     Cell: ({ ...props }) => completeName(props.data[props.row.index].firstName, props.data[props.row.index].lastName),
    //accessor:'printName'
  },
  {
    Header: (props) => <AgentCustomHeader tableProps={props} title='Agent Code' className='min-w-125px' />,
    accessor: 'agentCode'
  },
 
  {
    Header: (props) => <AgentCustomHeader tableProps={props} title='Company Name' className='min-w-125px' />,
    accessor: 'companyName'
  },
  {
    Header: (props) => <AgentCustomHeader tableProps={props} title='Mobile Number' className='min-w-125px' />,
    accessor: 'mobileNumber'
  },
  { 
    Header: (props) => <AgentCustomHeader tableProps={props} title='Email Address' className='min-w-125px' />,
    accessor: 'emailAddress'
  },
  {
    Header: (props) => <AgentCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'isActive',
    Cell: ({...props}) => <StatusCell isActive={props.data[props.row.index].isActive} />,
  },
  {
    Header: (props) => (
      <AgentCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <AgentActionsCell id={props.data[props.row.index].id} />,
  },
]

export {AgentColumns}
