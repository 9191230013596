
import { KTIcon } from '../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'

const PaymentModeHeader = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'> {!itemIdForUpdate ? `Create Payment Mode` : `Edit Payment Mode`}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-brand-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{ cursor: 'pointer' }}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export { PaymentModeHeader }
