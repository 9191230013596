/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useQueryRequest } from './QueryRequestProvider'
import { createResponseContext, stringifyRequestQuery } from '../../../../../_metronic/helpers/crud-helper/helpers'
import { WithChildren } from '../../../../../_metronic/helpers'
import { PaginationResponse, initialQueryResponse } from '../../../../../_metronic/helpers/crud-helper/models'
import { QUERIES } from '../../../../../_metronic/helpers/crud-helper/consts'
import { Banner } from './_models'
import { getBannerList } from './_requests'


const QueryResponseContext = createResponseContext<Banner>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.BANNER}-${query}`,
    () => {
      return getBannerList(state)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationResponse = {
    currentPage: 1,
    pageSize: 10
  }
  const { response } = useQueryResponse()
  if (!response || !response.pagination) {
    return defaultPaginationState
  }

  return response.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}


export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
