import { useQuery } from 'react-query'
import { useListView } from '../core/ListViewProvider'
import { isNotEmpty } from '../../../../../_metronic/helpers/crud-helper/helpers'
import { QUERIES } from '../../../../../_metronic/helpers/crud-helper/consts'
import { useParams } from 'react-router-dom'
import { getCollectionById } from '../core/_requests'
import CollectionEditPage from './CollectionEditPage'




const CollectionWrapper = () => {
  const {id}=useParams()
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(id)
  const {
    isLoading,
    data: result,
    error,
  } = useQuery(
    `${QUERIES.COLLECTIONS}-${id}`,
    async () => {
      return await getCollectionById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

 
  if (!isLoading && !error && result) {
    return <CollectionEditPage isBannerLoading={isLoading} data={result} />
  }

  return null
}

export { CollectionWrapper }
