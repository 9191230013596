import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from '../../../../../_metronic/helpers/crud-helper/models'

import { Result } from '../../../../core/models/Result'
import { Role, RolePermissions, RoleQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_STORE_API_URL
const Role_URL = `${API_URL}/role`
const ROLE_LIST_URL=`${API_URL}/role/search`
const getRoleList = async (filter: PaginationFilter): Promise<RoleQueryResponse> => {
   return await axios
    .post(`${ROLE_LIST_URL}`,filter )
    .then((d: AxiosResponse<RoleQueryResponse>) => {  
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getRoleById = async (id: ID): Promise<Result | Role> => {
  return await axios
    .get(`${Role_URL}/${id}`)
    .then((response: AxiosResponse<Role>) => response.data)
    .then((response: Role) => response)
    .catch((err: Result) => {
      return err
    })
}

const createRole = async (role: Role): Promise<Result> => {
  return await axios
    .post(Role_URL, {
      name: role.name,
      permissions:role.permissions      
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateRole = async (role: Role): Promise<Result> => {
  return await axios
    .put(`${Role_URL}/${role.id}`, {
      id: role.id,
      name: role.name,
      permissions:role.permissions,
      isActive:role.isActive

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
// const deleteRoleById = async (id: ID): Promise<Result | Role> => {
 
//   return await axios
//     .delete(`${Role_URL}/${id}`)
//     .then((response: AxiosResponse<Role>) => response.data)
//     .then((response: Role) => response)
//     .catch((err: Result) => {
//       return err
//     })
// }

const updateRolePermissions = async (permission: RolePermissions): Promise<Result> => {
   
  return await axios

    .put(`${Role_URL}/${permission.id}/permissions`, {
      id: permission.id,
      permissions:permission.permissions

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

export {
  getRoleList, getRoleById, createRole, updateRole,updateRolePermissions
}
