import { Link, Navigate, useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { ProductGroupListFilter } from './ProductGroupListFilter'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../../core/shared/core/permissionUtils'






const ProductGroupListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const openAddUserModal = () => {
    //  setItemIdForUpdate(null)    //to redirect to modal
    navigate('/catalog/productgroup/add')   //to redirect to add page

  }
  const navigate = useNavigate();
  return (
    <div className="d-flex align-items-center justify-content-end gap-2 gap-lg-3">
      {/* <div className="m-0">
        <ProductGroupListFilter />
      </div> */}
      {/* {MustHavePermission(SWSection.Store, SWResource.ProductGroups, SWAction.Create) ?
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTIcon iconName='plus' className='fs-2' />
          Add
        </button>
        :
        null} */}
      <button type='button' className='btn btn-success' onClick={() => { navigate('/catalog/productgroup/pricelist') }}>       
        Price List
      </button>
    </div>
  )
}

export { ProductGroupListToolbar }
