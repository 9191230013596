import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Product } from "../../../modules/catalog/product/product-list/core/_models";
import { Filter, PaginationFilter } from "../../../../_metronic/helpers";
import { getProductList } from "../../../modules/catalog/product/product-list/core/_requests";


interface ProductSearchbarProps {
    onSelect: (newValue: Product) => void;
}

const ProductSearchbar = ({ onSelect }: ProductSearchbarProps) => {
    const [searchString, setSearchString] = useState("");
    const [keyDown, setKeyDown] = useState(false);
    const [filterProducts, setfilterProducts] = useState<Product[]>([]);

    const formatResult = (item: Product) => {
        return (
            <span style={{ display: "block", textAlign: "left" }}>
                {" "}
                {/* {item.designNumber} ({item.name}) */}
                {item.name}
            </span>
        );
    };



    const onProductSearch = (product: Product) => {
        
        onSelect(product);
        setSearchString("");
        setKeyDown(false);
    };

    let scanProductBarCode = false;
    let scannerEnter = false;
    const handleOnSelect = (result: Product) => {

        if (keyDown === false && scanProductBarCode === true) {
            onProductSearch(result);
            scanProductBarCode = false;
        }

        if (searchString.trim()?.length !== 0) {
            onProductSearch(result);
        }
    };

    const handleOnSearch = async (searchValue: string) => {

        if (searchString.trim() === "") {
            setSearchString(searchValue);

            if (!isNaN(parseInt(searchValue))) {
                scanProductBarCode = true;
            }

            let paginationFilter: PaginationFilter = {
                pageNumber: 1,
                pageSize: 10
            };

            let isActiveFilter = { field: 'isActive', operator: 'eq', value: true }
            let ProductFilter: Filter = {
                logic: 'or',
                filters: [{ field: 'name', operator: 'contains', value: searchValue },
                // { field: 'designNumber', operator: 'contains', value: searchValue },
                { field: 'barcode', operator: 'contains', value: searchValue }]
            }

            paginationFilter.advancedFilter = {
                logic: 'and',
                filters: [isActiveFilter, ProductFilter]
            }

            const response = await getProductList(paginationFilter);
            if (response.data !== undefined) {
                if (response.data.length == 1 && response.data[0].barcode == searchValue) {
                    handleOnSelect(response.data[0]);
                    setSearchString("");
                }
                else {
                    setfilterProducts(response.data)

                }
            }

        } else {
           // setSearchString("");
        }
    };


;

    const inputFocus = () => {

        let searchInput = document.querySelector<HTMLElement>('input[data-test="search-input"]');
        if (searchInput != null) {
            searchInput.focus();
        }
    };

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.altKey && event.code === "KeyQ") {
                event.preventDefault();
                inputFocus();
            }
            else if (event.key === 'Enter') {
                event.preventDefault();
                inputFocus();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, []);


    return (
        <Col className="position-relative my-3 search-bar product-details col-xxl-8 col-lg-12 col-12">
            <ReactSearchAutocomplete<Product>
                placeholder="Scan / Search Product By Name or Code"
                items={filterProducts}
                onSearch={handleOnSearch}
                inputSearchString={searchString}
                onSelect={(data) => {
                    handleOnSelect(data);
                }}
                formatResult={formatResult}
                showIcon={false}
                showClear={false}
                autoFocus={true} />
            <i className="bi bi-search fs-2 react-search-icon position-absolute top-0 bottom-0 d-flex align-items-center ms-2" />

          

        </Col>
    )
}

export default ProductSearchbar