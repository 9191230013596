import { FC, useEffect, useState } from 'react'
import { Result } from '../../../../../core/models/Result'

import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import { getOrganizatinProfile, updateSalesOrderSetting } from '../../../core/_requests'


import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import { OrganizationProfile, SalesOrderSetting } from '../../../core/_models'
import CustomDatePicker from '../../../../../core/shared/components/CustomDatePicker'
import { Link } from 'react-router-dom'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils'

type Props = {
  data: Result | SalesOrderSetting
}


const SaleOrderForm: FC<Props> = ({ data }) => {
  const API_URL = process.env.REACT_APP_IMAGE_API_URL

  const soDetail = data as SalesOrderSetting
  const [orgProfile, setOrgProfile] = useState<OrganizationProfile>();

  const initialValues: SalesOrderSetting = {
    id: soDetail.id,
    termCondition: soDetail.termCondition,
    customerNotes: soDetail.customerNotes,
    isClosedOrderEditable: soDetail.isClosedOrderEditable,
    defaultDeliveryTime: soDetail.defaultDeliveryTime

  }



  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,

    onSubmit: async (formValues, { setSubmitting }) => {
      setSubmitting(true)

      let SOData: SalesOrderSetting = {
        id: formValues.id,
        termCondition: formValues.termCondition,
        customerNotes: formValues.customerNotes,
        isClosedOrderEditable: formValues.isClosedOrderEditable,
        defaultDeliveryTime: formValues.defaultDeliveryTime
      }
      let result: Result
      result = await updateSalesOrderSetting(SOData)
      toast.success("Sale Order Updated Successfully.")
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOrganizatinProfile();
        const profile = response as OrganizationProfile;
        setOrgProfile(profile);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <FormikProvider value={formik}>  

      <KTCard>
        <KTCardBody>
          <form
            id='kt_modal_add_creditnote_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >


            <div className='mb-7 row '>

              <div className='col-6 col-md-6'><div><label className='form-label'>Delivery Time</label></div>

                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.defaultDeliveryTime && formik.errors.defaultDeliveryTime },
                    {
                      'is-valid': formik.touched.defaultDeliveryTime && !formik.errors.defaultDeliveryTime,
                    }
                  )}

                  {...formik.getFieldProps('defaultDeliveryTime')}
                  name='defaultDeliveryTime'
                />
                {formik.touched.defaultDeliveryTime && formik.errors.defaultDeliveryTime && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.defaultDeliveryTime}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6 col-md-6'>

                <input
                  className='form-check-input ms-1'
                  type='checkbox'
                  {...formik.getFieldProps('isClosedOrderEditable')}
                  checked={{ ...formik.getFieldProps('isClosedOrderEditable') }.value}


                /><span className='ms-4'>Restrict closed sales orders from being edited</span>
                {formik.touched.isClosedOrderEditable && formik.errors.isClosedOrderEditable && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.isClosedOrderEditable}</span>
                    </div>
                  </div>
                )}
              </div>

            </div>

            <div className='mb-7 row '>
              
                <label className='form-label'>Terms & Conditions</label>
                <textarea
                  rows={5}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.termCondition && formik.errors.termCondition },
                    {
                      'is-valid': formik.touched.termCondition && !formik.errors.termCondition,
                    }
                  )}

                  {...formik.getFieldProps('termCondition')}
                  name='termCondition'
                  placeholder="Enter the terms and conditions of your business to be displayed in your transaction"
                />
                {formik.touched.termCondition && formik.errors.termCondition && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.termCondition}</span>
                    </div>
                  </div>
                )}
              </div>
              
           
            <div className='row mb-7'>
          
                <label className='form-label'>Customer Notes</label>
                <textarea
                  rows={5}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.customerNotes && formik.errors.customerNotes },
                    {
                      'is-valid': formik.touched.customerNotes && !formik.errors.customerNotes,
                    }
                  )}
                  placeholder="Enter any notes to be displayed in your transaction"
                  {...formik.getFieldProps('customerNotes')}
                  name='customerNotes'
                />
                {formik.touched.customerNotes && formik.errors.customerNotes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.customerNotes}</span>
                    </div>
                  </div>
                )}
            
            </div>
            {MustHavePermission(SWSection.Store, SWResource.SalesOrderSettings, SWAction.Update) ?
            <button type='submit' className='btn btn-primary '>
              Save
            </button>:null}
          </form>
        </KTCardBody>
      </KTCard>
    </FormikProvider>
  )
}

export { SaleOrderForm }
