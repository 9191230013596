import React from 'react';
import clsx from 'clsx';
import { PaginationResponse, QueryState } from '../../../../_metronic/helpers/crud-helper/models';
import { calculateRange } from '../../../../_metronic/helpers';

interface PaginationProps {
  pagination: PaginationResponse
  isLoading: boolean
  updateState: (updates: Partial<QueryState>) => void;
}

const Pagination: React.FC<PaginationProps> = ({ pagination, isLoading, updateState }) => {
  if (!pagination.currentPage || !pagination.totalPages) {
    return null; // Return early if pagination data is missing or invalid
  }

  const currentPage = pagination.currentPage
  const pageSize = typeof pagination.pageSize === 'number' ? pagination.pageSize : 10
  const totalPages = pagination.totalPages
  const totalCount = typeof pagination.totalCount === 'number' ? pagination.totalCount : 0

  const paginationRange = calculateRange(currentPage, totalPages, 5);

  const startRecord = currentPage == 1 ? 1 : ((currentPage - 1) * pageSize) + 1
  const endRecord = (currentPage * pageSize) < totalCount ? (currentPage * pageSize) : totalCount

  let pageNumbers: Array<number> = [];
  for (let i = paginationRange.start; i <= paginationRange.end; i++) {
    pageNumbers.push(i);
  }

  const changePageSize = (pageSize: string) => {
    if (!pagination || !pageSize || isLoading || pagination.pageSize === parseInt(pageSize)) {
      return;
    }
    updateState({ pageNumber: 1, pageSize: parseInt(pageSize) });
  }

  const changePageNumber = (pageNumber: number) => {
    if (!pagination || !pageNumber || isLoading || pagination.currentPage === pageNumber) {
      return;
    }
    updateState({ pageNumber: pageNumber, pageSize: pagination.pageSize || 10 });
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <div className='dataTables_length'>
          <label>
            <select
              className='form-select form-select-sm form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-generic-table-page-size='pageSize'
              data-hide-search='true'
              onChange={(e) => changePageSize(e.target.value)}>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
            </select>
          </label>
        </div>
        <div className='dataTables_info'>
          Showing {startRecord} to {endRecord} of {totalCount} records
        </div>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_generic_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: isLoading || pagination.currentPage === 1,
              })}
            >
              <a onClick={() => changePageNumber(1)} style={{ cursor: 'pointer' }} className='page-link'>
                <span className="ki-duotone ki-double-left-arrow"><span className="path1"></span><span className="path2"></span></span>
              </a>
            </li>
            <li
              className={clsx('page-item previous', {
                disabled: isLoading || !pagination.hasPreviousPage,
              })}
            >
              <a onClick={() => changePageNumber(currentPage - 1)} style={{ cursor: 'pointer' }} className='page-link page-text me-5'>
              <span className="ki-duotone ki-arrow-left"><span className="path1"></span><span className="path2"></span></span>

              </a>
            </li>
            {pageNumbers.map((pn) => (
              <li
                key={pn}
                className={clsx('page-item', {
                  active: pagination.currentPage === pn,
                  disabled: isLoading,
                })}
              >
                <a
                  className={clsx('page-link')}
                  onClick={() => changePageNumber(pn)}
                  style={{ cursor: 'pointer' }}
                >
                  {pn}
                </a>
              </li>
            ))}
            <li
              className={clsx('page-item next', {
                disabled: isLoading || !pagination.hasNextPage,
              })}
            >
              <a onClick={() => changePageNumber(currentPage + 1)} style={{ cursor: 'pointer' }} className='page-link page-text'>
              <span className="ki-duotone ki-arrow-right"><span className="path1"></span><span className="path2"></span></span>
              
              </a>
            </li>
            <li
              className={clsx('page-item', {
                disabled: isLoading || pagination.currentPage === pagination.totalPages,
              })}
            >
              <a
                onClick={() => changePageNumber(totalPages)}
                style={{ cursor: 'pointer' }}
                className='page-link'
              >
                <span className="ki-duotone ki-double-right-arrow"><span className="path1"></span><span className="path2"></span></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
