import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../../_metronic/helpers'
import ProductGroupPage from './productGroup/ProductGroupPage'
import PrintBarcodePage from './printBarcode/PrintBarcodePage'
import { useAuth } from '../auth'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../core/shared/core/permissionUtils'
import UnAuthorized from '../errors/components/UnAuthorized'


const CatalogRoutes = () => {
  const ProductPage = lazy(() => import('./product/ProductPage'))
  const ManufacturerPage = lazy(() => import('./manufacturer/ManufacturerPage'))
  const BrandPage = lazy(() => import('./brand/BrandPage'))
  const CategoryPage = lazy(() => import('./category/CategoryPage'))
  const { currentUser } = useAuth()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='brands/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Brands, SWAction.Search) ? <BrandPage /> : <UnAuthorized />}

            </SuspensedView>
          }
        />
        <Route
          path='manufacturer/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Manufacturers, SWAction.Search) ? <ManufacturerPage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        <Route
          path='product/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Products, SWAction.Search) ? <ProductPage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        <Route
          path='print-barcode/*'
          element={
            <SuspensedView>
              <PrintBarcodePage  />
            </SuspensedView>
          }
        />
        <Route
          path='productgroup/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.ProductGroups, SWAction.Search) ? <ProductGroupPage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        <Route
          path='categories/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Categories, SWAction.Search) ? <CategoryPage /> : <UnAuthorized />}
            </SuspensedView>
          }
        />
        {/* <Route
          path='pricelist/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.PriceList, SWAction.Search) ? <PriceList /> : <UnAuthorized />}
              <PriceListPage />
            </SuspensedView>
          }
        /> */}
      </Route>


    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { CatalogRoutes }
