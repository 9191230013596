import { KTCard } from '../../../../_metronic/helpers'
import { PaymentModal } from './agent-pages/payment/PaymentModal'
import { AgentTable } from './agent-table/AgentTable'
import AgentAdvancedSearch from './components/header/AgentAdvancedSearch'
import AgentListHeader from './components/header/AgentListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'

const AgentList = () => { 

  return (
    <>
    <AgentAdvancedSearch/>
      <KTCard>
       {/* <AgentListHeader/> */}
       <AgentTable /> 
      </KTCard>
      {/* {itemIdForUpdate === undefined && <PaymentModal />} */}
    </>
  )
}

const AgentWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AgentList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AgentWrapper}
