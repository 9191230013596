
import {useQuery} from 'react-query'

import React  from 'react'
import { QUERIES } from '../../../../../_metronic/helpers'
import {  getPurchaseOrderSetting,} from '../../core/_requests'
import { PurchaseOrderForm } from './PurchaseOrderForm'


export const PurchaseOrderPage = () => {
  const {isLoading, data: result, error: error} = useQuery([`${QUERIES.PO_SETTING}`], async () => {
    return await getPurchaseOrderSetting()
  })
  
 if (!isLoading && !error && result) {

    return <PurchaseOrderForm data={result} />
  }
  return (
  
    null
  )
}

