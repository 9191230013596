import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"

import { Result } from '../../../../core/models/Result'
import { PageQueryResponse, SEO, SEOQueryResponse } from './_models'
const API_URL = process.env.REACT_APP_STORE_API_URL
const SEO_LIST_URL = `${API_URL}/seo/search`
const SEO_BY_ID_URL = `${API_URL}/seo`
const SEO_ADD_URL = `${API_URL}/seo`

const Page_LIST_URL = `${API_URL}/page/search`



const getSEOList = async (filter: PaginationFilter): Promise<SEOQueryResponse> => {
  return await axios
    .post(`${SEO_LIST_URL}`, filter)
    .then((d: AxiosResponse<SEOQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getSEOById = async (id: ID): Promise<Result | SEO> => {
  return axios
    .get(`${SEO_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<SEO>) => response.data)
    .then((response: SEO) => response)
    .catch((err: Result) => {
      return err
    })
}
const createSEO = async (seo: SEO): Promise<Result> => {
  return await axios
    .post(SEO_ADD_URL, {
      pageId:seo.pageId,
      metaDescription:seo.metaDescription,
      metaKeywords:seo.metaKeywords,
      metaTitle:seo.metaTitle,
      googleTagManager:seo.googleTagManager
      
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateSEO = (seo: SEO): Promise<Result> => {
  return axios
    .put(`${SEO_BY_ID_URL}/${seo.id}`, {
      id: seo.id,
      pageId:seo.pageId,
      metaDescription:seo.metaDescription,
      metaKeywords:seo.metaKeywords,
      metaTitle:seo.metaTitle,
      googleTagManager:seo.googleTagManager
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteSEO = async (id: ID): Promise<Result | SEO> => {
  return axios
    .delete(`${SEO_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<SEO>) => response.data)
    .then((response: SEO) => response)
    .catch((err: Result) => {
      return err
    })
}

const getPageList = async (filter: PaginationFilter): Promise<PageQueryResponse> => {
  return await axios
    .post(`${Page_LIST_URL}`, filter)
    .then((d: AxiosResponse<PageQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}


export {
  getSEOList,
  getSEOById,
  createSEO,
  updateSEO,
  deleteSEO,
  getPageList,


}
