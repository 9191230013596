import { FC } from 'react'
import { Result } from '../../../../core/models/Result'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import { updateCustomerVendor, updateEmailSetting } from '../../core/_requests'
import { CustomerVendor, EmailSetting } from '../../core/_models'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'


type Props = {
  data: Result | EmailSetting
}

const emailSchema = Yup.object().shape({
  emailEncryption: Yup.string().required('Please enter email encryption'),
  smtpHost: Yup.string().required('Please enter SMTP Host.'),
  smtpPort: Yup.string().required('Please enter SMTP Port'),
  smtpUserName: Yup.string().required('Please enter SMTP Username'),
  smtpPassword: Yup.string().required('Please enter SMTP Password.'),
  emailAddress: Yup.string().required('Please enter Email Address.'),
  emailCharset: Yup.string().required('Please enter Email Charset.'),
  bccAllEmailsTo: Yup.string().required('Please enter BCC Email.'),
  emailSignature: Yup.string().required('Please enter Email Signature.'),
  predefinedHeader: Yup.string().required('Please enter predefined Header.'),
  predefinedFooter: Yup.string().required('Please enter predefined Footer.'),
  enableEmailQueue: Yup.string().required('Please select email queue'),
  allowEmailWithAttachmentInQueue:Yup.string().required('Please select email Attachment.')
})
const EmailSettingForm: FC<Props> = ({ data }) => {
  const emailDetail = data as EmailSetting
  const initialValues: EmailSetting = {
    id: emailDetail.id,
    emailEncryption: emailDetail.emailEncryption,
    smtpHost: emailDetail.smtpHost,
    smtpPort: emailDetail.smtpPort,
    smtpUserName: emailDetail.smtpUserName,
    smtpPassword: emailDetail.smtpPassword,
    emailAddress: emailDetail.emailAddress,
    emailCharset: emailDetail.emailCharset,
    bccAllEmailsTo: emailDetail.bccAllEmailsTo,
    emailSignature: emailDetail.emailSignature,
    predefinedHeader: emailDetail.predefinedHeader,
    predefinedFooter: emailDetail.predefinedFooter,
    enableEmailQueue: emailDetail.enableEmailQueue,
    allowEmailWithAttachmentInQueue: emailDetail.allowEmailWithAttachmentInQueue

  }



  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    validationSchema: emailSchema,
    
    onSubmit: async (formValues, { setSubmitting }) => {      
      setSubmitting(true)
      let emailSetting: EmailSetting = {
        id: formValues.id,
        emailEncryption: formValues.emailEncryption,
        smtpHost: formValues.smtpHost,
        smtpPort: formValues.smtpPort,
        smtpUserName: formValues.smtpUserName,
        smtpPassword: formValues.smtpPassword,
        emailAddress: formValues.emailAddress,
        emailCharset: formValues.emailCharset,
        bccAllEmailsTo: formValues.bccAllEmailsTo,
        emailSignature: formValues.emailSignature,
        predefinedHeader: formValues.predefinedHeader,
        predefinedFooter: formValues.predefinedFooter,
        enableEmailQueue: formValues.enableEmailQueue,
        allowEmailWithAttachmentInQueue: formValues.allowEmailWithAttachmentInQueue
      }
      let result: Result
      result = await updateEmailSetting(emailSetting)
      toast.success("Email Setting Updated Successfully.")
    },
  })



  return (
    <FormikProvider value={formik}>
      <KTCard>
        <KTCardBody>
          <form
            id='kt_modal_add_customervendor_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='mb-7 row '>
              <div className='col-6 col-md-6'>
                <label className='form-label'>Email Encryption</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.emailEncryption && formik.errors.emailEncryption },
                    {
                      'is-valid': formik.touched.emailEncryption && !formik.errors.emailEncryption,
                    }
                  )}
                  placeholder='emailEncryption'
                  {...formik.getFieldProps('emailEncryption')}
                  name='emailEncryption'
                />
                {formik.touched.emailEncryption && formik.errors.emailEncryption && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.emailEncryption}</span>
                    </div>
                  </div>
                )}
              </div>
              
            </div>

            <div className='mb-7 row '>
              <div className='col-6 col-md-6'>
                <label className='form-label'> SMTP Host</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.smtpHost && formik.errors.smtpHost },
                    {
                      'is-valid': formik.touched.smtpHost && !formik.errors.smtpHost,
                    }
                  )}
                  placeholder='smtpHost'
                  {...formik.getFieldProps('smtpHost')}
                  name='smtpHost'
                />
                {formik.touched.smtpHost && formik.errors.smtpHost && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.smtpHost}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6 col-md-6'>
                <label className='form-label'>SMTP Port</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.smtpPort && formik.errors.smtpPort },
                    {
                      'is-valid': formik.touched.smtpPort && !formik.errors.smtpPort,
                    }
                  )}
                  placeholder='smtpPort'
                  {...formik.getFieldProps('smtpPort')}
                  name='smtpPort'
                />
                {formik.touched.smtpPort && formik.errors.smtpPort && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.smtpPort}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='mb-7 row '>
              <div className='col-6 col-md-6'>
                <label className='form-label'> SMTP Username</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.smtpUserName && formik.errors.smtpUserName },
                    {
                      'is-valid': formik.touched.smtpUserName && !formik.errors.smtpUserName,
                    }
                  )}
                  placeholder='smtpUserName'
                  {...formik.getFieldProps('smtpUserName')}
                  name='smtpUserName'
                />
                {formik.touched.smtpUserName && formik.errors.smtpUserName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.smtpUserName}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6 col-md-6'>
                <label className='form-label'>SMTP Password</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.smtpPassword && formik.errors.smtpPassword },
                    {
                      'is-valid': formik.touched.smtpPassword && !formik.errors.smtpPassword,
                    }
                  )}
                  placeholder='smtpPassword'
                  {...formik.getFieldProps('smtpPassword')}
                  name='smtpPassword'
                />
                {formik.touched.smtpPassword && formik.errors.smtpPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.smtpPassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='mb-7 row '>
              <div className='col-6 col-md-6'>
                <label className='form-label'> Email Address</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.emailAddress && formik.errors.emailAddress },
                    {
                      'is-valid': formik.touched.emailAddress && !formik.errors.emailAddress,
                    }
                  )}
                  placeholder='emailAddress'
                  {...formik.getFieldProps('emailAddress')}
                  name='emailAddress'
                />
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.emailAddress}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6 col-md-6'>
                <label className='form-label'>Email Charset</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.emailCharset && formik.errors.emailCharset },
                    {
                      'is-valid': formik.touched.emailCharset && !formik.errors.emailCharset,
                    }
                  )}
                  placeholder='emailCharset'
                  {...formik.getFieldProps('emailCharset')}
                  name='emailCharset'
                />
                {formik.touched.emailCharset && formik.errors.emailCharset && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.emailCharset}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='mb-7 row '>
              <div className='col-6 col-md-6'>
                <label className='form-label'> BCC Email </label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.bccAllEmailsTo && formik.errors.bccAllEmailsTo },
                    {
                      'is-valid': formik.touched.bccAllEmailsTo && !formik.errors.bccAllEmailsTo,
                    }
                  )}
                  placeholder='bccAllEmailsTo'
                  {...formik.getFieldProps('bccAllEmailsTo')}
                  name='bccAllEmailsTo'
                />
                {formik.touched.bccAllEmailsTo && formik.errors.bccAllEmailsTo && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.bccAllEmailsTo}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6 col-md-6'>
                <label className='form-label'>Email Signature</label>
                <textarea
                  rows={4}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.emailSignature && formik.errors.emailSignature },
                    {
                      'is-valid': formik.touched.emailSignature && !formik.errors.emailSignature,
                    }
                  )}
                  placeholder='emailSignature'
                  {...formik.getFieldProps('emailSignature')}
                  name='emailSignature'
                />
                {formik.touched.emailSignature && formik.errors.emailSignature && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.emailSignature}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='mb-7 row '>
              <div className='col-6 col-md-6'>
                <label className='form-label'> Predefined Header </label>
                <textarea
                rows={4}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.predefinedHeader && formik.errors.predefinedHeader },
                    {
                      'is-valid': formik.touched.predefinedHeader && !formik.errors.predefinedHeader,
                    }
                  )}
                  placeholder='predefinedHeader'
                  {...formik.getFieldProps('predefinedHeader')}
                  name='predefinedHeader'
                />
                {formik.touched.predefinedHeader && formik.errors.predefinedHeader && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.predefinedHeader}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6 col-md-6'>
                <label className='form-label'>Predefined Footer</label>
                <textarea
                  rows={4}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.predefinedFooter && formik.errors.predefinedFooter },
                    {
                      'is-valid': formik.touched.predefinedFooter && !formik.errors.predefinedFooter,
                    }
                  )}
                  placeholder='predefinedFooter'
                  {...formik.getFieldProps('predefinedFooter')}
                  name='predefinedFooter'
                />
                {formik.touched.predefinedFooter && formik.errors.predefinedFooter && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.predefinedFooter}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-7'>
              <div className='col-6 col-md-6 mb-4'>
                <label className="d-flex align-items-center fs-6 fw-bold mb-2 required ms-1">Email Queue</label>
                <input
                  className='form-check-input ms-1'
                  type='checkbox'
                  {...formik.getFieldProps('enableEmailQueue')}
                  checked={{ ...formik.getFieldProps('enableEmailQueue') }.value}


                /><span className='ms-4'>Enable Email Queue</span>
                {formik.touched.enableEmailQueue && formik.errors.enableEmailQueue && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.enableEmailQueue}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-6 col-md-6 mb-4'>
                <label className='fw-bold fs-6 mb-2 ms-1'>Email Attachment</label>

                <div className='form-check form-check-custom form-check-solid ms-1'>

                  <input
                    className='form-check-input ms-1'
                    type='checkbox'
                    {...formik.getFieldProps('allowEmailWithAttachmentInQueue')}
                    checked={{ ...formik.getFieldProps('allowEmailWithAttachmentInQueue') }.value}


                  /><span className='ms-4'>Enable Email Queue</span>
                </div>
                {formik.touched.allowEmailWithAttachmentInQueue && formik.errors.allowEmailWithAttachmentInQueue && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.allowEmailWithAttachmentInQueue}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {MustHavePermission(SWSection.Store, SWResource.EmailSettings, SWAction.Update) ?
            <button type='submit' className='btn btn-primary '>
              Save
            </button>
            :null}
          </form>
        </KTCardBody>
      </KTCard>
    </FormikProvider>
  )
}

export { EmailSettingForm }
