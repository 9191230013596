import { ID, Response } from "../../../../../_metronic/helpers/crud-helper/models";
export interface Banner {
    id?: ID
    name?: string
    uploadWebImage?: updateImage
    uploadMobileImage?: updateImage
    webImagePath?: string
    mobileImagePath?: string
    mobImagePath?: string
    wImagePath?: string
    pageId?: number
    sectionId?: number
    pageName?: string
    sectionName?: string
    shortDescription?:string
    linkUrl?:string
    title?:string
}
export type BannerQueryResponse = Response<Array<Banner>>

export type updateImage = {
    name?: string,
    extension?: string,
    data?: string
}

export const initialBanner: Banner = {
    name: '',
    webImagePath: '',
    pageId: undefined,
    sectionId: undefined,
    wImagePath:'',
    mobImagePath:'',
    mobileImagePath:'',
    

}

export type Page={
id?:number
name?:string
}

export type PageQueryResponse = Response<Array<Page>>

export type Section={
    id?:number
    name?:string
}
export type SectionQueryResponse = Response<Array<Section>>
