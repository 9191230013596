// @ts-nocheck
import {Column} from 'react-table'
import { Collection } from '../core/_models'
import { CollectionCustomHeader } from './CollectionCustomHeader'
import { CollectionActionsCell } from './CollectionActionsCell'
import { StatusCell } from '../../../../../_metronic/helpers/components/StatusCell'


const CollectionColumns: ReadonlyArray<Column<Collection>> = [
  {
    Header: (props) => <CollectionCustomHeader tableProps={props} title='Collection Name' className='min-w-125px' />,
    accessor: 'name'
  },
  {
    Header: (props) => <CollectionCustomHeader tableProps={props} title='Page Name' className='min-w-125px' />,
    id:'pageId',
    accessor: 'pageName'
  },
  {
    Header: (props) => <CollectionCustomHeader tableProps={props} title='Section Name' className='min-w-125px' />,
    id:'sectionId',
    accessor: 'sectionName'
  },

  {
    Header: (props) => <CollectionCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'isActive',
    Cell: ({...props}) => <StatusCell isActive={props.data[props.row.index].isActive} />,
  },
 
  {
    Header: (props) => (
      <CollectionCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CollectionActionsCell id={props.data[props.row.index].id} />,
  },
]

export {CollectionColumns}
