/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'
import { getPermissions, getUserByToken, login } from '../core/_requests'
import { PaginationFilter, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { getTenantList } from '../../../core/shared/core/_request'
import { Tenant } from '../../../core/shared/core/_model'
import CustomSelect from '../../../core/shared/components/CustomSelect'
import { toast } from 'react-toastify'

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
  advancedFilter: {
    field: 'isActive',
    operator: 'eq',
    value: true,
  },
}
const loginSchema = Yup.object().shape({
  username: Yup.string()
    //.email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  // tenantId: Yup.string().required('Please select location')
})

const initialValues = {
  username: '', // ho.admin@saawree.com
  password: '', // 123Pa$$word!
  tenantId: 0
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.username, values.password, values.tenantId)
        saveAuth(auth)

        const { data: user } = await getUserByToken(auth.token)
        const { data: permissions } = await getPermissions(auth.token);
        user.permissions = permissions
        setCurrentUser(user)
      } catch (error) {

        setStatus('The login details are incorrect')
        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);
      }
    },
  })
  const [storeList, setStoreList] = useState<Tenant[]>([])
  useEffect(() => {
    let result: any
    let storeArray: any[] = []
    paginationFilter.tenantTypeId = 2
    getTenantList(paginationFilter).then((v) => {
      result = v.data as Tenant[]
      result.map((item: any) => {
        return storeArray.push({ value: item.id, label: item.name })
      })
      setStoreList(storeArray)
    })
  }, [])
  const [showGeneratedPassword, setShowGeneratedPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowGeneratedPassword(!showGeneratedPassword);
  };
  return (
    <FormikProvider value={formik}>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Store Panel Login</h1>
        </div>

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          ''
        )}

        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark required'>Location</label>
          <Field
            {...formik.getFieldProps('tenantId')}
            className={clsx('form-select-solid',
              // {
              //   'is-invalid': formik.touched.tenantId && formik.errors.tenantId,
              // },
              // {
              //   'is-valid': formik.touched.tenantId && !formik.errors.tenantId,
              // }
            )}
            options={storeList}
            component={CustomSelect}
          >
          </Field>
          {/* {formik.touched.tenantId && formik.errors.tenantId && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.tenantId}</span>
            </div>
          )} */}
        </div >
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='UserName'
            {...formik.getFieldProps('username')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.username && formik.errors.username },
              {
                'is-valid': formik.touched.username && !formik.errors.username,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
            onChange={(e) => formik.setFieldValue('username', e.target.value)}
          />
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.username}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          {/* <input
            type='password'

            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            onChange={(e) => formik.setFieldValue('password', e.target.value)}

          /> */}

          <div className="input-group">
            <input
              placeholder="password"
              {...formik.getFieldProps("password")}
              type={showGeneratedPassword ? "text" : "password"}
              name="password"
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              onChange={(e) => formik.setFieldValue('password', e.target.value)}

            />
            <div className="input-group-append">
              <span
                className={clsx(
                  "ki-duotone cursor-pointer position-absolute translate-middle top-50 end-0 fs-1",
                  showGeneratedPassword ? "ki-eye-slash" : "ki-eye"
                )}
                onClick={handleTogglePasswordVisibility}
              >
                <span className="path1"></span><span className="path2"></span><span className="path3"></span>
              </span>
            </div>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='link-primary'>
            Forgot Password ?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Login</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}

      </form>
    </FormikProvider>
  )
}
