import { ID, Response } from "../../../../../_metronic/helpers/crud-helper/models";
export interface SEO {
    id?: ID
    
    pageId?: number
    metaTitle?: string
    metaDescription?: string
    metaKeywords?: string
    googleTagManager?: string

}
export type SEOQueryResponse = Response<Array<SEO>>



export const initialSEO: SEO = {
   pageId:undefined,
   metaDescription:'',
   metaKeywords:'',
   metaTitle:'',
   googleTagManager:''

}

export type Page = {
    id?: number
    name?: string
}

export type PageQueryResponse = Response<Array<Page>>

