
import { KTCard } from '../../../../_metronic/helpers'
import NewArrivalListHeader from './components/header/NewArrivalListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { NewArrivalTable } from './newarrival-table/NewArrivalTable'



const NewArrivalList = () => {
 
  return (
    <>
      <KTCard>
       <NewArrivalListHeader/>
       <NewArrivalTable /> 
      </KTCard>
    </>
  )
}

const NewArrivalListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <NewArrivalList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {NewArrivalListWrapper}
