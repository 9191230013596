// @ts-nocheck
import {Column} from 'react-table'

import { Products } from '../core/_models'

import { StatusCell } from '../../../../../../_metronic/helpers/components/StatusCell'
import { ProductCustomHeader } from './ProductCustomHeader'
import { ProductGroupActionsCell } from './ProductGroupActionsCell'
const ProductsColumns: ReadonlyArray<Column<Products>> = [
  {
    Header: (props) => <ProductCustomHeader tableProps={props} title='ProductGroup Name' className='min-w-125px' />,
    accessor: 'name'
  },
  {
    Header: (props) => <ProductCustomHeader tableProps={props} title='Design Number' className='min-w-125px' />,
    id:'designNumberId',
    accessor: 'designNumber'
  },
  {
    Header: (props) => <ProductCustomHeader tableProps={props} title='Category' className='min-w-125px' />,
    accessor: 'categoryName',
    id:'category.name'
    
  },
    {
    Header: (props) => <ProductCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'isActive',
    Cell: ({...props}) => <StatusCell isActive={props.data[props.row.index].isActive} />,
  },
    {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProductGroupActionsCell id={props.data[props.row.index].id} />,
  },
]

export {ProductsColumns}
