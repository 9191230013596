import React from 'react'
import PermissionsPage from './PermissionsPage'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { QUERIES } from '../../../../../_metronic/helpers'
import { getRoleById } from '../core/_requests'

const PermissionWrapper = () => {
  const{id}=useParams()
    const {
        isLoading,
        data: result,
        error,
      } = useQuery(
        `${QUERIES.ROLE_LIST}-${id}`,
        async () => {
          return await getRoleById(id)
        },
        {
          cacheTime: 0,
          enabled: true,
          onError: (err) => {
          
            console.error(err)
          },
        }
      )
      if (!isLoading && !error && result) {
        return <PermissionsPage isRoleLoading={isLoading} data={result} />
      }
    
      return null
}

export default PermissionWrapper