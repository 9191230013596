// @ts-nocheck
import {Column} from 'react-table'

import { StatusCell } from '../../../../../_metronic/helpers/components/StatusCell'
import { NewArrival } from '../core/_models'
import { NewArrivalCustomHeader } from './NewArrivalCustomHeader'
import { NewArrivalActionsCell } from './NewArrivalActionsCell'


const CollectionColumns: ReadonlyArray<Column<NewArrival>> = [
  {
    Header: (props) => <NewArrivalCustomHeader tableProps={props} title='New Arrival Name' className='min-w-125px' />,
    accessor: 'name'
  },
 

  {
    Header: (props) => <NewArrivalCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'isActive',
    Cell: ({...props}) => <StatusCell isActive={props.data[props.row.index].isActive} />,
  },
 
  {
    Header: (props) => (
      <NewArrivalCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <NewArrivalActionsCell id={props.data[props.row.index].id} />,
  },
]

export {CollectionColumns}
