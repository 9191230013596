import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from '../../../../../_metronic/helpers/crud-helper/models'
import { ExpenseCategory, ExpenseCategoryQueryResponse } from './_models'
import { Result } from '../../../../core/models/Result'

const API_URL = process.env.REACT_APP_STORE_API_URL
const EXPENSECATEGORY_BY_ID_URL = `${API_URL}/expensecategory`
const EXPENSECATEGORY_LIST_URL = `${API_URL}/expensecategory/search`
const EXPENSECATEGORY_ADD_URL = `${API_URL}/expensecategory`

const getExpenseCategoryList = async (filter: PaginationFilter): Promise<ExpenseCategoryQueryResponse> => {
  return await axios
    .post(`${EXPENSECATEGORY_LIST_URL}`, filter)
    .then((d: AxiosResponse<ExpenseCategoryQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getExpenseCategoryById = async (id: ID): Promise<Result | ExpenseCategory> => {
  return await axios
    .get(`${EXPENSECATEGORY_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<ExpenseCategory>) => response.data)
    .then((response: ExpenseCategory) => response)
    .catch((err: Result) => {
      return err
    })
}

const createExpenseCategory = async (expenseCategory: ExpenseCategory): Promise<Result> => {
  return await axios
    .post(EXPENSECATEGORY_ADD_URL, {
      name: expenseCategory.name,
      description: expenseCategory.description,
     
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateExpenseCategory = async (expenseCategory: ExpenseCategory): Promise<Result> => {
  return await axios
    .put(`${EXPENSECATEGORY_BY_ID_URL}/${expenseCategory.id}`, {
      id: expenseCategory.id,
      name: expenseCategory.name,      
      description: expenseCategory.description,    
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
export {
  getExpenseCategoryList, getExpenseCategoryById, createExpenseCategory, updateExpenseCategory
}
