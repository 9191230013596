import { useParams } from 'react-router-dom'
import { isNotEmpty } from '../../../../../_metronic/helpers/crud-helper/helpers';
import { useQuery } from 'react-query';
import { QUERIES } from '../../../../../_metronic/helpers/crud-helper/consts';
import { getInvoiceById, } from '../core/_requests';
import { InvoiceDetailForm } from './InvoiceDetailForm';


const InvoiceDetailPage = () => {
    const { id } = useParams();
    const enabledQuery: boolean = isNotEmpty(id)
    const {
        isLoading,
        data: result,
        error,
    } = useQuery(
        [`${QUERIES.INVOICE}-${id}`],
        async () => {
            return await getInvoiceById(id)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {

                console.error(err)
            },
        }
    )
    if (!isLoading && !error && result) {
        return <InvoiceDetailForm  data={result} />
    }
    return null
}
export { InvoiceDetailPage }
