import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { POSAddPage } from './pages/POSAddPage'


const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const POSPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='add'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add POS</PageTitle>
              <POSAddPage />
            </>
          }
        />
        
        {/* <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Sales Order</PageTitle>
              <POSAddPage />            
            </>
          }
        /> */}
       
      </Route>
    </Routes>
  )
}
export default POSPage
