import { NewArrivalListSearchComponent } from "./NewArrivalListSearchComponent"
import { NewArrivalListToolbar } from "./NewArrivalListToolbar"


const NewArrivalListHeader = () => {

  return (
    <div className='card-header border-0 pt-6'>
      <NewArrivalListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <NewArrivalListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default NewArrivalListHeader
