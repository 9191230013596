// @ts-nocheck
import {Column} from 'react-table'
import { SEO } from '../core/_models'
import { SEOCustomHeader } from './SEOCustomHeader'
import { SEOActionsCell } from './SEOActionsCell'

const BrandColumns: ReadonlyArray<Column<SEO>> = [
  {
    Header: (props) => <SEOCustomHeader tableProps={props} title='Page Name' className='min-w-125px' />,
    id:'pageId',
    accessor: 'pageName'
  },
  {
    Header: (props) => <SEOCustomHeader tableProps={props} title='Meta Title' className='min-w-125px' />,
    accessor: 'metaTitle'
  },
  {
    Header: (props) => <SEOCustomHeader tableProps={props} title='Meta Title' className='min-w-125px' />,
    accessor: 'metaKeywords'
  },
 
  {
    Header: (props) => <SEOCustomHeader tableProps={props} title='GoogleTag Manager' className='min-w-125px' />,
    
    accessor: 'googleTagManager'
  },
 
  {
    Header: (props) => (
      <SEOCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SEOActionsCell id={props.data[props.row.index].id} />,
  },
]

export {BrandColumns}
