import { Link, Navigate, useNavigate } from 'react-router-dom'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { AgentListFilter } from './AgentListFilter'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils'
const AgentListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const navigate = useNavigate()
  const openAddUserModal = () => {
    navigate(`/sales/agent/add`)
  }

  return (
    <div className="d-flex align-items-center gap-2 gap-lg-3">
      <div className="m-0">
        {/* <AgentListFilter /> */}
      </div>
      {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.Create) ?

        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTIcon iconName='plus' className='fs-2' />
          Add
        </button>
        : null}
    </div>
  )
}

export { AgentListToolbar }
