import { lazy, FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../../_metronic/helpers'

import { MustHavePermission, SWSection, SWResource, SWAction } from '../../core/shared/core/permissionUtils'
import UnAuthorized from '../errors/components/UnAuthorized'
import BannerPage from './banner/BannerPage'
import SEOPage from './seo/SEOPage'
import TestimonialPage from './testimonials/TestimonialPage'
import CollectionPage from './collections/CollectionPage'
import NewArrivalPage from './new-arrival/NewArrivalPage'

const CMSRoutes = () => {
  // const CustomerPage = lazy(() => import('./customer/CustomerPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='banner/*'
          element={
            <SuspensedView>
              {/* {MustHavePermission(SWSection.Store, SWResource.Customers, SWAction.Search) ? */}
              <BannerPage />
              {/* : <UnAuthorized />} */}
            </SuspensedView>
          }
        />

        <Route
          path='seo/*'
          element={
            <SuspensedView>
              {/* {MustHavePermission(SWSection.Store, SWResource.Customers, SWAction.Search) ? */}
              <SEOPage />
              {/* : <UnAuthorized />} */}
            </SuspensedView>
          }
        />

        <Route
          path='testimonial/*'
          element={
            <SuspensedView>
              {/* {MustHavePermission(SWSection.Store, SWResource.Customers, SWAction.Search) ? */}
              <TestimonialPage />
              {/* : <UnAuthorized />} */}
            </SuspensedView>
          }
        />


        <Route
          path='collection/*'
          element={
            <SuspensedView>
              {/* {MustHavePermission(SWSection.Store, SWResource.Customers, SWAction.Search) ? */}
              <CollectionPage />
              {/* : <UnAuthorized />} */}
            </SuspensedView>
          }
        />

        <Route
          path='newarrival/*'
          element={
            <SuspensedView>
              {/* {MustHavePermission(SWSection.Store, SWResource.Customers, SWAction.Search) ? */}
              <NewArrivalPage />
              {/* : <UnAuthorized />} */}
            </SuspensedView>
          }
        />

      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { CMSRoutes }
