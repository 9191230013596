import axios, { AxiosResponse } from 'axios'
import {  SalesOrder, SalesOrderQueryResponse } from './_models'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"
import { Result } from '../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const SalesOrder_BYID_URL = `${API_URL}/saleorders`
const SalesOrder_ADD_URL = `${API_URL}/saleorders`
const SalesOrder_LIST_URL = `${API_URL}/saleorders/search`

const getSalesOrderList = (filter: PaginationFilter): Promise<SalesOrderQueryResponse> => {
  return axios
    .post(`${SalesOrder_LIST_URL}`, filter)
    .then((d: AxiosResponse<SalesOrderQueryResponse>) => {
    
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getSalesOrderById = async (id: ID): Promise<Result | SalesOrder> => {
  return await axios
    .get(`${SalesOrder_BYID_URL}/${id}`)
    .then((response: AxiosResponse<SalesOrder>) => response.data)
    .then((response: SalesOrder) => response)
    .catch((err: Result) => {
      return err
    })
}
const createSalesOrder = async (salesOrder: SalesOrder): Promise<Result> => {
  return await axios
    .post(SalesOrder_ADD_URL, {
      customerId: salesOrder.customerId,
      orderDate: salesOrder.orderDate,
      dueDate:salesOrder.dueDate,
      discountValue:salesOrder.discountValue,
      expectedShipmentDate: salesOrder.expectedShipmentDate,
      notes: salesOrder.notes,
      adjustment: salesOrder.adjustment,
      subTotal: salesOrder.subTotal,
      roundOff: salesOrder.roundOff,
      orderTotal: salesOrder.orderTotal,
      itemList: salesOrder.itemList
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateSalesOrder = (salesOrder: SalesOrder): Promise<Result> => {
  return axios
    .put(`${SalesOrder_BYID_URL}/${salesOrder.id}`, {
      id: salesOrder.id,
      customerId: salesOrder.customerId,
      orderDate: salesOrder.orderDate,
      dueDate:salesOrder.dueDate,
      expectedShipmentDate: salesOrder.expectedShipmentDate,
      notes: salesOrder.notes,
      adjustment: salesOrder.adjustment,
      subTotal: salesOrder.subTotal,
      roundOff: salesOrder.roundOff,
      orderTotal: salesOrder.orderTotal,
      itemList: salesOrder.itemList
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

export {
  getSalesOrderById,
  getSalesOrderList,
  createSalesOrder,
  updateSalesOrder,
 
}
