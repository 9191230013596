
import { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon, PaginationFilter } from '../../../../../_metronic/helpers'
import { ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { format } from 'date-fns';
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {  InvoiceDto } from '../core/_models'
import { getInvoiceList, invoiceGeneratePdf } from '../core/_requests'
import { useNavigate } from 'react-router-dom'
import { FileResult } from '../../../../core/models/Result'
import { saveAs } from 'file-saver';
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'
import { formatCurrencyInINR } from '../../../../core/models/CurrencyFormatter'
import InvoiceAdvancedSearch from '../components/header/InvoiceAdvancedSearch'
import { useQuery } from 'react-query'
 
// const paginationFilter: PaginationFilter   = {
//   pageNumber: 1,
//   pageSize: 500,
// }

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
  advancedFilter: {
    logic: 'and',
    filters: [{

      field: "isActive",
      operator: "eq",
      value: true,

    },]
  }
};
const InvoiceList = () => {
  const [invoice, setInvoice] = useState<InvoiceDto[]>([])
  const [filters, setFilters] = useState({});

  const { data: invoiceResponse, isLoading ,refetch} = useQuery(['invoice', filters], () => {
    return getInvoiceList({  ...filters });
  });

  useEffect(() => {
    if (invoiceResponse?.data) {
      setInvoice(invoiceResponse.data);
    }
  }, [invoiceResponse]);

  const handleSearch = (searchParams) => {
    const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: searchParams.advancedFilter
    };
    setFilters(updatedPaginationFilter);
    refetch()
  };


  const openAddAccountModal = () => {
    navigate('/sales/invoice/add')
  }

  const invoicepdf = (id) => {

    invoiceGeneratePdf(id).then(file => {
      let output = file as FileResult;
      let url = window.URL
        .createObjectURL(output.data);
      saveAs(url, output.name);
    });

  }

  const navigate = useNavigate()
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">

        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Actions
          <KTIcon iconName='down' className='fs-5 m-0' />
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
          data-kt-menu='true'
        >
          {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Update) ?

            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={() => navigate(`/sales/invoice/edit/${rowData.id}`)}>
                Edit
              </a>
            </div>
            : null}
          {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.View) ?

            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={() => navigate(`/sales/invoice/detail/${rowData.id}`)}>
                Detail
              </a>
            </div>
            : null}

        </div>
      </div>
    );
  };
  const formatDateColumn = (rowData: any, dateFormat: string) => {
    const date = new Date(rowData);
    return format(date, dateFormat);
  };

  const formatAmount = (rowData: InvoiceDto) => {
    return formatCurrencyInINR(rowData.orderTotal)
  }



  return (
    <>
      <InvoiceAdvancedSearch onSearch={handleSearch} />
      <KTCard>
        <KTCardBody>
          <>
            <div className="d-flex justify-content-end mt-4">
              <div className="m-0">
              </div>
              {/* {MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Create) ?

                <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
                  <KTIcon iconName='plus' className='fs-2' />
                  Add
                </button>
                : null} */}
            </div>
            <div>
              <DataTable

                className='table align-middle table-row-dashed gy-5 dataTable no-footer'
                value={invoice}
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'
                paginator
                rows={10}
                rowsPerPageOptions={[10,20,50]}
                //lazy
              >

                <Column
                  className='text-gray-600 fw-bold fs-6'

                  field='invoiceNumber'
                  header={
                    <div className='text-start text-muted fw-bolder fs-7 gs-0 min-w-125px'>
                      INVOICE NUMBER
                    </div>
                  }
                  sortable
                />
                <Column

                  className='text-gray-600 fw-bold fs-6'
                  field='customerName'
                  header={
                    <div className='text-start text-muted fw-bolder fs-7 gs-0 min-w-125px'>
                      CUSTOMER
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                />

                <Column

                  className='text-gray-600 fw-bold fs-6'
                  field='orderTotal'
                  header={
                    <div className='text-start text-muted fw-bolder fs-7 gs-0 min-w-125px'>
                      ORDER TOTAL
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                  body={(rowData) => formatAmount(rowData)}
                />


                <Column
                  className='text-gray-600 fw-bold fs-6'
                  field='invoiceDate'
                  header={
                    <div className='text-start text-muted fw-bolder fs-7 gs-0 min-w-125px'>
                      INVOICE DATE
                    </div>
                  }
                  body={(rowData) => formatDateColumn(rowData.invoiceDate, 'dd MMM yyyy')}
                  sortable
                />
                <Column
                  className='text-gray-600 fw-bold fs-6'
                  field='dueDate'
                  header={
                    <div className='text-start text-muted fw-bolder fs-7 gs-0 min-w-125px'>
                      DUE DATE
                    </div>
                  }
                  body={(rowData) => formatDateColumn(rowData.dueDate, 'dd MMM yyyy')}


                  sortable
                />
                <Column

                  className='text-gray-600 fw-bold fs-6'
                  field='invoiceStatusName'
                  header={
                    <div className='text-start text-muted fw-bolder fs-7 gs-0 min-w-125px'>
                      STATUS
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                />
                <Column
                className='text-gray-600 fw-bold fs-6' 
                field="" 
                header={
                  <div className='text-end text-muted fw-bolder fs-7 gs-0 min-w-125px w-100'>
                    ACTIONS
                  </div>
                } body={actionBodyTemplate} />
              </DataTable>
            </div>
          </>
        </KTCardBody>
      </KTCard>

    </>
  )
}

const InvoiceWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <InvoiceList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { InvoiceWrapper }
