import { FC } from 'react'
import { Result } from '../../../../core/models/Result'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import {  updateAutoNumberGeneration } from '../../core/_requests'
import { AutoNumberGenerationDto, AutoNumberGenerationGroupDto } from '../../core/_models'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'

type Props = {
  data: Result | AutoNumberGenerationGroupDto
}

const autoNumberGenerationGroupDtoSchema = Yup.object().shape({
  name: Yup.string().required('Please enter name'),
  id: Yup.number(),
})
const SeriesEditPageForm: FC<Props> = ({ data }) => {
  const angDetail = data as AutoNumberGenerationGroupDto
  const initialValues: AutoNumberGenerationGroupDto = {
    id: angDetail.id,
    name: angDetail.name,
    isDefault: true
  }

  let autoNumberGenerationList: AutoNumberGenerationDto[] = []

  if (
    angDetail.autoNumberGenerationDtos != undefined &&
    angDetail.autoNumberGenerationDtos?.length > 0
  ) {
    angDetail.autoNumberGenerationDtos.map((item, index) => {
      autoNumberGenerationList.push({
        rowNumber: index,
        id: item.id,
        typeName: item.typeName,
        formattedTypeName: item.formattedTypeName,
        autoNumberGenerationGroupId: item.autoNumberGenerationGroupId,
        prefixString: item.prefixString,
        nextNumber: item.nextNumber,
      })
    })
  }
  const [editedAutoNumber, setEditedAutoNumber] =
    useImmer<AutoNumberGenerationDto[]>(autoNumberGenerationList)

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    //validationSchema: autoNumberGenerationGroupDtoSchema,
    onSubmit: async (formValues, { setSubmitting }) => {
      setSubmitting(true)

      let autoNumberGenerationDtos: AutoNumberGenerationGroupDto = {
        id: formValues.id,
        name: formValues.name,
        autoNumberGenerationDtos: editedAutoNumber,
      }
      let result: Result
      result = await updateAutoNumberGeneration(autoNumberGenerationDtos)
      toast.success("Data Updated Successfully.")
    },
  })

  return (
    <FormikProvider value={formik}>
      <KTCard>
        <KTCardBody>
          <form
            id='kt_modal_add_product_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div>
              <div className='row'>
                <div className='col-4'><label className='form-label'>Series Name</label></div>

                <div className='col-4'><label className='form-label'>Prefix</label></div>
                <div className='col-4'><label className='form-label'>Next Number</label></div></div>

              {editedAutoNumber.map((i, index) => (
                <div className='row mb-5' key={index}>
                  <div className='col'>
                    <h6 className='d-flex w-25 me-5'>{i.formattedTypeName}</h6>
                  </div>

                  <div className='col'>

                    <Field
                      type='text'
                      className={clsx('form-control')}
                      onChange={(e) => {
                        setEditedAutoNumber((series) => {
                          series[i.rowNumber].prefixString = e.target.value
                        })
                      }}
                      name={`editedAutoNumber[${i.rowNumber}].prefixString`}
                      value={editedAutoNumber[i.rowNumber].prefixString}
                    />
                  </div>

                  <div className='col'>

                    <Field
                      type='number'
                      className={clsx('form-control')}
                      onChange={(e) => {
                        setEditedAutoNumber((series) => {
                          series[i.rowNumber].nextNumber = parseInt(e.target.value)
                        })
                      }}
                      name={`editedAutoNumber[${i.rowNumber}].nextNumber`}
                      value={editedAutoNumber[i.rowNumber].nextNumber}
                    />
                  </div>

                </div>
              ))}
            </div>
            {MustHavePermission(SWSection.Store, SWResource.AutoNumberGenerationGroups, SWAction.Update) ?
            <button type='submit' className='btn btn-primary '>
              Save
            </button>
            :null}
          </form>
        </KTCardBody>
      </KTCard>
    </FormikProvider>
  )
}

export { SeriesEditPageForm }
