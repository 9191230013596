import { FC } from 'react'
import { Result } from '../../../../core/models/Result'

import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import { getAutoNumberGeneration, updateAutoNumberGeneration, updateCreditNote, updateCustomerVendor } from '../../core/_requests'
import { AutoNumberGenerationDto, AutoNumberGenerationGroupDto, CreditNoteSetting, CustomerVendor } from '../../core/_models'
import { useImmer } from 'use-immer'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import CustomAsyncSelect from '../../../../core/shared/components/CustomAsyncSelect'
import { Customer } from '../../../sales/customer/customer-list/core/_models'
import { getCustomerList } from '../../../sales/customer/customer-list/core/_requests'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'

type Props = {
  data: Result | CustomerVendor
}

const customerVendorSchema = Yup.object().shape({
  // customerVendorBillingFormat: Yup.string().required('Please enter Billing format.'),
  // customerVendorShippingFormat: Yup.string().required('Please enter Shipping Format.'),
  defaultCustomerType: Yup.string().required('Please select Customer type.'),
  defaultCustomerId: Yup.string().required('Please select Customer.')
})
const CustomerVendorForm: FC<Props> = ({ data }) => {
  const customerDetail = data as CustomerVendor
  const initialValues: CustomerVendor = {
    id: customerDetail.id,
    isDuplicateCustomerVendorName: customerDetail.isDuplicateCustomerVendorName,
    defaultCustomerType: customerDetail.defaultCustomerType,
    customerVendorBillingFormat: customerDetail.customerVendorBillingFormat,
    customerVendorShippingFormat: customerDetail.customerVendorShippingFormat,
    defaultCustomerId: customerDetail.defaultCustomerId
  }

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    validationSchema: customerVendorSchema,
    onSubmit: async (formValues, { setSubmitting }) => {
      setSubmitting(true)

      let customerVendor: CustomerVendor = {
        id: formValues.id,
        isDuplicateCustomerVendorName: formValues.isDuplicateCustomerVendorName,
        defaultCustomerType: formValues.defaultCustomerType,
        customerVendorBillingFormat: formValues.customerVendorBillingFormat,
        customerVendorShippingFormat: formValues.customerVendorShippingFormat,
        defaultCustomerId: formValues.defaultCustomerId
      }
      let result: Result
      result = await updateCustomerVendor(customerVendor)
      toast.success("Customer Vendor Updated Successfully.")
    },
  })



  return (
    <FormikProvider value={formik}>
      <KTCard>
        <KTCardBody>
          <form
            id='kt_modal_add_customervendor_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >

            <div className='row mb-7'>
              <div className='col-6 col-md-6 mb-4'>
                <label className="d-flex align-items-center fs-6 fw-bold mb-2 required ms-1">Customer Name</label>
                <Field
                  className='form-select-solid'
                  component={CustomAsyncSelect<Customer>}
                  {...formik.getFieldProps(`defaultCustomerId`)}
                  name={`defaultCustomerId`}
                  loadOptionFunc={getCustomerList}
                  searchByField="printName"
                  labelField="printName"
                  onChange={(newValue: any) => {
                    formik.setFieldValue('defaultCustomerId', newValue?.value)
                  }}
                ></Field>

                {formik.touched.defaultCustomerId && formik.errors.defaultCustomerId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.defaultCustomerId}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6 col-md-6 mb-4'>
                <label className="d-flex align-items-center fs-6 fw-bold mb-2 required ms-1">Customer Type</label>
                <Field
                  className={clsx(
                    'form-select-solid ms-1',
                    { 'is-invalid': formik.touched.defaultCustomerType && formik.errors.defaultCustomerType },
                    {
                      'is-valid': formik.touched.defaultCustomerType && !formik.errors.defaultCustomerType,
                    }
                  )}
                  {...formik.getFieldProps('defaultCustomerType')}

                  name="defaultCustomerType"

                  options={[
                    { value: 1, label: 'Semi Wholeseller' },
                    { value: 2, label: 'Retail' },
                    { value: 3, label: 'WholeSeller' },
                  ]}

                  onDropDownChange={(e: { value: any }) => formik.setFieldValue('defaultCustomerType', e?.value)}
                  component={CustomSelect}
                  placeholder="Select Parent Group"
                  isMulti={false}
                ></Field>
              </div>
            </div>
            <div className='row mb-7'>
              <div className='col-6 col-md-6 mb-4'>
                <label className='fw-bold fs-6 mb-2 ms-1'>Duplicate Customer VendorName</label>

                <div className='form-check form-check-custom form-check-solid ms-1'>

                  <input
                    className='form-check-input ms-1'
                    type='checkbox'
                    {...formik.getFieldProps('isDuplicateCustomerVendorName')}
                    checked={{ ...formik.getFieldProps('isDuplicateCustomerVendorName') }.value}


                  /><span className='ms-4'>Duplicate Customer Vendor</span>
                </div>
                {formik.touched.isDuplicateCustomerVendorName && formik.errors.isDuplicateCustomerVendorName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.isDuplicateCustomerVendorName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className='mb-7 row '>
              <div className='col-6 col-md-6'>
                <label className='form-label'>Customer Vendor Billing Format</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.customerVendorBillingFormat && formik.errors.customerVendorBillingFormat },
                    {
                      'is-valid': formik.touched.customerVendorBillingFormat && !formik.errors.customerVendorBillingFormat,
                    }
                  )}
                  placeholder='product customerVendorBillingFormat'
                  {...formik.getFieldProps('customerVendorBillingFormat')}
                  name='customerVendorBillingFormat'
                />
                {formik.touched.customerVendorBillingFormat && formik.errors.customerVendorBillingFormat && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.customerVendorBillingFormat}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6 col-md-6'>
                <label className='form-label'>Customer Vendor Shipping Format</label>
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.customerVendorShippingFormat && formik.errors.customerVendorShippingFormat },
                    {
                      'is-valid': formik.touched.customerVendorShippingFormat && !formik.errors.customerVendorShippingFormat,
                    }
                  )}
                  placeholder='product customerVendorShippingFormat'
                  {...formik.getFieldProps('customerVendorShippingFormat')}
                  name='customerVendorShippingFormat'
                />
                {formik.touched.customerVendorShippingFormat && formik.errors.customerVendorShippingFormat && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.customerVendorShippingFormat}</span>
                    </div>
                  </div>
                )}
              </div>
            </div> */}

            {MustHavePermission(SWSection.Store, SWResource.CustomerVendorSettings, SWAction.Update) ?
              <button type='submit' className='btn btn-primary '>
                Save
              </button>
              : null}
          </form>
        </KTCardBody>
      </KTCard>
    </FormikProvider>
  )
}

export { CustomerVendorForm }
