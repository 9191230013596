import {format} from 'date-fns'

export const formatDate = (rowData: any, dateFormat: string) => {
    const date = new Date(rowData);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
        console.error('Invalid date:', rowData);
        return 'Invalid date';
    }
    return format(date, dateFormat);
 
};