import {useParams} from 'react-router-dom'
import {isNotEmpty} from '../../../../../_metronic/helpers/crud-helper/helpers'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers/crud-helper/consts'
import React, {BaseSyntheticEvent, useEffect, useState} from 'react'
import {getAutoNumberGeneration, updateAutoNumberGeneration} from '../../core/_requests'

import {SeriesEditPageForm} from './SeriesEditPageForm'



export const SeriesEditPage = () => {


  const {isLoading, data: result, error: error} = useQuery([`${QUERIES.AUTONUMBERGENERATION}`], async () => {
    return await getAutoNumberGeneration()
  })
  
 if (!isLoading && !error && result) {

    return <SeriesEditPageForm data={result} />
  }
  return (
  
    null
  )
}

