import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { AgentAddPage } from './agent-pages/AgentAddPage'
import { AgentWrapper } from './AgentList'
import { AgentFormWrapper } from './agent-pages/AgentFormWrapper'
import { AgentDetailWrapper } from './agent-pages/AgentDetailWrapper'
import AgentOverview from './agent-pages/AgentOverview'
import AgentProfile from './agent-pages/AgentProfile'
import AgentCommissionPage from './agent-pages/AgentCommissionPage'
import AgentPayoutPage from './agent-pages/AgentPayoutPage'
import { PaymentForm } from './agent-pages/payment/PaymentForm'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../core/shared/core/permissionUtils'
import UnAuthorized from '../../errors/components/UnAuthorized'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AgentPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Agent</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.Search) ?
              <AgentWrapper />
              :<UnAuthorized/>}
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Edit Agent</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.Update) ?
              <AgentFormWrapper />
              :<UnAuthorized/>}
            </>
          }
        />
        <Route
          path='detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}> Agent Details</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.View) ?
              <AgentDetailWrapper />
              :<UnAuthorized/>}

            </>
          }
        >

          <Route
            path='overview/*'
            index
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Agent Overview</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.View) ?
                <AgentOverview />
                :<UnAuthorized/>}
              </>
            }
          />

          <Route
            path='profile/*'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Agent Profile</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.View) ?
                <AgentProfile />
                :<UnAuthorized/>}
              </>
            }
          />

          <Route
            path='commission/*'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Agent Commission</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.View) ?
                <AgentCommissionPage />:
                <UnAuthorized/>}
              </>
            }
          />

          <Route
            path='payout/*'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Agent Payout</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.View) ?
                <AgentPayoutPage />
                :<UnAuthorized/>}
              </>
            }
          />
          <Route
            path='payment/*'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Agent Payout</PageTitle>
                <PaymentForm />
              </>
            }
          />

        </Route>
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Agent</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.Create) ?
              <AgentAddPage isAgentLoading={false} />
              :<UnAuthorized/>}
            </>
          }
        />
      </Route>

    </Routes>
  )
}

export default AgentPage
