import axios, {AxiosResponse} from 'axios'

import {ID, PaginationFilter} from '../../../../../../_metronic/helpers/crud-helper/models'
import {Result} from '../../../../../core/models/Result'
import {Payment, PaymentQueryResponse} from './_model'

const API_URL = process.env.REACT_APP_STORE_API_URL
const PAYMENT_LIST_URL = `${API_URL}/purchaseorders/purchaseorderpayment/search`
const PAYMENT_BY_ID_URL=`${API_URL}/purchaseorders/purchaseorderpayment`
const getPaymentList = (filter: PaginationFilter): Promise<PaymentQueryResponse> => {
  return axios

    .post(`${PAYMENT_LIST_URL}`, filter)
    .then((d: AxiosResponse<PaymentQueryResponse>) => {
          return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getPaymentById = async (id: ID): Promise<Result | Payment> => {
  return await axios
    .get(`${PAYMENT_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Payment>) => response.data)
    .then((response: Payment) => response)
    .catch((err: Result) => {
      return err
    })
}
export {getPaymentList,getPaymentById}
