import { Route, Routes, Outlet } from 'react-router-dom'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../core/shared/core/permissionUtils'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import {  ExpenseWrapper } from './pages/ExpenseList'
import UnAuthorized from '../../errors/components/UnAuthorized'
import { ExpenseDetailPage } from './pages/ExpenseDetailPage'

const Breadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ExpensePage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='list'
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Expense  </PageTitle>
                            {MustHavePermission(SWSection.Store, SWResource.Expenses, SWAction.Search) ?
                            <ExpenseWrapper />
                            :<UnAuthorized/>}
                        </>
                    }
                />    

                <Route
                    path='detail/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}> Expense Details</PageTitle>
                            <ExpenseDetailPage />
                        </>
                    }
                />         

            </Route>
        </Routes>
    )
}

export default ExpensePage
