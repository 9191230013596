/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useNavigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {CatalogRoutes} from '../modules/catalog/CatalogRoutes'
import {SalesRoutes} from '../modules/sales/SalesRoutes'
import {SettingRoutes} from '../modules/settings/SettingRoutes'
import {PurchaseRoutes} from '../modules/purchases/PurchaseRoutes'
import {ReportRoutes} from '../modules/reports/ReportsRoutes'
import { setupInterceptorsTo } from '../core/Interceptors/AxiosInterceptors'
import axios from 'axios'
import { CMSRoutes } from '../modules/cms/CMSRoutes'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

function NavigateFunctionComponent() {
  let navigate = useNavigate();
  const { saveAuth, logout } = useAuth();
  const [ran, setRan] = useState(false);

  {/* only run setup once */ }
  if (!ran) {
    setupInterceptorsTo(axios, navigate, saveAuth, logout);
    setRan(true);
  }
  return <></>;
}

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      {<NavigateFunctionComponent />}
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
              <Route path='/catalog/*' element={<CatalogRoutes />} />
              <Route path='/sales/*' element={<SalesRoutes />} />
              <Route path='/settings/*' element={<SettingRoutes />} />
              <Route path='/purchase/*' element={<PurchaseRoutes />} />
              <Route path='/reports/*' element={<ReportRoutes />} />
              <Route path='/cms/*' element={<CMSRoutes />} />

           
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
