import React, { FC } from 'react'
import { FileResult, Result } from '../../../../core/models/Result';
import { Payment } from './core/_model';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { formatCurrencyInINR } from '../../../../core/models/CurrencyFormatter';

type Props = {
  isPaymentLoading: boolean;
  data: Result | Payment;
};
const PaymentDetailPage: FC<Props> = ({ isPaymentLoading, data }) => {
  const paymentData = data as Payment;
  const formatDate = (rowData: any, dateFormat: string) => {
    const date = new Date(rowData);
    return format(date, dateFormat);
  };
  const navigate = useNavigate()
  const API_URL = process.env.REACT_APP_STORE_API_URL
  const GENERATE_PDF_URL = `${API_URL}/saleorders/saleorderpayment/downloadpdf`

  const priceListGeneratePdf = async (id: number): Promise<Result | FileResult> => {
    return await axios
      .get(`${GENERATE_PDF_URL}/${id}`, {
        responseType: 'blob'
      },)
      .then((response: AxiosResponse<any>) => {
        let filename = response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          .replace('filename=', '')
          .trim();

        var result: FileResult = {
          data: response.data,
          name: filename
        }

        return result
      })
      .catch((err: Result) => {
        return err
      })
  }

  const priceListpdf = (soId) => {
    priceListGeneratePdf(soId).then(file => {
      let output = file as FileResult;
      let url = window.URL
        .createObjectURL(output.data);
      saveAs(url, output.name);
    });

  }
  return (
    <>
      <div className='d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-primary me-3 mb-4'
          onClick={() => { priceListpdf(paymentData.id); }}
        >
          Download Pdf
        </button>
      </div>
      <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 mb-4">
        <div className="card card-flush flex-row-fluid">
          <div className='card-header'>
            <div className="card-title">

              <h2>Payment Details (#{paymentData.paymentNumber ? paymentData.paymentNumber : ' -'})</h2>

            </div>
          </div>

          <div className="card-body pt-0">
            <div className="table-responsive">

              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody className="fw-semibold text-gray-600">
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-calendar fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>{" "}
                        Date Added
                      </div>
                    </td>


                    <td className="fw-bold text-end">
                      {paymentData.paymentDate ? formatDate(paymentData.paymentDate, 'dd MMM yyyy') : 'No Date available'}

                    </td>
                  </tr>

                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-wallet fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </i>{" "}
                        Amount Received
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {paymentData.amountReceived ? formatCurrencyInINR(paymentData.amountReceived) : '₹ 0.0'}

                    </td>
                  </tr>

                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-wallet fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </i>{" "}
                        Amount Received
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {paymentData.paymentModeName ? paymentData.paymentModeName : '-'}
                    </td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>

        </div>

        <div className="card card-flush flex-row-fluid">
          <div className='card-header'>
            <div className="card-title">
              <h2>Customer Details</h2>
            </div>
          </div>



          <div className="card-body pt-0">
            <div className="table-responsive">
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody className="fw-semibold text-gray-600">
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-profile-circle fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </i>{" "}
                        Customer
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      <div className="d-flex align-items-center justify-content-end">

                        <label

                          className="text-gray-600 text-hover-primary"
                        >
                          {paymentData.customerDetailForPaymentDto?.customerName ? paymentData.customerDetailForPaymentDto?.customerName : ' - '}
                        </label>

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-sms fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>{" "}
                        Email
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      <a
                        className="text-gray-600 text-hover-primary"
                      >
                        {paymentData.customerDetailForPaymentDto?.email ? paymentData.customerDetailForPaymentDto?.email : ' - '}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <i className="ki-duotone ki-phone fs-2 me-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>{" "}
                        Phone
                      </div>
                    </td>
                    <td className="fw-bold text-end">{paymentData.customerDetailForPaymentDto?.mobileNumber ? paymentData.customerDetailForPaymentDto?.mobileNumber : ' - '}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
      {paymentData.isAdvance ? null :
        <div className="card card-flush py-4 flex-row-fluid overflow-hidden mb-4">

          <div className="card-header">
            <div className="card-title">
              <h2>Order Details</h2>
            </div>
          </div>

          <div className="card-body pt-0">
            <div className="table-responsive">

              <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                <thead>
                  <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-175px">Order Number</th>
                    <th className="min-w-70px text-end">Order Date</th>
                    <th className="min-w-100px text-end">Order Total</th>


                  </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">

                  {paymentData?.saleOrderDetailForPaymentDto?.map((soData, index) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="ms-5">
                            {soData.orderNumber}
                          </div>
                        </div>
                      </td>
                      <td className="text-end">{formatDate(soData?.orderDate, 'dd MMM yyyy')} </td>
                      <td className="text-end">{formatCurrencyInINR(soData.orderTotal)}</td>

                    </tr>
                  ))
                  }

                </tbody>
              </table>

            </div>
          </div>

        </div>
      }


      <div className='d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-primary me-3'
          onClick={() => navigate('/sales/payment/list')}
        >
          Back
        </button>


      </div>

    </>
  )
}

export default PaymentDetailPage
