import React, { FC, useEffect, useState } from 'react'
import { PaginationFilter } from '../../../../../_metronic/helpers';
import { Result } from '../../../../core/models/Result';
import { Agent, AgentRecord, TransactionHistory } from '../core/_models';
import { format } from 'date-fns';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRecordById, transactionHistory } from '../core/_requests';

type Props = {
    isAgentLoading: boolean;
    data: Result | Agent;
};
const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,
    advancedFilter: {
        logic: 'and',
        filters: [{

            field: "isActive",
            operator: "eq",
            value: true,

        },]
    }
};
const AgentDetailPage: FC<Props> = ({ data, isAgentLoading }) => {
    const agent = data as Agent

    // const [orderDetails, setOrderDetails] = useState<SalesOrder[]>([])
    const [recordDetails, setRecordDetails] = useState<AgentRecord>()
    const [transactions, setTransactions] = useState<TransactionHistory>()
    const location = useLocation();
    const { id } = useParams()


    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const updatedPaginationFilter: PaginationFilter = {
    //                 ...paginationFilter,
    //                 advancedFilter: {
    //                     field: "customerId",
    //                     operator: "eq",
    //                     value: parseInt(id as string),
    //                 },
    //             };
    //             // const response = await getSalesOrderList(updatedPaginationFilter);
    //             // setOrderDetails(response.data as SalesOrder[]);
    //             const response = await getRecordById(id)
    //             setRecordDetails(response as AgentRecord)

    //         } catch (error) {

    //         }
    //     };
    //     const transactionHistoryDetails = async () => {
    //         const response = await transactionHistory(id)
    //         setTransactions(response as TransactionHistory)
    //     }

    //     transactionHistoryDetails()

    //     fetchData();
    // }, []);

    const navigate = useNavigate()


    useEffect(() => {
        if (location.pathname === `/sales/agent/detail/${id}`) {
            navigate(`/sales/agent/detail/${id}/overview`);
        }
    }, [location.pathname, id, navigate]);
    
    return (

        <div id="kt_app_content" className="app-content  flex-column-fluid ">
            <div id="kt_app_content_container" className="app-container  container-xxl ">
                <div className="d-flex flex-column flex-xl-row">
                    <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                        <div className="card mb-5 mb-xl-8">
                            <div className="card-body pt-5">
                                <div className="d-flex flex-center flex-column mb-5">

                                </div>
                                <div className="d-flex flex-stack fs-4 py-3">
                                    <div className="fw-bold">Agent Details</div>

                                </div>
                                <div className="separator separator-dashed my-3" />
                                <div className="pb-5 fs-6">
                                    <div className="fw-bold mt-5">Agent Code</div>
                                    <div className="text-gray-600">{agent.agentCode}</div>

                                    <div className="fw-bold mt-5">Mobile Number</div>
                                    <div className="text-gray-600">
                                        <a href="#" className="text-gray-600 text-hover-primary">
                                            {agent.mobileNumber}
                                        </a>
                                    </div>

                                    <div className="fw-bold mt-5">Email Address</div>
                                    <div className="text-gray-600">
                                        {agent.emailAddress}

                                    </div>

                                    <div className="fw-bold mt-5">Agent Status</div>
                                    <div className="text-gray-600">
                                        {agent.isActive ? 'Active' : 'InActive'}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-lg-row-fluid ms-lg-15">
                        <ul
                            className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
                            role="tablist"
                        >
                            <li className="nav-item" role="presentation">

                                <Link

                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === `/sales/agent/detail/${id}/overview` && 'active')
                                    }
                                    to={`/sales/agent/detail/${id}/overview`}>Overview{" "}</Link>

                                
                            </li>
                            <li className="nav-item" role="presentation">
                                <Link

                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === `/sales/agent/detail/${id}/profile` && 'active')
                                    }
                                    to={`/sales/agent/detail/${id}/profile`}>Profile{" "}</Link>

                            </li>


                            <li className="nav-item" role="presentation">
                                <Link

                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === `/sales/agent/detail/${id}/commission` && 'active')
                                    }
                                    to={`/sales/agent/detail/${id}/commission`}>Commission{" "}</Link>
                            </li>

                            <li className="nav-item" role="presentation">
                                <Link

                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === `/sales/agent/detail/${id}/payout` && 'active')
                                    }
                                    to={`/sales/agent/detail/${id}/payout`}>Payout{" "}</Link>
                            </li>

                        </ul>
                        <div>
                            <Outlet></Outlet>
                        </div>

                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-primary' onClick={() => { navigate('/sales/agent/list') }}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default AgentDetailPage