import { FC, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikContext, FormikProvider, useFormik } from 'formik'

import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty } from '../../../../../_metronic/helpers/crud-helper/helpers'
import { Loading } from '../../../../core/shared/components/Loading'
import { Result } from '../../../../core/models/Result'
import { camelize } from '../../../../core/utils/StringHelpers'
import { StatusOptions } from '../../../../core/models/StatusOptions'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import { PaymentMode, } from '../core/_models'
import { createPaymentMode, updatePaymentMode } from '../core/_requests'
import { toast } from 'react-toastify'

type Props = {
  isPaymentModeLoading: boolean
  data: Result | PaymentMode
}

const paymentModeSchema = Yup.object().shape({
  name: Yup.string().required('Please enter payment mode name'),
  statusId: Yup.number(),
})

const PaymentModeForm: FC<Props> = ({ data, isPaymentModeLoading }) => {
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  if (data.hasOwnProperty('statusCode')) {
    setItemIdForUpdate(undefined)
  }

  let paymentmode = data as PaymentMode;

  const { refetch } = useQueryResponse()

  const initialValues = {
    id: paymentmode.id,
    name: paymentmode.name,
    isActive: paymentmode.isActive,
    statusId: (paymentmode.isActive ? 1 : 0)
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: paymentModeSchema,

    onSubmit: async (paymentmodedata, { setFieldError, setSubmitting }) => {
      setSubmitting(true)
      try {
        let result: Result
        if (isNotEmpty(paymentmodedata.id)) {
          paymentmodedata.isActive = paymentmodedata.statusId == 1
          result = await updatePaymentMode(paymentmodedata)
        } else {
          result = await createPaymentMode(paymentmodedata)
        }

        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success("PaymentMode added successfully")

          cancel(true)
        }
        else {
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage)
            )
          }
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  return (

    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_paymentmode_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column'
          id='kt_modal_add_paymentmode_scroll'
          data-kt-scroll='true'

        >

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2 ms-1'>PaymentMode Name</label>

            <input
              placeholder='PaymentMode Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isPaymentModeLoading}

            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>

          {itemIdForUpdate !== null ?
            <>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2 ms-1'>Status</label>
                <Field
                  className={clsx(
                    'form-select-solid ms-1',
                    { 'is-invalid': formik.touched.statusId && formik.errors.statusId },
                    {
                      'is-valid': formik.touched.statusId && !formik.errors.statusId,
                    }
                  )}
                  {...formik.getFieldProps('statusId')}

                  name="statusId"
                  options={StatusOptions}
                  component={CustomSelect}
                  placeholder="Select Status"
                  isMulti={false}
                ></Field>
              </div>

            </> : ''}

        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-brand-modal-action='cancel'
            disabled={formik.isSubmitting || isPaymentModeLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-brand-modal-action='submit'

            disabled={isPaymentModeLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isPaymentModeLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle '></span>
              </span>
            )}
          </button>
        </div>

      </form>
      {(formik.isSubmitting || isPaymentModeLoading) && <Loading />}
    </FormikProvider>
  )
}

export { PaymentModeForm }
