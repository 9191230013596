import { SelectOptionProps } from "./SelectOptionProps";

const StatusOptions: SelectOptionProps[] = []

StatusOptions.push({
    label: 'Active',
    value: 1
  })

  StatusOptions.push({
    label: 'Inactive',
    value: 0
  })

  export { StatusOptions }

