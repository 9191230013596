import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import qs from 'qs'
import {ID, PaginationRange, QueryResponseContextProps, QueryState} from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function calculateRange(currentPage: number, totalPages: number, maxDisplayedPage: number): PaginationRange {
  let start: number
  let end: number
  let gap: number = Math.round(maxDisplayedPage / 2)

  if (maxDisplayedPage > totalPages)
    maxDisplayedPage = totalPages

  if (totalPages === 1) {
    start = end = 1
  }
  else if (currentPage < maxDisplayedPage) {
    start = 1
    end = maxDisplayedPage
  }
  else if (currentPage + maxDisplayedPage === totalPages) {
    start = totalPages - maxDisplayedPage > 0 ? totalPages - maxDisplayedPage - 1 : 1;
    end = totalPages - 2
  }
  else if (currentPage + maxDisplayedPage === totalPages + 1) {
    start = totalPages - maxDisplayedPage > 0 ? totalPages - maxDisplayedPage : 1;
    end = totalPages - 1
  }
  else if (currentPage + maxDisplayedPage > totalPages + 1) {
    start = totalPages - maxDisplayedPage > 0 ? totalPages - maxDisplayedPage + 1 : 1;
    end = totalPages
  }
  else {
    start = currentPage - gap > 0 ? currentPage - gap + 1 : 1
    end = start + maxDisplayedPage - 1
  }

  return {
    start, end
  }
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, { filter: ['pageNumber', 'pageSize'], skipNulls: true })
  const sort = qs.stringify(state, { filter: ['sort', 'order'], skipNulls: true })
  const search = isNotEmpty(state.keyword)
    ? qs.stringify(state, { filter: ['keyword'], skipNulls: true })
    : ''


    const advancedFilterString = qs.stringify(state.advancedFilter);

    const filter =(advancedFilterString )
    ? Object.entries(advancedFilterString as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `filter_${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & {id?: ID}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  calculateRange,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
}
